
























































import { ChevronRightIcon, XIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { FieldConfiguration } from '../types/typings';
import { AlrternateNames } from '../types';

export default defineComponent({
    name: 'FieldPath',
    props: {
        field: {
            type: Object as PropType<FieldConfiguration>,
            required: true,
        },
        basePath: {
            type: Array as PropType<string[]>,
            required: false,
            default: () => [],
        },
        alternateName: {
            type: Object as PropType<AlrternateNames[string]>,
            default: null,
        },
        showAlternateNaming: {
            type: Boolean,
            default: false,
        },
    },
    components: { ChevronRightIcon, XIcon },
    setup(props, { emit }) {
        // references to DOM elements
        const pathContainerRef = ref<HTMLElement | null>(null);
        const pathSeparatorRef = ref<any>(null);
        const fieldTitleRef = ref<HTMLElement | null>(null);

        // calculates the width of a given string based on the font
        const getTextWidth = (text: string) => {
            const canvas: any = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = '.875rem sans-serif';
            const metrics = context.measureText(text);
            return Math.ceil(metrics.width);
        };

        /**
         * calculates the displayed field paths based
         * on the available screen size
         */
        const fieldPaths = computed((): { visible: []; hidden: []; all: [] } => {
            const fieldPathCopy = props.showAlternateNaming ? [...props.alternateName.path] : [...props.field.path];

            const result: any = { visible: [], hidden: [], all: [] };
            result.all = props.showAlternateNaming ? [...props.alternateName.path] : [...props.field.path];
            if (pathContainerRef.value && fieldTitleRef.value) {
                if (!props.showAlternateNaming && !R.isNil(props.field.title)) result.all.push(props.field.title);
                else if (props.showAlternateNaming && result.all?.length > 1 && !R.isNil(props.alternateName.title))
                    result.all.push(props.alternateName.title);

                const last = fieldPathCopy.pop();

                fieldPathCopy.forEach((path: string) => {
                    if (!pathContainerRef.value || !fieldTitleRef.value) return;
                    const totalWidth = pathContainerRef.value.offsetWidth;
                    const pathSeparatorWidth = pathSeparatorRef.value?.width.baseVal.value + 8;
                    const titleWidth = fieldTitleRef.value.offsetWidth;
                    const estimatedWidth =
                        getTextWidth(result.visible.join('') + path + last) +
                        (result.visible.length + 3) * pathSeparatorWidth +
                        titleWidth;
                    if (result.hidden.length === 0 && estimatedWidth <= totalWidth) result.visible.push(path);
                    else if (result.hidden.length === 0) {
                        result.visible.push('..');
                        result.hidden.push(path);
                    } else result.hidden.push(path);
                });

                if (last) result.visible.push(last); // always show first independent of width
            }
            return result;
        });

        const isBasePath = (field: string, level: number) => {
            if (!props.basePath || level > props.basePath.length || field === '..') return false;

            for (let i = 0; i <= level; i++)
                if ((fieldPaths.value.all[i] as string).replaceAll('[]', '') !== props.basePath[i]) return false;

            return true;
        };

        const field: Ref<FieldConfiguration> = computed({
            get: () => props.field,
            set: (newField: FieldConfiguration) => {
                emit('change', newField);
            },
        });

        const trimPath = () => {
            field.value.path.splice(-1, 1);
            field.value.pathUids.splice(-1, 1);
            field.value.parentIds.splice(-1, 1);
            field.value.categories.splice(-1, 1);
        };

        return {
            fieldPaths,
            pathContainerRef,
            fieldTitleRef,
            pathSeparatorRef,
            trimPath,
            isBasePath,
        };
    },
});
