var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full px-2 overflow-hidden text-white border-neutral-300 bg-primary-700",class:{ 'border-t': !_vm.standalone, 'rounded-b': _vm.standalone && !_vm.hasPagination }},[_c('div',{ref:"contentRef",staticClass:"overflow-auto",style:(("max-height: " + _vm.maxHeight))},[(_vm.logs.length > 0)?_c('div',{staticClass:"relative flex flex-col h-full px-4 py-4 space-y-2"},_vm._l((_vm.sortedLogs),function(log,idx){return _c('div',{key:idx},[_c('div',{staticClass:"w-full font-mono text-xs sm:flex sm:items-center lg:text-sm"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                            content: _vm.modifiedTimestamp(log.timestamp),
                            classes: 'max-w-lg break-words',
                        }),expression:"{\n                            content: modifiedTimestamp(log.timestamp),\n                            classes: 'max-w-lg break-words',\n                        }"}],staticClass:"break-all lg:-mr-1 lg:w-1/6 line-clamp-1",class:_vm.workflowType === _vm.WorkflowType.Analytics ? 'w-1/5' : 'w-1/3'},[_vm._v(" "+_vm._s(_vm.modifiedTimestamp(log.timestamp))+" ")]),(_vm.workflowType === _vm.WorkflowType.Analytics)?_c('div',{staticClass:"flex justify-center w-1/4 text-sm text-neutral-800 md:text-base md:w-1/6"}):_vm._e(),_c('div',{staticClass:"uppercase sm:text-center md:w-1/6",class:[
                            _vm.workflowType === _vm.WorkflowType.Analytics ? 'w-1/4' : 'w-1/3 md:-ml-1',
                            _vm.textColour(log.level) ]},[_vm._v(" "+_vm._s(log.level)+" ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                            content: log.message.length >= 50 && log.message.length < 500 ? log.message : null,
                            classes: 'max-w-lg break-words',
                        }),expression:"{\n                            content: log.message.length >= 50 && log.message.length < 500 ? log.message : null,\n                            classes: 'max-w-lg break-words',\n                        }"}],staticClass:"text-sm break-all line-clamp-1",class:_vm.workflowType === _vm.WorkflowType.Analytics ? 'md:w-1/3 ml-1' : 'md:w-1/2 md:ml-1'},[(log.message.length < 500)?_c('span',{staticClass:"break-all line-clamp-1"},[_vm._v(" "+_vm._s(log.message)+" ")]):_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                'The log message is too long. You may copy it to the Clipboard and paste it in an editor to view it.'
                            ),expression:"\n                                'The log message is too long. You may copy it to the Clipboard and paste it in an editor to view it.'\n                            "}],on:{"click":_vm.copyToClipboard}},[_c('input',{attrs:{"id":"longMessage","type":"hidden"},domProps:{"value":log.message}}),_c('div',{staticClass:"text-left break-all line-clamp-1",class:{
                                    'hover:text-red-200': log.level === 'error',
                                    'hover:text-primary-200': log.level === 'info',
                                }},[_vm._v(" "+_vm._s(log.message)+" ")])])])])])}),0):_c('div',{staticClass:"flex items-center h-full p-4"},[_c('div',{staticClass:"font-mono text-sm text-white"},[_vm._v("No logs found!")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }