
















































































import { AlertBanner, ConfirmModal, TwButton } from '@/app/components';
import { useAxios } from '@/app/composable';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { ValidationObserver } from 'vee-validate';
import { MqttAPI } from '../../../api';
import { LoadingStreamingSampleModal } from '../../../components';
import { useHarvester } from '../../../composable';
import { ExternalMqttHarvesterConfiguration, TFile } from '../../../types';
import { MqttProtocol, MqttTopicHierarchy, RetrieveUntilSettings, SampleUpload } from '../common';
import MqttConnectionDetails from './MqttConnectionDetails.vue';

export default defineComponent({
    name: 'SetupExternalMqttHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<ExternalMqttHarvesterConfiguration>,
            required: true,
        },
        sample: {
            type: [Object, Array],
            default: null,
        },
        sampleFile: {
            type: File as PropType<TFile | null>,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        inUpdateStatus: {
            type: Boolean,
            default: false,
        },
        pipelineFinalized: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        MqttProtocol,
        MqttConnectionDetails,
        MqttTopicHierarchy,
        ValidationObserver,
        TwButton,
        ConfirmModal,
        AlertBanner,
        RetrieveUntilSettings,
        SampleUpload,
        LoadingStreamingSampleModal,
        ChevronRightIcon,
    },
    setup(props, { root, emit }) {
        const formatChanged = ref<boolean>(false);
        const emptySampleResponse = ref<boolean>(false);
        const errorAlert = ref<{ title: string | null; body: string | null }>({ title: null, body: null });
        const mqttValidationRef = ref<any>(null);
        const initialConnectionDetails = ref<any>(R.clone(props.configuration.connectionDetails));

        const mqttConfiguration: Ref<ExternalMqttHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: ExternalMqttHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const fileType = computed(() => mqttConfiguration.value.fileType);

        const { exec, loading: loadingTest } = useAxios(true);
        const {
            parseXMLString,
            reduceSampleValues,
            limitResponse,
            checkMissingFields,
            cancelSelectionChange,
            confirmSelectionChange,
            convertSelectedItemsToArray,
            showPartialMatchModal,
            showNoMatchModal,
            isReset,
            separator,
            basePath,
        } = useHarvester(root, emit, fileType);

        const selectedParameter = computed(() => {
            if (mqttConfiguration.value.topicNameField) {
                return [
                    `${basePath}${separator}${mqttConfiguration.value.topicNameField}`,
                    `${basePath}[0]${separator}${mqttConfiguration.value.topicNameField}`,
                ];
            }
            return [];
        });

        const selection = computed(() =>
            mqttConfiguration.value.response.selectedItems.filter(
                (item: string) => item.includes(separator) && !selectedParameter.value.includes(item),
            ),
        );

        const displaySampleUpload = computed(
            () => emptySampleResponse.value || (!!props.sampleFile && !props.inUpdateStatus),
        );

        const hasSameSubtopics = computed(() => {
            const subtopics: any = mqttConfiguration.value.connectionDetails?.topics
                ?.filter(
                    (subtopic: { name: string; qos: number }) =>
                        subtopic.name !== `${mqttConfiguration.value.connectionDetails.topic}/`,
                )
                .map((subtopic: { name: string; qos: number }) => subtopic.name);
            return !!subtopics.filter(
                (subtopic: { name: string; qos: number }, index: number) => subtopics.indexOf(subtopic) !== index,
            ).length;
        });

        const connectionDetailsChanged = computed(
            () =>
                JSON.stringify(initialConnectionDetails.value) !==
                JSON.stringify(mqttConfiguration.value.connectionDetails),
        );

        const sampleFileIsRequired = computed(
            () => !props.sampleFile && emptySampleResponse.value && !connectionDetailsChanged.value,
        );

        const resetFileFormat = () => {
            emit('sample-file-changed', null);
            formatChanged.value = true;
            emptySampleResponse.value = false;
        };

        const setCroppedSample = (cropped: boolean) => {
            mqttConfiguration.value.isSampleCropped = cropped;
        };

        const testCredentialsAndCreateSample = () => {
            exec(
                MqttAPI.testCredentialsAndCreateSample(
                    mqttConfiguration.value.connectionDetails,
                    mqttConfiguration.value.fileType,
                ),
            )
                .then((res: any) => {
                    emit('modify-final-sample', null);
                    if (!res.data || (mqttConfiguration.value.fileType === 'json' && !res.data.length)) {
                        emptySampleResponse.value = true;
                        errorAlert.value = {
                            title: 'Failed to retrieve sample.',
                            body:
                                'Please upload a sample in the "Sample Streaming Data Upload" section below. Important note: the sample must be an exact match of the messages that will be published in the specific MQTT topic.',
                        };
                    } else {
                        const data =
                            mqttConfiguration.value.fileType === 'xml' ? parseXMLString(res.data.toString()) : res.data;
                        const reducedData = reduceSampleValues(limitResponse(data, 20));
                        emit('sample-uploaded', reducedData);

                        if ((!props.inUpdateStatus || isReset.value) && mqttConfiguration.value.response.selectedItems)
                            emit('update-selected-items', []);

                        if (data && props.inUpdateStatus && selection.value.length && !isReset.value) {
                            const items = convertSelectedItemsToArray(selection.value);
                            const missingFields = checkMissingFields(reducedData, items);
                            if (missingFields) return;
                            else
                                emit(
                                    'update-selected-items',
                                    convertSelectedItemsToArray(mqttConfiguration.value.response.selectedItems),
                                );
                        }
                        emit('next-tab');
                    }
                })
                .catch((error: any) => {
                    if (error && error.response?.data)
                        errorAlert.value = {
                            title: 'Failed action',
                            body: error.response.data.message
                                ? `Testing failed with message: ${error.response.data.message}`
                                : null,
                        };
                    (root as any).$toastr.e('PubSub mechanism connection failed', 'Error');
                });
        };

        const validateAndProceed = async () => {
            if (!mqttValidationRef.value) return;

            const valid = await mqttValidationRef.value.validate();
            if (!valid) return;

            // add 1 extra day in order to make it inclusive
            const inclusiveDate = dayjs.utc(mqttConfiguration.value.retrieval.endDate).add(1, 'day');

            if (!props.pipelineFinalized && inclusiveDate.isBefore(dayjs().utc())) {
                (root as any).$toastr.e(
                    'Retrieve Until Date is in the past. Please update it accordingly to continue.',
                    'Invalid Retrieve Until Date',
                );
                return;
            }

            if (connectionDetailsChanged.value || formatChanged.value) {
                emit('sample-file-changed', null);
                emit('modify-final-sample', null);
                formatChanged.value = false;

                if ((!props.inUpdateStatus || isReset.value) && mqttConfiguration.value.response.selectedItems)
                    emit('update-selected-items', []);
            }
            initialConnectionDetails.value = R.clone(mqttConfiguration.value.connectionDetails);
            errorAlert.value = { title: null, body: null };
            emptySampleResponse.value = false;

            if (!props.sampleFile && !props.sample && !props.isFinalized) testCredentialsAndCreateSample();
            else emit('next-tab');
        };

        const sampleUploaded = (parsedSample: any) => {
            if (parsedSample && props.inUpdateStatus && selection.value.length && !isReset.value)
                checkMissingFields(parsedSample, selection.value);
            emit('sample-uploaded', parsedSample);
        };

        const confirmSelection = (reset: boolean) => {
            confirmSelectionChange(props.sampleFile, props.sample, selection.value, reset);
            emptySampleResponse.value = false;
        };

        watch(
            () => connectionDetailsChanged.value,
            () => {
                emit('sample-file-changed', null);
                emptySampleResponse.value = false;
            },
        );

        return {
            loadingTest,
            emptySampleResponse,
            errorAlert,
            showNoMatchModal,
            showPartialMatchModal,
            displaySampleUpload,
            hasSameSubtopics,
            mqttValidationRef,
            sampleFileIsRequired,
            mqttConfiguration,
            resetFileFormat,
            validateAndProceed,
            confirmSelection,
            cancelSelectionChange,
            setCroppedSample,
            sampleUploaded,
        };
    },
});
