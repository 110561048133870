





























import store from '@/app/store';
import { useApolloPipeline, useApolloTask } from '@/modules/apollo/composable';
import { RetrievalType } from '@/modules/apollo/constants';
import { ApolloTask, LargeFilesHarvesterConfiguration } from '@/modules/apollo/types';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { RetrievalSettings, Schedules } from '../common';

export default defineComponent({
    name: 'LargeFilesRetrievalSettings',
    components: { RetrievalSettings, Schedules },
    props: {
        task: {
            type: Object as PropType<ApolloTask<LargeFilesHarvesterConfiguration>>,
            required: true,
        },
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const loadingSchedules = ref(false);

        const { inDraftStatus, pipelineFinalized, isRunning, isFinalized, hasCompleted } = useApolloTask(
            computed(() => props.task),
        );
        const { hasAnonymisation } = useApolloPipeline(props.task.pipeline.id);

        const user = computed(() => store.state.auth.user);

        const isOwner = computed(() => props.task.pipeline.createdById === user.value.id);

        const retrieveOnce = computed(() => props.task.configuration.retrieval.type === RetrievalType.Once);

        const readOnlyMessage = computed(() => {
            if (!isOwner.value) return 'Only the owner of the pipeline is allowed to update its schedules';
            if (retrieveOnce.value) {
                if (hasCompleted.value) return 'You cannot update a schedule that has already been executed';
                if (isRunning.value) return 'You cannot update a schedule that is currently being executed';
            }
            return null;
        });

        watch(
            () => hasAnonymisation.value,
            () => {
                if (hasAnonymisation.value && props.task.configuration.retrieval.type === RetrievalType.Periodic) {
                    emit('update-retrieval-type', RetrievalType.Immediately);
                }
            },
            { immediate: true },
        );

        return {
            isRunning,
            hasAnonymisation,
            isFinalized,
            retrieveOnce,
            isOwner,
            inDraftStatus,
            pipelineFinalized,
            loadingSchedules,
            hasCompleted,
            readOnlyMessage,
        };
    },
});
