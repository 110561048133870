









import { BooleanGroupRule } from '@/modules/apollo/types';
import { PropType, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'BooleanGroupRule',
    props: {
        rule: {
            type: Object as PropType<BooleanGroupRule>,
            required: true,
        },
    },
});
