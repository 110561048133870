

























import { defineComponent } from '@vue/composition-api';
import { CheckIcon } from '@vue-hero-icons/outline';
import { CopyToClipboardButton } from '@/app/components';

export default defineComponent({
    name: 'AccessToken',
    components: {
        CheckIcon,
        CopyToClipboardButton,
    },
    props: {
        token: {
            type: String,
        },
    },
});
