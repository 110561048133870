var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full space-y-4"},[_c('div',{staticClass:"flex items-center space-x-2 flex-no-grow"},[_c('span',{staticClass:"text-sm font-bold tracking-wide uppercase text-primary-700"},[_vm._t("title",function(){return [_vm._v(" Schedule"+_vm._s(_vm.multiple ? 's' : '')+" ")]})],2)]),_c('div',{class:_vm.fixedHeight},[_c('div',{staticClass:"h-full"},[_c('scrollbar',{staticClass:"h-full",class:{ 'divide-y divide-neutral-200': _vm.minimalLayout, 'pr-3': !_vm.minimalLayout }},[_vm._l((_vm.modifiedSchedules),function(schedule,index){return _c('div',{key:schedule.id,class:{ 'py-2 relative': !_vm.minimalLayout, 'py-4': _vm.minimalLayout }},[_c('div',{staticClass:"w-full rounded-md",class:{
                            'bg-primary-100 hover:bg-neutral-200 border-primary-700':
                                _vm.selectedScheduleIdx === index && !_vm.minimalLayout,
                            'bg-neutral-100 border-neutral-100 hover:bg-neutral-200':
                                _vm.selectedScheduleIdx !== index && !_vm.minimalLayout,
                            'p-2': !_vm.minimalLayout,
                            'cursor-pointer': !_vm.readOnly,
                        },on:{"mouseenter":function($event){_vm.hoveredItem = index},"mouseleave":function($event){_vm.hoveredItem = null},"click":function($event){_vm.readOnly ? null : _vm.editSchedule(index)}}},[_c('div',{staticClass:"flex items-center justify-between w-full"},[_c('div',{staticClass:"flex",class:{ 'w-10/12': _vm.minimalLayout }},[_c('div',{staticClass:"flex text-sm tracking-wide text-neutral-800"},[_vm._v(" "+_vm._s(_vm.cronToString( schedule.schedule, schedule.startDate, schedule.endDate, _vm.retrieveOnce ))+" ")])]),_c('div',{staticClass:"flex items-center",class:{ 'w-2/12 flex space-x-2 justify-end ': _vm.minimalLayout }},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.message),expression:"message"}],staticClass:"flex items-center"},[_c('toggle',{class:{ 'mr-1': !_vm.minimalLayout },attrs:{"checked":schedule.isEnabled,"backgroundColour":_vm.setToggleBgColour(index),"additionalInputClasses":_vm.setToggleAdditionalInputClass(schedule.isEnabled),"disabled":_vm.disableScheduleToggling},on:{"change":function($event){return _vm.changeStatus(index)}},model:{value:(schedule.isEnabled),callback:function ($$v) {_vm.$set(schedule, "isEnabled", $$v)},expression:"schedule.isEnabled"}})],1),(!_vm.readOnly)?_c('div',{class:{
                                        'absolute top-0 flex flex-row -right-1': !_vm.minimalLayout,
                                        'cursor-not-allowed ':
                                            _vm.selectedSchedule && _vm.selectedSchedule.id === schedule.id,
                                    }},[_c('ConfirmButton',{class:{
                                            'pointer-events-none ':
                                                _vm.selectedSchedule && _vm.selectedSchedule.id === schedule.id,
                                        },attrs:{"disabled":_vm.selectedSchedule && _vm.selectedSchedule.id === schedule.id},on:{"confirmed":function($event){return _vm.deleteSchedule(index)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"cursor-pointer",class:{
                                                    'bg-white rounded-full': !_vm.minimalLayout,
                                                    ' text-neutral-400':
                                                        _vm.selectedSchedule && _vm.selectedSchedule.id === schedule.id,
                                                    'hover:text-red-800 text-neutral-600':
                                                        !_vm.selectedSchedule || _vm.selectedSchedule.id !== schedule.id,
                                                },attrs:{"title":"Remove"}},[(_vm.minimalLayout)?_c('TrashIcon',{staticClass:"w-5 h-5"}):_c('XCircleIcon',{staticClass:"w-5 h-5"})],1)]},proxy:true},{key:"confirm",fn:function(){return [_c('div',{staticClass:"my-auto text-orange-800 cursor-pointer hover:text-orange-900",attrs:{"title":"Click again to confirm removal"}},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])])]},proxy:true}],null,true)})],1):_vm._e()])])])])}),(_vm.enableAddNewScheduleBtn)?_c('div',{staticClass:"flex justify-center w-full",class:_vm.minimalLayout ? 'pt-4' : 'mt-2'},[_c('button',{staticClass:"p-1 border rounded-full text-neutral-600 hover:border-transparent focus:outline-none hover:text-white hover:bg-primary-500 bg-neutral-200 border-neutral-300",attrs:{"type":"button","title":"Add a new schedule"},on:{"click":function($event){return _vm.emit('create-schedule')}}},[_c('PlusIcon',{staticClass:"w-5 h-5"})],1)]):_vm._e()],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }