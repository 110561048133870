











































































































import { ApiHarvesterHeader, ApiHeaderTag, ApiHeaderTagConfig } from '@/modules/apollo/types';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { clone } from 'ramda';
import { InputErrorIcon } from '@/app/components';
import { ValidationProvider } from 'vee-validate';
import { TrashIcon, PlusIcon, LockClosedIcon } from '@vue-hero-icons/outline';
import VueTagsInput from '@johmun/vue-tags-input';

export default defineComponent({
    name: 'ExtraHeaders',
    components: { VueTagsInput, ValidationProvider, InputErrorIcon, TrashIcon, PlusIcon, LockClosedIcon },
    model: { prop: 'configuration', event: 'changed' },
    props: {
        configuration: {
            type: Array as PropType<ApiHarvesterHeader[]>,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loginResponseKeys: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        tagsConfiguration: {
            type: Array as PropType<ApiHeaderTag[]>,
            default: () => [],
        },
        showTitles: {
            type: Boolean,
            default: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const headers: Ref<ApiHarvesterHeader[]> = computed({
            get: () => props.configuration,
            set: (newHeaders: ApiHarvesterHeader[]) => emit('changed', newHeaders),
        });

        const headerTags: Ref<ApiHeaderTag[]> = computed({
            get: () => props.tagsConfiguration,
            set: (newHeaderTags: ApiHeaderTag[]) => emit('update-header-tags', newHeaderTags),
        });

        const inVault = (str: string | undefined | null): boolean => {
            if (str) {
                return str.startsWith('vault://');
            }
            return false;
        };

        const addHeader = () => {
            headers.value = [
                ...headers.value,
                {
                    key: '',
                    value: '',
                    sensitive: false,
                },
            ];
            headerTags.value = [...headerTags.value, { value: '', tags: [] }];
        };

        const removeHeader = (index: number) => {
            const newHeaders = clone(headers.value);
            const newHeaderTags = clone(headerTags.value);

            newHeaders.splice(index, 1);
            newHeaderTags.splice(index, 1);

            headers.value = newHeaders;
            headerTags.value = newHeaderTags;
        };

        const addHeaderTag = (index: number, newTags: ApiHeaderTagConfig[]) => {
            const newHeaders = clone(headers.value);
            const newHeaderTags = clone(headerTags.value);

            newHeaderTags[index].tags = [];

            let value = '';
            for (const tag of newTags) {
                if (props.loginResponseKeys.includes(tag.text)) {
                    tag.classes = 'loginParam';
                    value += `{${tag.text}}`;
                } else {
                    tag.classes = 'userParam';
                    value += `${tag.text}`;
                }
                newHeaderTags[index].tags.push(tag);
            }
            newHeaders[index].value = value;

            headers.value = newHeaders;
            headerTags.value = newHeaderTags;
        };

        const autocompleteTags = computed(() => {
            const autoTags = [];
            for (let i = 0; i < props.loginResponseKeys.length; i += 1) {
                autoTags.push({ text: props.loginResponseKeys[i] });
            }
            return autoTags;
        });

        return { headers, headerTags, autocompleteTags, inVault, addHeader, removeHeader, addHeaderTag };
    },
});
