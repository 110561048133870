

























import { computed, defineComponent, PropType } from '@vue/composition-api';
import * as R from 'ramda';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFilters, useMonitoringPeriod } from '@/app/composable';
import { MonitoringExecutionQuery } from '@/app/types';
dayjs.extend(utc);

export default defineComponent({
    name: 'MonitoringQuerySummary',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
    },
    setup(props) {
        const { formatDateAs: formatDate } = useFilters();
        const { findPeriod } = useMonitoringPeriod();
        const formatList = (list: string[]) => {
            if (R.isNil(list) || R.isEmpty(list)) return null;
            if (list.length === 1) return list[0];
            const last = list.pop();
            return `${list.join(',')}${last ? ' and ' + last : ''}`;
        };

        const period = computed(() => findPeriod(props.query.query.dateRange?.start, props.query.query.dateRange?.end));

        const ownership = computed(() => (props.query.query.organisationId ? "my organisation's" : 'my'));

        return { period, ownership, formatDate, formatList };
    },
});
