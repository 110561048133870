





















import { minLengthValidator, requiredValidator } from '@/app/validators';
import { defineComponent, PropType } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { AccessTokenScope } from '.';
import { AccessToken } from '../types';
import { AccessTokenScope as AccessTokenScopeType } from '../types/access-token-scope.interface';

extend('required', requiredValidator);
extend('min', minLengthValidator);

export default defineComponent({
    name: 'AccessTokenScopes',
    components: { ValidationProvider, AccessTokenScope },
    props: {
        scopes: {
            type: Array as PropType<AccessTokenScopeType[]>,
        },
        token: {
            type: Object as PropType<AccessToken>,
        },
    },
});
