















































































import { AlertBanner, FormBlock, JsonParser } from '@/app/components';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import * as R from 'ramda';
import { ExternalMqttHarvesterConfiguration, TFile } from '../../../types';
import { MqttTopicParameter } from '../common';

export default defineComponent({
    name: 'ReviewExternalMqttHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<ExternalMqttHarvesterConfiguration>,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        inUpdateStatus: {
            type: Boolean,
            default: false,
        },
        loadingFinalization: {
            type: Boolean,
            default: false,
        },
        finalSample: {
            type: [Array, Object],
            default: null,
        },
        sample: {
            type: [Array, Object],
            default: null,
        },
        sampleFile: {
            type: File as PropType<TFile | null>,
            default: null,
        },
    },
    components: { AlertBanner, MqttTopicParameter, JsonParser, FormBlock },
    setup(props, { emit }) {
        const basePath = 'res';
        const separator = '||';

        const mqttConfiguration: Ref<ExternalMqttHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: ExternalMqttHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const isSampleArray = computed(() => R.is(Array, props.sample));

        const additionalDataKeyValue = computed(() =>
            mqttConfiguration.value.topicNameField ? { [mqttConfiguration.value.topicNameField]: 'SUB_TOPIC' } : {},
        );

        const config = computed(() => {
            if (props.sample)
                return {
                    basePath,
                    multiple: isSampleArray.value,
                    selectedItems: mqttConfiguration.value?.response?.selectedItems || [],
                };
            return null;
        });

        const focusPath = computed(() =>
            config.value && config.value.basePath !== basePath ? config.value.basePath : null,
        );

        const jsonSize = computed(() => (isSampleArray.value ? props.sample.length : 1));

        const additionalPaths = computed(() => {
            const paths = [];
            if (mqttConfiguration.value.topicNameField)
                if (isSampleArray.value)
                    paths.push(`${basePath}[0]${separator}${mqttConfiguration.value.topicNameField}`);
                else paths.push(`${basePath}${separator}${mqttConfiguration.value.topicNameField}`);
            return paths;
        });

        const displaySampleIsArrayBanner = computed(
            () => isSampleArray.value && !props.isFinalized && !props.loadingFinalization && !!props.sampleFile,
        );

        const sampleSelectionText = computed(() =>
            props.sampleFile ? 'Uploaded Sample Selection' : 'MQTT Response Selection',
        );

        const sampleSelectionDescription = computed(
            () =>
                `The complete ${
                    props.sampleFile
                        ? 'Sample uploaded by the user'
                        : 'MQTT response retrieved when testing the MQTT connection'
                }. The user needs to select the concepts that should be further processed and stored. The concepts that are not selected will be discarded.`,
        );

        const sampleSummaryText = computed(() => (props.sampleFile ? 'Uploaded Sample' : 'MQTT Response'));

        return {
            mqttConfiguration,
            focusPath,
            jsonSize,
            displaySampleIsArrayBanner,
            additionalDataKeyValue,
            basePath,
            additionalPaths,
            sampleSummaryText,
            separator,
            sampleSelectionText,
            sampleSelectionDescription,
        };
    },
});
