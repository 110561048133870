





























import * as R from 'ramda';
import { useApolloTask, usePolicyFileUpload } from '@/modules/apollo/composable';
import { KafkaProcessingOptions, TaskStatus } from '@/modules/apollo/constants';
import {
    ApolloTask,
    KafkaConnectionDetails,
    KafkaHarvesterConfiguration,
    TaskStep,
    TFile,
    WizardAction,
} from '@/modules/apollo/types';
import { computed, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api';
import { ApolloTaskShell } from '../../../components';
import { ScheduleAPI } from '@/modules/workflow-designer/api';
import { ScheduleType } from '@/modules/workflow-designer/types';

export default defineComponent({
    name: 'KafkaHarvester',
    components: { ApolloTaskShell },
    props: {
        task: {
            type: Object as PropType<ApolloTask<KafkaHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    setup(props, { root }) {
        const taskRef = ref<ApolloTask<KafkaHarvesterConfiguration>>(props.task);
        const { loading: loadingTask, save, finalize, isFinalized, inDraftStatus } = useApolloTask(taskRef);
        const { uploadPolicyFiles } = usePolicyFileUpload(props.task.pipeline.id);
        const currentStep = ref<number>(0);
        const showFinalizeModal = ref<boolean>(false);
        const sample: Ref<any> = ref<any>(props.task.processedSample);
        const sampleFile = ref<TFile | null>(null);
        const schedules = ref<ScheduleType[]>([]);

        const loading = computed(() => loadingTask.value);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: props.task?.status !== TaskStatus.Deprecated && currentStep.value === 1,
            },
        ]);

        const setSample = (sampleData: any) => {
            sample.value = sampleData;
        };

        const setSampleFile = (file: TFile | null) => {
            sampleFile.value = file;
            if (!file) sample.value = null;
        };

        const setConnectionDetails = (connectionDetails: KafkaConnectionDetails) => {
            taskRef.value.configuration.connectionDetails = connectionDetails;
        };

        const updateProcessingOption = (newProcessingOption: KafkaProcessingOptions) => {
            props.task.configuration.processing = newProcessingOption;
            if (props.task.configuration.retrieval) props.task.configuration.retrieval.endDate = null;
        };

        const updateSchedules = (updatedSchedules: ScheduleType[]) => {
            schedules.value = updatedSchedules;
        };

        const saveTask = async () => {
            try {
                if (sample.value) {
                    const arraySample = R.is(Array, sample.value) ? sample.value : [sample.value];
                    props.task.configuration.sample = arraySample as Array<Record<string, any>>;
                }
                await save();
            } catch (e) {
                (root as any).$toastr.e('Failed to save task', 'An error occurred!');
                throw e;
            }
        };

        const finalizeTask = async () => {
            try {
                await saveTask();
                if (sampleFile.value)
                    await uploadPolicyFiles([
                        {
                            file: sampleFile.value,
                            sample: true,
                            policy: {
                                folder: 'upload',
                                subfolder: `sample/${new Date().valueOf().toString()}`,
                            },
                        },
                    ]);
                if (inDraftStatus.value && schedules.value.length) await ScheduleAPI.create(schedules.value);
                await finalize();
                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            }
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<KafkaHarvesterConfiguration>) => {
                taskRef.value = newTask;
            },
        );

        return {
            taskRef,
            currentStep,
            loading,
            showFinalizeModal,
            wizardActions,
            sample,
            sampleFile,
            schedules,
            setSample,
            setSampleFile,
            setConnectionDetails,
            updateSchedules,
            saveTask,
            finalizeTask,
            updateProcessingOption,
        };
    },
});
