



















































































import { Scrollbar, TwButton, WizardTabs } from '@/app/components';
import { useAxios } from '@/app/composable';
import { WorkflowStatus } from '@/modules/apollo/constants';
import {
    CheckIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    RefreshIcon,
    SaveIcon,
    UploadIcon,
} from '@vue-hero-icons/outline';
import { PlayIcon, TableIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ApolloAPI } from '../api';
import { LoadingSampleRunModal, TaskCompletionModal, WizardActions } from '../components';
import { useApolloTask } from '../composable';
import { ApolloTask, ApolloTaskConfiguration, WizardAction } from '../types';

export default defineComponent({
    name: 'ApolloTaskShell',
    model: { prop: 'currentStep', event: 'changed' },
    props: {
        task: {
            type: Object as PropType<ApolloTask<ApolloTaskConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingSampleRun: {
            type: Boolean,
            default: false,
        },
        steps: {
            type: Array as PropType<{ key: string; name: string; description?: string }[]>,
            default: () => [],
        },
        currentStep: {
            type: Number,
            default: 0,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        message: {
            type: String,
            default: null,
        },
        showFinalizeModal: {
            type: Boolean,
            default: false,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        allowNext: {
            type: Boolean,
            default: true,
        },
        restartedStep: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: true,
        },
        wizardActions: {
            type: Array as PropType<WizardAction[]>,
            default: () => [],
        },
    },
    components: {
        Scrollbar,
        OrbitSpinner,
        WizardTabs,
        TwButton,
        ChevronLeftIcon,
        ChevronRightIcon,
        LoadingSampleRunModal,
        WizardActions,
        TaskCompletionModal,
    },
    setup: (props, { emit }) => {
        const { exec } = useAxios(true);

        const taskRef = ref(props.task);
        const { isFinalized } = useApolloTask(taskRef);
        const contentRef = ref<any>(null);

        // Override these actions using the wizardActions prop
        const defaultWizardActions: WizardAction[] = [
            { key: 'sample-run', label: 'Run on Sample', show: false, enabled: false, color: 'white', icon: PlayIcon },
            {
                key: 'view-processed-sample',
                label: 'View Result',
                show: false,
                enabled: false,
                color: 'green',
                icon: TableIcon,
            },
            { key: 'save', label: 'Save Configuration', show: false, enabled: false, color: 'white', icon: SaveIcon },
            {
                key: 'save-after-finalize',
                label: 'Update Configuration',
                show: false,
                enabled: false,
                color: 'white',
                icon: SaveIcon,
            },
            { key: 'append', label: 'Upload and Run', show: false, enabled: false, color: 'white', icon: UploadIcon },
            {
                key: 'revise',
                label: 'Revise Configuration',
                show: false,
                enabled: false,
                color: 'white',
                icon: RefreshIcon,
            },
            {
                key: 'finalize',
                label: 'Finalise Configuration',
                show: false,
                enabled: false,
                color: 'primary',
                icon: CheckIcon,
            },
        ];

        const finalWizardActions = computed(() =>
            props.wizardActions.reduce((acc: WizardAction[], wizardAction: WizardAction) => {
                const foundAction = defaultWizardActions.find((action) => action.key === wizardAction.key);
                if (foundAction) acc.push(R.mergeDeepRight(foundAction, wizardAction));
                else acc.push(wizardAction);
                return acc;
            }, [] as WizardAction[]),
        );

        const showCancelButton = computed(() =>
            finalWizardActions.value.some((action: WizardAction) => action.showCancel),
        );

        const tabs = computed(() =>
            props.steps.map((ttw: { key: string; name: string; description?: string }) => ({
                title: ttw.name,
                description: ttw.description,
            })),
        );

        const hasProcessedSample = computed(() => !!props.task.processedSample?.length);

        const scrollUp = () => {
            if (props.scrollable) contentRef.value.scrollToTop();
            else contentRef.value.scrollTo({ top: 0, behavior: 'smooth' });
        };

        const previousTab = () => {
            emit('changed', props.currentStep - 1);
            scrollUp();
        };

        const nextTab = () => {
            emit('changed', props.currentStep + 1);
            scrollUp();
        };

        const unlockPipeline = async () => {
            await exec(ApolloAPI.unlock(props.task.pipeline?.id as string));
        };

        onMounted(() => {
            window.addEventListener('beforeunload', unlockPipeline);
        });

        onUnmounted(async () => {
            unlockPipeline();
        });

        watch(
            () => props.task,
            (newTask: ApolloTask<ApolloTaskConfiguration>) => {
                taskRef.value = newTask;
            },
        );

        return {
            contentRef,
            WorkflowStatus,
            tabs,
            previousTab,
            nextTab,
            isFinalized,
            hasProcessedSample,
            finalWizardActions,
            showCancelButton,
            scrollUp,
        };
    },
});
