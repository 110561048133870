




















































import { FormBlock, TwButton } from '@/app/components';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { InternalApiHarvesterConfiguration, TFile } from '../../../types';
import { SampleUpload } from '../common';

export default defineComponent({
    name: 'SetupInternalApiHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<InternalApiHarvesterConfiguration>,
            required: true,
        },
        sample: {
            type: [Object, Array],
            default: null,
        },
        sampleFile: {
            type: File as PropType<TFile | null>,
            default: null,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        inDraftStatus: {
            type: Boolean,
            default: false,
        },
    },
    components: { ValidationObserver, FormBlock, SampleUpload, TwButton, ChevronRightIcon },
    setup(props, { emit }) {
        const apiValidationRef = ref<any>(null);

        const dataTypes = [
            { label: 'Text only', value: 'text' },
            { label: 'Text and Binary', value: 'textBinary' },
        ];

        const internalApiConfiguration: Ref<InternalApiHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: InternalApiHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const validateAndProceed = async () => {
            if (!apiValidationRef.value) return;

            const valid = await apiValidationRef.value.validate();
            if (!valid) return;

            emit('next-tab');
        };

        const setCroppedSample = (cropped: boolean) => {
            internalApiConfiguration.value.isSampleCropped = cropped;
        };

        return { internalApiConfiguration, apiValidationRef, dataTypes, validateAndProceed, setCroppedSample };
    },
});
