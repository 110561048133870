








































































































import { ConfirmModal, InputErrorIcon, JsonEditor, Scrollbar } from '@/app/components';
import { ApiHarvesterMethod, ApiHarvesterParameter, ApiHarvesterParams } from '@/modules/apollo/types';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { clone, equals, filter, find, isEmpty, isNil, pathEq, reject } from 'ramda';
import { ValidationProvider } from 'vee-validate';
import ClickOutside from 'vue-click-outside';
import { CheckIcon, PencilAltIcon, XIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import UrlPreview from './UrlPreview.vue';

export default defineComponent({
    name: 'APIRequest',
    model: { prop: 'params', event: 'changed' },
    props: {
        params: { type: Object as PropType<ApiHarvesterParams>, required: true },
        disabled: { type: Boolean, default: false },
    },
    directives: {
        ClickOutside,
    },
    components: {
        ValidationProvider,
        InputErrorIcon,
        Scrollbar,
        ConfirmModal,
        JsonEditor,
        CheckIcon,
        PencilAltIcon,
        XIcon,
        ExclamationIcon,
        UrlPreview,
    },
    setup(props, { emit }) {
        const initializeRequest = (params: ApiHarvesterParams) => {
            return {
                ...params,
                url: params.url || '',
                method: params.method || 'GET',
                ignoreCertificates: params.ignoreCertificates || false,
            };
        };

        const urlRef = ref<any>();
        const editingURL = ref<boolean>(isNil(props.params.url) || isEmpty(props.params.url));
        const showChangeMethodModal = ref<boolean>(false);
        const oldMethod = ref<ApiHarvesterMethod>();
        const newMethod = ref<ApiHarvesterMethod>();
        const request: Ref<ApiHarvesterParams> = ref<ApiHarvesterParams>(initializeRequest(clone(props.params)));

        const requestUrl = computed({
            get: () => {
                return `${request.value.url}`;
            },
            set: (newUrl: string) => {
                const { valid, url, parameters } = processUrl(newUrl);
                if (valid) {
                    request.value.url = url || '';
                    request.value.parameters = parameters;
                }
            },
        });

        const isPayloadValid = computed(() => {
            try {
                if (isNil(request.value.payload) || isEmpty(request.value.payload)) return false;
                // replace any variables so that the match passes
                JSON.parse(request.value.payload.replaceAll(/\{[A-Za-z0-9_-]+\}/g, '""'));
                return true;
            } catch (e) {
                return false;
            }
        });

        const extractURLParams = (url: string) => {
            if (!url || url.length === 0) return [];
            const matches = url.match(/\{([A-Za-z0-9_-]+)\}/g);
            const urlParameters: ApiHarvesterParameter[] = [];
            if (matches !== null) {
                for (let i = 0; i < matches.length; i += 1) {
                    const key = matches[i].split('{')[1].split('}')[0];
                    const existingParameter: ApiHarvesterParameter | undefined = find(
                        pathEq(['key', 'name'], key),
                        request.value.parameters,
                    );

                    const parameter: ApiHarvesterParameter = {
                        key: { name: key, type: 'url' },
                        value: {
                            value: existingParameter?.value.value,
                            type: existingParameter?.value.type,
                            format: existingParameter?.value.format,
                            category: existingParameter?.value.category,
                        },
                        sensitive: existingParameter?.sensitive || false,
                    };

                    urlParameters.push(parameter);
                }
            }
            return urlParameters;
        };

        /**
         * Processes the user's input url
         * if there are any query parameters then they are extracted
         * (even though we do not allow the typing of a query parameter the user might paste URL containing query params)
         * after extracting query params we extract url params
         * we remove from the existing listed parameters any parameters that have been removed and add the new ones
         * TODO extract this function into a composable that will make the processing more cleaninly and hide
         * the complexity
         *
         * @param url - the url to process
         */

        const processUrl = (url: string) => {
            const parameters: ApiHarvesterParameter[] = clone(request.value.parameters);
            const existingQueryParams = Object.values(request.value.parameters)
                .filter((param: ApiHarvesterParameter) => param.key.type === 'query')
                .map((param: ApiHarvesterParameter) => param.key.name);

            let valid = true;
            const urlParts = url.split('?');
            if (urlParts.length > 2) {
                valid = false;
            }
            if (valid && urlParts.length < 3 && urlParts[1]) {
                const queryParams = urlParts[1].split('&');
                queryParams.forEach((query: string) => {
                    const queryParts = query.split('=');
                    if (
                        queryParts[0].length > 0 &&
                        queryParts[0].charAt(0) === '{' &&
                        queryParts[0].charAt(queryParts[0].length - 1) === '}'
                    ) {
                        queryParts[0] = queryParts[0].slice(1, -1);
                    }
                    if (queryParts[0].length === 0 || !queryParts[0].match(/[A-Za-z0-9_-]+$/g)) {
                        valid = false;
                        return;
                    }
                    let queryValue = '';
                    if (queryParts[1]) [queryValue] = [queryParts[1]];
                    if (!existingQueryParams.includes(queryParts[0]))
                        parameters.push({
                            key: {
                                name: queryParts[0],
                                type: 'query',
                            },
                            value: {
                                value: queryValue,
                                category: 'static',
                                type: undefined,
                            },
                            sensitive: false,
                        });
                });
            }
            if (!valid) return { valid: false, url: undefined, parameters: [] };
            return {
                valid: true,
                url: urlParts[0],
                parameters: [...reject(pathEq(['key', 'type'], 'url'), parameters), ...extractURLParams(urlParts[0])],
            };
        };

        const parseURL = () => {
            if (!editingURL.value || showChangeMethodModal.value) {
                showChangeMethodModal.value = false;
                return;
            }
            const { valid, url, parameters } = processUrl(request.value.url);

            if (urlRef.value && urlRef.value.errors.length === 0) {
                if (valid) {
                    let finalURL = url;
                    if (url?.length && !url?.startsWith('http://') && !url?.startsWith('https://'))
                        finalURL = `http://${finalURL}`;
                    request.value.url = finalURL || '';
                    request.value.parameters = parameters;
                } else {
                    urlRef.value.errors.push('Invalid url');
                }
            }

            emit('changed', clone(request.value));
            editingURL.value = !url?.length;
        };

        const extractBodyParams = () => {
            if (!request.value.payload) return;
            const matches = request.value.payload.match(/\{([A-Za-z0-9_-]+)\}/g);
            let parameters = request.value.parameters.filter(
                (param: ApiHarvesterParameter) =>
                    param.key.type !== 'body' || (param.key.type === 'body' && param.value.category === 'pagination'),
            );
            const bodyParams = filter(pathEq(['key', 'type'], 'body'), request.value.parameters);

            if (matches !== null) {
                for (let i = 0; i < matches.length; i += 1) {
                    const key = matches[i].split('{')[1].split('}')[0];
                    const record = find(pathEq(['key', 'name'], key), bodyParams);

                    const param: ApiHarvesterParameter = {
                        key: {
                            name: key,
                            type: record?.key.type ? record?.key.type : 'body',
                        },
                        value: {
                            value: record?.value.value,
                            type: record?.value.type,
                            format: record?.value.format,
                            category: record?.value.category,
                        },
                        sensitive: record?.sensitive ? record?.sensitive : false,
                    };

                    parameters.push(param);
                }
            }
            request.value.parameters = parameters;
            emit('changed', clone(request.value));
        };

        const confirmChangeMethod = (event: any) => {
            oldMethod.value = request.value.method;
            newMethod.value = event.target.value;
            if (newMethod.value === oldMethod.value || newMethod.value !== 'GET') {
                request.value.method = newMethod.value as ApiHarvesterMethod;
                emit('changed', clone(request.value));

                return;
            }
            showChangeMethodModal.value = true;
        };

        const changeMethod = () => {
            if (newMethod.value === 'GET')
                request.value.parameters = request.value.parameters.reduce(
                    (acc: ApiHarvesterParameter[], p: ApiHarvesterParameter) => {
                        if (p.key.type === 'body' && p.value.category === 'pagination')
                            acc.push({
                                ...p,
                                key: { ...p.key, type: 'query' },
                            });
                        else if (p.key.type !== 'body') acc.push(p);
                        return acc;
                    },
                    [],
                );
            request.value.payload = null;
            request.value.method = newMethod.value as ApiHarvesterMethod;
            showChangeMethodModal.value = false;
            emit('changed', clone(request.value));
        };

        const doNotChangeMethod = () => {
            request.value.method = oldMethod.value as ApiHarvesterMethod;
            oldMethod.value = undefined;
        };

        const cancelEditingURL = () => {
            request.value.url = props.params.url || '';
            editingURL.value = !request.value.url.length;
        };

        watch(
            () => props.params,
            (newParams: ApiHarvesterParams) => {
                if (!equals(newParams, request.value)) {
                    request.value = initializeRequest(newParams);
                }
            },
            { immediate: true, deep: true },
        );

        return {
            requestUrl,
            editingURL,
            request,
            urlRef,
            showChangeMethodModal,
            isPayloadValid,
            parseURL,
            cancelEditingURL,
            changeMethod,
            confirmChangeMethod,
            doNotChangeMethod,
            extractBodyParams,
        };
    },
});
