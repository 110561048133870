

















import { MonitoringAPI } from '@/app/api';
import { WorkflowType } from '@/app/constants';
import { useAxios } from '@/app/composable';
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { isNil } from 'ramda';
import { ExecutionHistoryEntry } from '../../types';
import ExecutionOverview from './ExecutionOverview.vue';

export default defineComponent({
    name: 'Executions',
    components: { ExecutionOverview },
    props: {
        executions: {
            type: Array as PropType<ExecutionHistoryEntry[]>,
            required: true,
        },
        expandExecutionId: {
            type: String,
        },
        workflowId: {
            type: String,
            required: true,
        },
        workflowType: {
            type: String as PropType<WorkflowType>,
            required: true,
            validator: (value: string) => (Object.values(WorkflowType) as string[]).includes(value),
        },
    },
    setup(props, { emit }) {
        const { exec } = useAxios(true);
        const collapsedExecution = ref<ExecutionHistoryEntry | undefined>(
            props.expandExecutionId
                ? props.executions.find((e: ExecutionHistoryEntry) => e.executionId === props.expandExecutionId)
                : undefined,
        );
        const executionLogs = ref<any>([]);
        const loadingLogs = ref<boolean>(false);

        const getExecutionLogs = async (execution: ExecutionHistoryEntry) => {
            loadingLogs.value = true;
            const res = await exec(
                MonitoringAPI.executionLogs(execution.executionId, props.workflowId, props.workflowType),
            );
            loadingLogs.value = false;
            return res ? res.data : [];
        };

        const cancelExecution = (uid: string | number) => {
            emit('cancel-execution', uid);
        };

        watch(
            () => [props.expandExecutionId, props.executions],
            async ([expandExecutionId, newExecutions]) => {
                if (expandExecutionId && newExecutions)
                    collapsedExecution.value = expandExecutionId
                        ? (newExecutions as ExecutionHistoryEntry[]).find(
                              (e: ExecutionHistoryEntry) => e.executionId === expandExecutionId,
                          )
                        : undefined;
            },
            { immediate: true },
        );

        watch(
            () => collapsedExecution.value,
            async (newCollapsed: ExecutionHistoryEntry | undefined) => {
                if (!isNil(newCollapsed)) executionLogs.value = await getExecutionLogs(newCollapsed);
            },
            { immediate: true },
        );

        return {
            executionLogs,
            loadingLogs,
            collapsedExecution,
            cancelExecution,
        };
    },
});
