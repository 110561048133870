








import { PropType, defineComponent } from '@vue/composition-api';
import { AnonymisationField } from '../../types';

export default defineComponent({
    name: 'InsensitiveAnonymisation',
    model: {
        prop: 'field',
        event: 'changed',
    },
    props: {
        field: { type: Object as PropType<AnonymisationField>, required: true },
        inEdit: { type: Boolean, default: false },
    },
});
