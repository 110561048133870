









































































































































































import { FormBlock, SearchBox } from '@/app/components';
import { useFilters } from '@/app/composable';
import { Concept, Model } from '@/app/interfaces';
import store from '@/app/store';
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import Fuse from 'fuse.js';
import { alternateNamingOptions } from '../../constants';
import { MappingConfiguration } from '../../types';

export default defineComponent({
    name: 'MappingInfo',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<MappingConfiguration>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        model: {
            type: Object as PropType<Model>,
            required: true,
        },
        restrictingConcept: {
            type: Object as PropType<Concept>,
            default: null,
        },
        concepts: {
            type: Array as PropType<Concept[]>,
            required: true,
        },
        fetchedConceptsFrom: {
            type: Number,
            default: 0,
        },
    },
    components: { FormBlock, SearchBox, SelfBuildingSquareSpinner, ChevronDownIcon, ChevronRightIcon },
    setup(props) {
        const searchText = ref<string>('');
        const hiddenCategories = ref<string[]>([]);
        const mappingConcepts = ref<Concept[]>(props.concepts);
        const domainChanged = ref<boolean>(false);

        const { splitCamelCase } = useFilters();

        const domains = computed(() => store.getters.dataModel.domains);

        const standards = computed(() => {
            if (domains.value && props.configuration.domain) {
                const domain = domains.value.find((obj: Model) => obj.id === props.configuration.domain?.id);
                if (domain) return domain.standardsMapping;
            }
            return [];
        });

        const filteredConcepts = computed(() => {
            if (searchText.value.trim() === '') return mappingConcepts.value;

            const fuseSearch = new Fuse(mappingConcepts.value, {
                keys: [
                    { name: 'name', weight: 3 },
                    { name: 'relatedTerms', weight: 1 },
                ],
                shouldSort: true,
                threshold: 0.1,
            });
            return fuseSearch.search(searchText.value).map((result) => result.item);
        });

        const filteredConceptsPerCategory = computed(() =>
            filteredConcepts.value
                .reduce((acc: { category: string; concepts: Concept[]; show: boolean }[], givenConcept: Concept) => {
                    const relatedTerm = givenConcept.relatedTerms?.find((term: string) => term.startsWith('Category:'));
                    const category: string = relatedTerm ? relatedTerm.slice(9) : 'No category';
                    const foundCategory:
                        | { category: string; concepts: Concept[]; show: boolean }
                        | undefined = acc.find((element: { category: string }) => element.category === category);
                    if (foundCategory) foundCategory.concepts.push(givenConcept);
                    else
                        acc.push({
                            category,
                            concepts: [givenConcept],
                            show: !hiddenCategories.value.includes(category),
                        });
                    return acc;
                }, [])
                .sort(),
        );

        const changeDomain = (reset = true) => {
            searchText.value = '';
            hiddenCategories.value = [];
            if (reset) {
                props.configuration.concept = null;
                props.configuration.standard = null;
            }
            if (props.configuration.domain && (!props.model || props.configuration.domain.uid !== props.model.uid)) {
                store.dispatch.modelBrowser.setModel({ model: props.configuration.domain.uid });
                domainChanged.value = true;
            }
            mappingConcepts.value = props.concepts;
        };

        const changeConcept = () => {
            if (
                props.configuration.concept &&
                (!props.restrictingConcept || props.configuration.concept.uid !== props.restrictingConcept.uid)
            )
                store.dispatch.modelBrowser.setRestrictingConcept({ concept: props.configuration.concept.id });
        };

        const toggleConcepts = (category: string) => {
            if (hiddenCategories.value.includes(category))
                hiddenCategories.value.splice(hiddenCategories.value.indexOf(category), 1);
            else hiddenCategories.value.push(category);
        };

        if (props.configuration.domain) changeDomain(false);

        watch(
            () => props.concepts,
            async (modelConcepts: any) => {
                if (domainChanged.value && props.fetchedConceptsFrom === props.configuration.domain?.id) {
                    mappingConcepts.value = modelConcepts;
                    domainChanged.value = false;
                }
            },
        );

        return {
            alternateNamingOptions,
            searchText,
            domains,
            standards,
            filteredConceptsPerCategory,
            domainChanged,
            changeDomain,
            splitCamelCase,
            toggleConcepts,
            changeConcept,
        };
    },
});
