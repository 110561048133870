



































































































































































import { ButtonGroup, Scrollbar, SvgImage, Toggle, TwButton } from '@/app/components';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import {
    AnonymisableFieldDatatype,
    AnonymisationConfigurationType,
    AnonymisationField,
    AnonymisationStatistics,
    AnonymisationType,
    GeneralisationMethod,
    IdentifierOptionsField,
    QuasiIdentifierOptionsField,
    TaskStats,
} from '../../types';
import AnonymisationFieldOverview from './AnonymisationFieldOverview.vue';
import { MappingFieldConfiguration } from '../../types/typings';
import { clone, equals, isNil } from 'ramda';
import InsensitiveAnonymisation from './InsensitiveAnonymisation.vue';
import IdentifierAnonymisation from './IdentifierAnonymisation.vue';
import MaskingAnonymisationField from './MaskingAnonymisationField.vue';
import NumericalAnonymisationField from './NumericalAnonymisationField.vue';
import DateAnonymisationField from './DateAnonymisationField.vue';
import BooleanAnonymisationField from './BooleanAnonymisationField.vue';
import SensitiveAnonymisation from './SensitiveAnonymisation.vue';
import AnonymisationOverallConfiguration from './AnonymisationOverallConfiguration.vue';
import SampleValues from '../SampleValues.vue';
import { ValidationObserver } from 'vee-validate';
import { ChevronRightIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'AnonymisationConfiguration',
    model: {
        prop: 'configuration',
        event: 'changed',
    },
    props: {
        configuration: { type: Object as PropType<AnonymisationConfigurationType>, required: true },
        fieldMappingConfigurations: {
            type: Object as PropType<Record<string, MappingFieldConfiguration>>,
            required: true,
        },
        sample: { type: Array, required: true },
        previousProcessedSample: { type: Array, required: true },
        loading: { type: Boolean, default: false },
        isFinalized: { type: Boolean, default: false },
        isUnderRevise: { type: Boolean, default: false },
        hasConfigurationChanges: { type: Boolean, default: false },
        statistics: { type: Object as PropType<TaskStats<AnonymisationStatistics>> },
        errors: {
            type: Array as PropType<
                { category: string; error: { title: string; description: string }; linkToHistory: boolean }[]
            >,
            default: () => [],
        },
    },
    components: {
        Scrollbar,
        ButtonGroup,
        Toggle,
        AnonymisationFieldOverview,
        SvgImage,
        SampleValues,
        ValidationObserver,
        TwButton,
        ChevronRightIcon,
        AnonymisationOverallConfiguration,
    },
    setup(props, { root, emit }) {
        const anonymisationTypes: { value: AnonymisationType; label: string }[] = [
            { value: 'insensitive', label: 'Insensitive' },
            { value: 'identifier', label: 'Identifier' },
            { value: 'quasi-identifier', label: 'Quasi-Identifier' },
            { value: 'sensitive', label: 'Sensitive' },
        ];
        const anonymisationRef = ref();
        const isConfigurationValid = ref(false);

        const fields: Ref<AnonymisationField[]> = computed({
            get: () => props.configuration.fields,
            set: (updatedFields: AnonymisationField[]) => {
                emit('changed', {
                    ...props.configuration,
                    fields: updatedFields,
                });
            },
        });

        const taskConfiguration = computed({
            get: () => props.configuration,
            set: (updatedConfiguration: AnonymisationConfigurationType) => {
                emit('changed', updatedConfiguration);
            },
        });

        const nextTooltip = computed(() => {
            if (!isNil(fieldInEdit.value))
                return `Please apply or save changes on field currently on edit before proceeding`;
            if (!isConfigurationValid.value)
                return `Please correct any invalid configurations for k-anonymity and the acceptable information loss threshold`;
            if (props.loading) return `Please wait for the ongoing request to finish before proceeding`;
            if (isUnderReviseAndNoChange.value)
                return `You need to make some changes in order to proceed after choosing to revise`;

            return `Proceed to running anonymisation sample run`;
        });

        const fieldFilter = ref<'all' | AnonymisationType>('all');
        const selectedFieldIdentifier = ref<string | undefined>();
        const showAlternateNaming = ref<boolean>(false);
        const fieldInEdit: Ref<AnonymisationField | undefined> = ref<AnonymisationField | undefined>();

        const fieldFilterOptions = [
            { label: 'All', tooltip: 'All Fields', key: 'all' },
            { label: 'Insensitive', tooltip: 'Only insensitive fields', key: 'insensitive' },
            { label: 'Identifier', tooltip: 'Only identifier fields', key: 'identifier' },
            { label: 'Quasi-Identifier', tooltip: 'Only quasi-identifier fields', key: 'quasi-identifier' },
            { label: 'Sensitive', tooltip: 'Only sensitive fields', key: 'sensitive' },
        ];

        const fieldsMap = computed(() =>
            fields.value.reduce((acc: Record<number, AnonymisationField>, field: AnonymisationField) => {
                acc[field.anonymisationIdentifier] = field;
                return acc;
            }, {}),
        );

        const selectedField: Ref<AnonymisationField | undefined> = computed(() => {
            if (!selectedFieldIdentifier.value) return undefined;
            return fieldsMap.value[selectedFieldIdentifier.value];
        });

        const getMappingField = (field: AnonymisationField, considerAlternateNaming = true) => {
            const fieldConfiguration = props.fieldMappingConfigurations[field.anonymisationIdentifier];
            const title =
                showAlternateNaming.value && considerAlternateNaming && fieldConfiguration.alias
                    ? `${fieldConfiguration.alias}${fieldConfiguration.multiple ? '[1]' : ''}`
                    : fieldConfiguration.title;
            return { ...fieldConfiguration, title, originalTitle: fieldConfiguration.title };
        };

        const getFullField = (field: AnonymisationField, considerAlternateNaming = true) => {
            return { ...field, ...getMappingField(field, considerAlternateNaming) };
        };

        const sampleViewSelectedFields = computed(() => {
            if (selectedField.value) return [getFullField(selectedField.value, false)];
            return [];
        });

        const selectedFieldComponent = computed(() => {
            if (!fieldInFocus.value) return undefined;
            switch (fieldInFocus.value.anonymisationType) {
                case 'insensitive':
                    return InsensitiveAnonymisation;
                case 'identifier':
                    return IdentifierAnonymisation;
                case 'quasi-identifier':
                    return quasiIdentifierBaseConfiguration[fieldInFocus.value.type].component;
                case 'sensitive':
                    return SensitiveAnonymisation;
                default:
                    return undefined;
            }
        });

        const filteredFields = computed(() => {
            const filtered =
                fieldFilter.value === 'all'
                    ? fields.value
                    : fields.value.filter((field: AnonymisationField) => field.anonymisationType === fieldFilter.value);
            return filtered.map((field: AnonymisationField) => {
                return getFullField(field);
            });
        });

        const hasAlternateNames = computed(() =>
            Object.values(props.fieldMappingConfigurations).some((f: MappingFieldConfiguration) => !isNil(f.alias)),
        );

        const fieldInFocus = computed({
            get: () => fieldInEdit.value || selectedField.value,
            set: (updatedField: AnonymisationField | undefined) => {
                if (!isNil(fieldInEdit.value)) fieldInEdit.value = updatedField;
            },
        });

        const mappingFieldInFocus = computed(() =>
            fieldInFocus.value ? getMappingField(fieldInFocus.value) : undefined,
        );

        const hasChanges = computed(() => fieldInEdit.value && !equals(fieldInEdit.value, selectedField.value));

        const isUnderReviseAndNoChange = computed(
            () =>
                props.isUnderRevise &&
                !taskConfiguration.value.hasChangesAfterRevise &&
                !props.hasConfigurationChanges &&
                props.errors.length > 0,
        );

        const validateAndProceed = () => {
            const sensitives = fields.value.filter((field) => field.anonymisationType === 'sensitive');
            const quasiIdentifiers = props.configuration.fields.filter(
                (field) => field.anonymisationType === 'quasi-identifier',
            );
            if (sensitives.length > 0 && !quasiIdentifiers.length) {
                (root as any).$toastr.i(
                    'Using Sensitive fields, you must set at least one field as Quasi-Identifier.',
                    'Warning',
                );
            } else {
                emit('next-tab');
            }
        };

        const saveFieldChanges = () => {
            fields.value = fields.value.map((field: AnonymisationField) => {
                if (fieldInEdit.value?.anonymisationIdentifier === field.anonymisationIdentifier)
                    return clone(fieldInEdit.value);
                return field;
            });
            // if field not in filtered fields then switch to all
            if (
                fieldInEdit.value &&
                fieldFilter.value !== 'all' &&
                fieldFilter.value !== (fieldInEdit.value as AnonymisationField).anonymisationType
            )
                fieldFilter.value = 'all';

            fieldInEdit.value = undefined;
        };

        const clearSelection = () => {
            selectedFieldIdentifier.value = undefined;
        };

        const quasiIdentifierBaseConfiguration: Record<
            AnonymisableFieldDatatype,
            {
                generalisation: GeneralisationMethod | undefined;
                options: IdentifierOptionsField | QuasiIdentifierOptionsField;
                component: any;
            }
        > = {
            string: {
                generalisation: 'masking',
                options: {
                    maskingChar: '*',
                    paddingChar: '#',
                    maskingDirection: 'rtl',
                    paddingDirection: 'ltr',
                    nullValues: {
                        keep: true,
                    },
                },
                component: MaskingAnonymisationField,
            },
            boolean: {
                generalisation: 'boolean-group',
                options: {
                    show: true,
                    nullValues: {
                        keep: true,
                    },
                },
                component: BooleanAnonymisationField,
            },
            datetime: {
                generalisation: 'datetime',
                options: {
                    finalLevel: 5,
                    nullValues: {
                        keep: true,
                    },
                },
                component: DateAnonymisationField,
            },
            date: {
                generalisation: 'date',
                options: {
                    finalLevel: 3,
                    nullValues: {
                        keep: true,
                    },
                },
                component: DateAnonymisationField,
            },
            time: {
                generalisation: 'time',
                options: {
                    finalLevel: 3,
                    nullValues: {
                        keep: true,
                        replaceWith: { hours: 0, minutes: 0, seconds: 0 },
                    },
                },
                component: DateAnonymisationField,
            },
            integer: {
                generalisation: 'interval',
                options: {
                    leveling: 'auto',
                    levels: [{ interval: null }],
                    nullValues: {
                        keep: true,
                    },
                },
                component: NumericalAnonymisationField,
            },
            double: {
                generalisation: 'interval',
                options: {
                    leveling: 'auto',
                    levels: [{ interval: null }],
                    nullValues: {
                        keep: true,
                    },
                },
                component: NumericalAnonymisationField,
            },
        };

        const changedFieldType = (anonymisationType: AnonymisationType) => {
            if (!!anonymisationType && fieldInEdit.value && anonymisationType !== fieldInEdit.value.anonymisationType) {
                let options = undefined;
                let generalisation: GeneralisationMethod | undefined = undefined;

                if (anonymisationType === 'identifier') {
                    options = {
                        anonymisationMethod: 'drop',
                    };
                } else if (anonymisationType === 'quasi-identifier') {
                    generalisation = quasiIdentifierBaseConfiguration[fieldInEdit.value.type].generalisation;
                    options = clone(quasiIdentifierBaseConfiguration[fieldInEdit.value.type].options);
                }

                fieldInEdit.value = {
                    ...(fieldInEdit.value as AnonymisationField),
                    anonymisationType,
                    generalisation,
                    options,
                };
            }
        };

        const changeSelectedField = (newSelectedFieldIdentifier: string) => {
            if (!!fieldInEdit.value && fieldInEdit.value.anonymisationIdentifier !== newSelectedFieldIdentifier)
                (root as any).$toastr.i('Apply your changes before you proceed.', 'Warning');
            else if (!fieldInEdit.value) {
                selectedFieldIdentifier.value = newSelectedFieldIdentifier;
                fieldInEdit.value = undefined;
            }
        };

        const validate = async () => {
            if (anonymisationRef.value) {
                isConfigurationValid.value = await anonymisationRef.value.validate();
                emit('validated', isConfigurationValid.value);
            }
        };

        watch(() => anonymisationRef.value, validate, { immediate: true });

        return {
            fields,
            filteredFields,
            fieldFilter,
            fieldFilterOptions,
            selectedFieldIdentifier,
            fieldInEdit,
            showAlternateNaming,
            hasAlternateNames,
            selectedFieldComponent,
            selectedField,
            sampleViewSelectedFields,
            hasChanges,
            fieldInFocus,
            mappingFieldInFocus,
            anonymisationRef,
            nextTooltip,
            taskConfiguration,
            isUnderReviseAndNoChange,
            isConfigurationValid,
            anonymisationTypes,
            saveFieldChanges,
            clearSelection,
            clone,
            changedFieldType,
            changeSelectedField,
            validate,
            validateAndProceed,
        };
    },
});
