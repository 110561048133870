











































import { ConfirmModal, FormBlock } from '@/app/components';
import { Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { RetrievalType } from '../../../constants';

export default defineComponent({
    name: 'RetrievalSettings',
    model: {
        prop: 'retrievalType',
        event: 'change',
    },
    props: {
        title: {
            type: String,
            default: 'Retrieval Settings',
        },
        description: {
            type: String,
            default: 'Define how often data should be fetched',
        },
        retrievalType: {
            type: String,
            default: null,
        },
        schedulesExist: {
            type: Boolean,
            default: false,
        },
        isRunning: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        allowPeriodic: {
            type: Boolean,
            default: true,
        },
    },
    components: { FormBlock, ConfirmModal },
    setup(props, { emit }) {
        const defaultRetrievalTypes = {
            [RetrievalType.Immediately]: 'Retrieve Immediately',
            [RetrievalType.Once]: 'Retrieve Once',
            // [RetrievalType.Polling]: 'Polling (every 60 seconds)',
        };

        const retrievalTypes = computed(() =>
            props.allowPeriodic
                ? {
                      ...defaultRetrievalTypes,
                      [RetrievalType.Periodic]: 'Periodic Retrieval (according to schedule)',
                  }
                : defaultRetrievalTypes,
        );

        const newRetrievalType = ref<RetrievalType | undefined>();

        const selectedRetrievalType: Ref<string> = computed({
            get: () => props.retrievalType,
            set: (retrievalTypeToBeSet: string) => {
                emit('change', retrievalTypeToBeSet);
            },
        });

        const checkRetrievalTypeChange = (event: any) => {
            if (event.target.value !== selectedRetrievalType.value && props.schedulesExist)
                newRetrievalType.value = event.target.value;
            else confirmRetrievalTypeChange(event.target.value);
        };

        const confirmRetrievalTypeChange = (retrievalType: RetrievalType) => {
            selectedRetrievalType.value = retrievalType;
            newRetrievalType.value = undefined;
        };

        return {
            newRetrievalType,
            retrievalTypes,
            selectedRetrievalType,
            checkRetrievalTypeChange,
            confirmRetrievalTypeChange,
        };
    },
});
