




































































































































import { AlertBanner, FormBlock } from '@/app/components';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { MqttConnectionDetails } from '../../../types';

export default defineComponent({
    name: 'MqttConnectionDetails',
    model: {
        prop: 'connectionDetails',
        event: 'change',
    },
    props: {
        connectionDetails: {
            type: Object as PropType<MqttConnectionDetails>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { FormBlock, ValidationProvider, AlertBanner },
    setup(props, { emit }) {
        const mqttConnectionDetails: Ref<MqttConnectionDetails> = computed({
            get: () => props.connectionDetails,
            set: (newConnectionDetails: MqttConnectionDetails) => {
                emit('change', newConnectionDetails);
            },
        });

        const securityProtocols = [
            { value: 'plain', label: 'Plain' },
            { value: 'ssl', label: 'SSL' },
        ];

        const selectedSecurityProtocol = computed(
            () =>
                securityProtocols.find(
                    (securityProtocol: { value: string; label: string }) =>
                        securityProtocol.value === mqttConnectionDetails.value.securityProtocol,
                )?.label,
        );

        return { securityProtocols, selectedSecurityProtocol, mqttConnectionDetails };
    },
});
