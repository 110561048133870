



















import { AlertBanner, FormBlock, JsonParser } from '@/app/components';
import { useApolloTask } from '@/modules/apollo/composable';
import { ApolloTask, KafkaHarvesterConfiguration, TFile } from '@/modules/apollo/types';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import * as R from 'ramda';

export default defineComponent({
    name: 'ReviewKafkaHarvester',
    components: {
        AlertBanner,
        FormBlock,
        JsonParser,
    },
    props: {
        task: {
            type: Object as PropType<ApolloTask<KafkaHarvesterConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        sample: {
            type: [Array, Object],
            required: false,
        },
        sampleFile: {
            type: File as PropType<TFile>,
            required: false,
        },
    },
    setup(props) {
        const { isFinalized } = useApolloTask(computed(() => props.task));

        const isSampleArray = computed(() => R.is(Array, props.sample));

        const displaySampleIsArrayBanner = computed(() => isSampleArray.value && !isFinalized && !props.loading);

        return {
            displaySampleIsArrayBanner,
        };
    },
});
