



















































































































import { FormBlock } from '@/app/components';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { useHarvester } from '../../../composable';
import { HarvesterBlockId } from '@/modules/apollo/constants';
import { HarvesterResponseConfiguration } from '@/modules/apollo/types';

export default defineComponent({
    name: 'ResponseHandling',
    components: {
        FormBlock,
    },
    model: { prop: 'configuration', event: 'changed' },
    props: {
        configuration: {
            type: Object as PropType<HarvesterResponseConfiguration>,
            required: true,
        },
        source: { type: String as PropType<HarvesterBlockId> },
        basePath: {
            type: String,
            default: 'res',
        },
        completed: {
            type: Boolean,
            default: true,
        },

        parsedSample: {
            type: [Object, Array],
            required: true,
        },
        separator: {
            type: String,
            default: '||',
        },
    },
    setup(props, { emit, root }) {
        const fileSource = 'dc.FilesHarvester';
        const { findArrayPath } = useHarvester(root, emit);
        const isSampleArray = computed(() => R.is(Array, props.parsedSample));
        const focusChoice = ref<string>(props.configuration?.multiple ? 'specificPath' : 'wholeObject');
        const focusSelectedNestedArrayBasePath = ref<string | null>(null);
        const objectData = computed(() => (R.is(Object, props.parsedSample) ? props.parsedSample : {}));

        /**
         * Calculates if there are any inner arrays, in order to give the user the option to select a different base path other than the root.
         */
        const focusNestedArrays = computed(() => {
            let arrays: string[] = [];
            if (objectData.value && !isSampleArray.value) {
                Object.keys(objectData.value).forEach((key: string) => {
                    let objArrays: string[] | null = findArrayPath(objectData.value[key], key);
                    if (objArrays) {
                        for (const objArray of objArrays) {
                            let data = objectData.value;
                            const path = objArray.split(props.separator); // can be nested
                            for (const pathKey of path) data = data[pathKey];
                            for (const obj of data) {
                                if (!R.is(Object, obj)) {
                                    objArrays = objArrays.filter((arrayName: string) => arrayName !== objArray);
                                    break;
                                }
                            }
                        }

                        arrays = [...arrays, ...objArrays];
                    }
                });
            }

            return arrays;
        });

        /**
         * Calculates to true in case the user has selected a different base path other than the root but has also selected nodes outside
         * this base path so that we show to the user a warning how these node selections will be handled
         */
        const focusSelectedObjectsFromOutside = computed(() => {
            if (
                props.configuration?.basePath &&
                focusChoice.value === 'specificPath' &&
                focusSelectedNestedArrayBasePath.value
            ) {
                for (let i = 0; i < props.configuration.selectedItems.length; i++) {
                    const arrayPath = props.configuration.selectedItems[i];
                    if (!arrayPath.startsWith(props.configuration.basePath)) {
                        return true;
                    }
                }
            }
            return false;
        });

        const pipelineConfig = computed(() => {
            if (objectData.value && props.basePath) {
                let { basePath }: { basePath: string | null } = props;
                let multiple = false;

                if (isSampleArray.value) {
                    multiple = true;
                } else if (focusChoice.value === 'specificPath' && !R.isNil(focusSelectedNestedArrayBasePath.value)) {
                    basePath = `${props.basePath}${props.separator}${focusSelectedNestedArrayBasePath.value}`;
                    multiple = true;
                }

                return {
                    basePath,
                    multiple,
                };
            }

            return null;
        });

        watch(
            () => pipelineConfig.value,
            (config: any) => {
                if (!props.completed) {
                    emit('changed', { ...props.configuration, basePath: config.basePath, multiple: config.multiple });
                }
            },
            { immediate: true },
        );

        watch(
            () => focusChoice.value,
            (choice: string) => {
                if (
                    choice === 'specificPath' &&
                    focusNestedArrays.value.length > 0 &&
                    !focusSelectedNestedArrayBasePath.value
                ) {
                    // eslint-disable-next-line prefer-destructuring
                    focusSelectedNestedArrayBasePath.value = focusNestedArrays.value[0];
                }
            },
        );

        if (
            !isSampleArray.value &&
            props.configuration?.basePath &&
            focusNestedArrays.value.includes(
                props.configuration.basePath.replace(`${props.basePath}${props.separator}`, ''),
            )
        ) {
            focusSelectedNestedArrayBasePath.value = props.configuration.basePath.replace(
                `${props.basePath}${props.separator}`,
                '',
            );
        } else if (focusNestedArrays.value.length > 0) {
            focusSelectedNestedArrayBasePath.value = focusNestedArrays.value[0];
        }

        return {
            fileSource,
            isSampleArray,
            focusNestedArrays,
            focusSelectedObjectsFromOutside,
            focusChoice,
            focusSelectedNestedArrayBasePath,
        };
    },
});
