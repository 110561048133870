
































































































































































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { outliersRuleOptions } from '../../constants';
import { Constraint, ConstraintType, OutliersRuleType, SecondaryRuleType } from '../../types/cleaning.type';

export default defineComponent({
    name: 'OutliersRule',
    props: {
        constraint: {
            type: Object as PropType<Constraint>,
            required: true,
        },
        selectedFieldsTypes: {
            type: Array as PropType<string[]>,
            required: true,
        },
        previousProcessedSample: {
            type: Array,
            default: null,
        },
        isAdvancedConstraint: {
            type: Boolean,
            default: false,
        },
        allowDropOrDefaultOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const availableOutliersRuleOptions = computed(() => {
            let options = outliersRuleOptions;

            switch (props.constraint.type) {
                case ConstraintType.MANDATORY:
                case ConstraintType.RANGE:
                case ConstraintType.NOT_RANGE:
                case ConstraintType.RANGE_EXCLUDING:
                case ConstraintType.REGULAR_EXPRESSION:
                case ConstraintType.NOT_REGULAR_EXPRESSION:
                    // DROP, DEFAULT_VALUE, PREVIOUS_VALUE, MOST_FREQUENT, MEAN_VALUE, MEDIAN_VALUE, MIN_VALUE, MAX_VALUE
                    options = options.slice(0, 8);
                    break;
                case ConstraintType.SET_MEMBERSHIP:
                    // DROP, DEFAULT_VALUE, PREVIOUS_VALUE, MOST_FREQUENT, MOST_SIMILAR
                    options = options.slice(0, 4).concat(options.slice(-1));
                    break;
                case ConstraintType.UNIQUE:
                case ConstraintType.CROSS_FIELD:
                    // DROP, DEFAULT_VALUE,
                    options = options.slice(0, 2);
                    break;
                case ConstraintType.FOREIGN_KEY:
                case ConstraintType.NOT_FOREIGN_KEY:
                    // DROP
                    options = options.slice(0, 1);
                    break;
                default:
                    options = options.slice(0, 8);
            }

            if (props.selectedFieldsTypes.length > 1) {
                return options.filter((option: any) => option.id === 'DROP');
            }

            if (props.allowDropOrDefaultOnly) {
                return options.filter((option: any) => option.id === 'DROP' || option.id === 'DEFAULT_VALUE');
            }

            if (
                props.selectedFieldsTypes.includes('datetime') ||
                props.selectedFieldsTypes.includes('date') ||
                props.selectedFieldsTypes.includes('time')
            ) {
                return options.filter((option: any) => option.id !== 'MEAN_VALUE' && option.id !== 'MEDIAN_VALUE');
            }
            if (props.selectedFieldsTypes.includes('string') || props.selectedFieldsTypes.includes('boolean')) {
                return options.filter(
                    (option: any) =>
                        option.id !== 'MEAN_VALUE' &&
                        option.id !== 'MEDIAN_VALUE' &&
                        option.id !== 'MIN_VALUE' &&
                        option.id !== 'MAX_VALUE',
                );
            }
            return options;
        });

        const setOutliersRule = (type: OutliersRuleType) => {
            props.constraint.outliersRule = {
                type,
                replaceValue: null,
                secondaryRule: null,
            };

            if (type === 'DEFAULT_VALUE') {
                if (props.selectedFieldsTypes.includes('time'))
                    props.constraint.outliersRule.replaceValue = new Date().setHours(0, 0, 0, 0);
                else if (props.selectedFieldsTypes.includes('boolean'))
                    props.constraint.outliersRule.replaceValue = true;
            }

            if (type === 'PREVIOUS_VALUE') {
                props.constraint.outliersRule.secondaryRule = {
                    type: SecondaryRuleType.DEFAULT_VALUE,
                    replaceValue: props.selectedFieldsTypes.includes('boolean') ? true : null,
                };
            }
        };

        return { availableOutliersRuleOptions, setOutliersRule };
    },
});
