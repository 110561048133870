var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row justify-between space-x-4 text-xs bg-primary-800",class:{
        'px-4 py-2 text-xs': _vm.compact,
        'p-4 text-base': !_vm.compact,
    }},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            "The k-anonymity algorithm is used to anonymise the data. The quasi-identifying fields will be\n                    suppressed or generalised until each row/record is identical with at least k-1 other\n                    rows/records."
        ),expression:"\n            `The k-anonymity algorithm is used to anonymise the data. The quasi-identifying fields will be\n                    suppressed or generalised until each row/record is identical with at least k-1 other\n                    rows/records.`\n        "}],staticClass:"flex flex-row items-center w-1/2 space-x-2 cursor-help",class:{ ' space-x-4': !_vm.compact }},[_c('span',{staticClass:"font-semibold text-left text-primary-200",attrs:{"for":"k-anonymity"}},[_vm._v(" Anonymisation Algorithm ")]),_c('ValidationProvider',{staticClass:"relative flex",attrs:{"rules":{ required: true, min_value: 2, max_value: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('span',{staticClass:"flex items-center pl-3 text-white select-none whitespace-nowrap"},[_vm._v("k-anonymity(k=")]),(_vm.disabled)?_c('span',{staticClass:"text-lg font-bold text-secondary-400",attrs:{"id":"k-anonymity"}},[_vm._v(_vm._s(_vm.kAnonymity))]):_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.kAnonymity),expression:"kAnonymity",modifiers:{"number":true}}],staticClass:"block p-0.5 mx-0.5 max-w-10 w-12 text-neutral-900",class:{ 'border-danger-700 bg-danger-200': errors.length > 0 },attrs:{"id":"k-anonymity","type":"number","name":"k-anonymity","min":"2","max":"20"},domProps:{"value":(_vm.kAnonymity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.kAnonymity=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.disabled && errors.length > 0)?_c('InputErrorIcon',{staticClass:"right-3",attrs:{"tooltip":{
                    content: errors[0],
                    placement: 'bottom',
                }}}):_vm._e(),_c('span',{staticClass:"flex items-center pr-3 text-white select-none"},[_vm._v(")")])]}}])})],1),(!_vm.isNil(_vm.statistics))?_c('div',{staticClass:"flex flex-row items-center w-1/2 space-x-2 cursor-help"},[_c('span',{staticClass:"font-semibold text-left text-primary-200"},[_vm._v(" Achieved Information Loss: ")]),_c('span',{staticClass:"text-lg font-bold",class:_vm.statistics.anonymisationTotalInformationLoss > _vm.configuration.lossLimit
                    ? 'text-danger-400'
                    : 'text-success-400'},[(!_vm.isNil(_vm.statistics.anonymisationTotalInformationLoss))?[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.statistics.anonymisationTotalInformationLoss))+"% ")]:[_vm._v("-")]],2)]):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            "The percentage of information loss that is acceptable for the specific dataset. If it is\n                    overcome, the anonymisation step will fail as the data will be destroyed beyond your preference."
        ),expression:"\n            `The percentage of information loss that is acceptable for the specific dataset. If it is\n                    overcome, the anonymisation step will fail as the data will be destroyed beyond your preference.`\n        "}],staticClass:"flex flex-row items-center w-1/2 space-x-2 cursor-help"},[_c('span',{staticClass:"font-semibold text-left text-primary-200",attrs:{"for":"information-loss-threshold"}},[_vm._v(" Acceptable Information Loss Threshold ")]),_c('ValidationProvider',{staticClass:"relative flex",attrs:{"rules":{ required: true, min_value: 1, max_value: 99 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [(_vm.disabled)?_c('span',{staticClass:"text-lg font-bold text-secondary-400",attrs:{"id":"information-loss-threshold"}},[_vm._v(_vm._s(_vm.informationLossThreshold))]):_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.informationLossThreshold),expression:"informationLossThreshold",modifiers:{"number":true}}],staticClass:"block w-12 text-left p-0.5 max-w-10 text-neutral-900",class:{ 'border-danger-700 bg-danger-200': errors.length > 0 },attrs:{"id":"information-loss-threshold","type":"number","name":"information-loss-threshold","min":"1","max":"99"},domProps:{"value":(_vm.informationLossThreshold)},on:{"input":function($event){if($event.target.composing){ return; }_vm.informationLossThreshold=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.disabled && errors.length > 0)?_c('InputErrorIcon',{staticClass:"right-7",attrs:{"tooltip":{
                    content: errors[0],
                    placement: 'bottom',
                }}}):_vm._e(),_c('span',{staticClass:"flex items-center text-white select-none",class:{ 'px-3': _vm.compact }},[_vm._v("%")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }