







import { IntervalRule } from '@/modules/apollo/types';
import { PropType, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'IntervalRule',
    props: {
        rule: {
            type: Object as PropType<IntervalRule>,
            required: true,
        },
    },
});
