var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.executions.results,"loading":_vm.loading,"sortBy":_vm.query.sortBy,"pagination":_vm.executions.pagination},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"workflow-id",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"workflow-name",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Pipeline does not exist")])]}},{key:"createdAt",fn:function(ref){
var formattedValue = ref.formattedValue;
var formattedTooltip = ref.formattedTooltip;
return [_c('div',{staticClass:"w-24 whitespace-nowrap"},[_c('span',{staticClass:"block group-hover:hidden"},[_vm._v(_vm._s(formattedValue))]),_c('span',{staticClass:"hidden group-hover:block"},[_vm._v(_vm._s(formattedTooltip))])])]}},{key:"workflow-name-tooltip",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workflow.description)+" ")]}},{key:"errors",fn:function(ref){
var value = ref.value;
return [(!value || value.length === 0)?_c('div',{staticClass:"italic text-neutral-600"},[_vm._v("No failures")]):(value.length === 1)?_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('ExecutionError',{attrs:{"error":value[0].errorCode}}),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1):_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('ExecutionError',{attrs:{"error":value[0].errorCode}}),_c('span',{staticClass:"italic text-neutral-700"},[_vm._v(" (and "+_vm._s(value.length - 1)+" more "+_vm._s(value.length - 1 === 1 ? 'failure' : 'failures')+") ")]),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1)]}},{key:"errors-tooltip",fn:function(ref){
var value = ref.value;
return [(!value || value.length === 0)?_c('div',{staticClass:"italic text-neutral-600"},[_vm._v("No failures")]):(value.length === 1)?_c('div',[_c('ExecutionError',{attrs:{"error":value[0].errorCode,"extended":true,"details":value[0].mappedField}})],1):_c('div',{staticClass:"flex flex-col"},_vm._l((value),function(error,idx){return _c('ExecutionError',{key:((error.errorCode) + "_" + idx),staticClass:"mb-1",attrs:{"error":error.errorCode,"extended":true,"details":error.mappedField}})}),1)]}},{key:"footerRow",fn:function(){return [(_vm.rejectedItems)?[_vm._v(" Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted ")]:_vm._e()]},proxy:true},{key:"noRowsMessage",fn:function(){return [(!_vm.rejectedItems)?_c('span',[_vm._v("No data to show")]):_c('span',[_vm._v("Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }