









import { MaskingRule } from '@/modules/apollo/types';
import { PropType, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'MaskingRule',
    props: {
        rule: {
            type: Object as PropType<MaskingRule>,
            required: true,
        },
    },
});
