














































































































































import { FormBlock } from '@/app/components';
import { maxLengthValidator, minLengthValidator, regexValidator, requiredValidator } from '@/app/validators';
import { PlusIcon, TrashIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { MqttConnectionDetails } from '../../../../types';

extend('required', requiredValidator);
extend('min', minLengthValidator);
extend('max', maxLengthValidator);
extend('main_topic_regex_validator', {
    ...regexValidator,
    message: 'Topic must contain only alphanumeric characters.',
});
extend('sub_topic_regex_validator', {
    ...regexValidator,
    message:
        'The sub-topic path must contain only alphanumeric characters and if needed, forward slashes (for dividing the sub-topic names) and each sub-topic name must have a length of at least 2 characters.',
});

export default defineComponent({
    name: 'MqttTopicHierarchy',
    model: {
        prop: 'connectionDetails',
        event: 'change',
    },
    props: {
        connectionDetails: {
            type: Object as PropType<MqttConnectionDetails>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        external: {
            type: Boolean,
            default: false,
        },
        hasSameSubtopics: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormBlock,
        PlusIcon,
        TrashIcon,
        ValidationProvider,
        ExclamationIcon,
        ValidationObserver,
    },
    setup(props, { emit }) {
        const qosOptions = [
            { value: 0, label: 'QoS0' },
            { value: 1, label: 'QoS1' },
            { value: 2, label: 'QoS2' },
        ];

        const mqttConnectionDetails: Ref<MqttConnectionDetails> = computed({
            get: () => props.connectionDetails,
            set: (newConnectionDetails: MqttConnectionDetails) => {
                emit('change', newConnectionDetails);
            },
        });

        const mainTopicNotSet = computed(() => !mqttConnectionDetails.value.topic);

        const hasSubtopics = computed(() =>
            mqttConnectionDetails.value.topics.length ? isSubtopic(mqttConnectionDetails.value.topics[0].name) : false,
        );

        const isSubtopic = (name: string) => name.startsWith(`${mqttConnectionDetails.value.topic}/`);

        if (!mqttConnectionDetails.value.topics.length) mqttConnectionDetails.value.topics.push({ name: '', qos: 0 });

        const addSubtopic = () => {
            if (
                mqttConnectionDetails.value.topics.length === 1 &&
                mqttConnectionDetails.value.topics[0].name === mqttConnectionDetails.value.topic
            )
                mqttConnectionDetails.value.topics.splice(0);
            mqttConnectionDetails.value.topics.push({
                name: `${mqttConnectionDetails.value.topic}/`,
                qos: 0,
            });
        };

        const removeSubtopic = (index: number) => {
            mqttConnectionDetails.value.topics.splice(index, 1);
            if (mqttConnectionDetails.value.topics.length === 0)
                mqttConnectionDetails.value.topics.push({
                    name: mqttConnectionDetails.value.topic,
                    qos: 0,
                });
        };

        const subtopicChanged = (event: any, index: number) => {
            mqttConnectionDetails.value.topics[
                index
            ].name = `${mqttConnectionDetails.value.topic}/${event.target.value}`;
        };

        const mainTopicChanged = (event: any) => {
            mqttConnectionDetails.value.topics[0].name = event.target.value;
            mqttConnectionDetails.value.topic = event.target.value;
        };

        return {
            mqttConnectionDetails,
            addSubtopic,
            removeSubtopic,
            isSubtopic,
            subtopicChanged,
            hasSubtopics,
            qosOptions,
            mainTopicNotSet,
            mainTopicChanged,
        };
    },
});
