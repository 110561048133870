var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormBlock',{attrs:{"title":"Store Topic Information within the Data","description":"Details about any sub-topic that should be added in each record/row."}},[_c('div',{staticClass:"flex items-center w-full"},[(_vm.isFinalized && !_vm.topicParameterName)?_c('div',{staticClass:"text-neutral-600"},[_vm._v(" No Parameter has been configured. ")]):(!_vm.editingParameterName)?_c('div',{staticClass:"flex items-center justify-between w-full py-1"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('div',[_vm._v("Topic/Sub-topic name to be stored within the data as Parameter")]),_c('div',{staticClass:"font-semibold truncate"},[_vm._v(_vm._s(_vm.topicNameField))])]),_c('div',{staticClass:"flex space-x-2"},[(!_vm.editingParameterName && !_vm.isFinalized)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Edit Parameter'),expression:"'Edit Parameter'"}],staticClass:"text-neutral-500 hover:text-neutral-700",on:{"click":function($event){_vm.editingParameterName = true}}},[_c('PencilAltIcon',{staticClass:"w-5 h-5"})],1):_vm._e(),(_vm.topicParameterName && !_vm.editingParameterName && !_vm.isFinalized)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delete Parameter'),expression:"'Delete Parameter'"}],staticClass:"text-neutral-500 hover:text-neutral-700",on:{"click":function($event){return _vm.deleteParameterName()}}},[_c('TrashIcon',{staticClass:"w-5 h-5"})],1):_vm._e()])]):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.resetValidationRef),expression:"resetValidationRef"}],staticClass:"flex items-center justify-between w-full space-x-2"},[_c('span',[_vm._v("Parameter ")]),_c('ValidationProvider',{ref:"topicNameFieldValidator",staticClass:"flex w-full space-x-2",attrs:{"rules":{
                    min: 2,
                    max: 20,
                    regex: /^[A-Z a-z0-9_-]*[a-zA-Z]+[A-Z a-z0-9_-]*$/,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.topicParameterName),expression:"topicParameterName",modifiers:{"trim":true}},{name:"tooltip",rawName:"v-tooltip",value:(_vm.errorTooltip(errors) || _vm.topicParameterName),expression:"errorTooltip(errors) || topicParameterName"}],staticClass:"block w-full h-8 pr-16 sm:text-sm sm:leading-5",class:{
                        'border-red-700 ':
                            errors.length > 0 ||
                            (_vm.parameterExistsInSampleKeys && _vm.previousParameterValue !== _vm.topicParameterName),
                        'truncate font-semibold': !_vm.editingParameterName,
                    },attrs:{"id":"parameter name","disabled":!_vm.editingParameterName,"placeholder":"Enter Parameter Name","type":"text"},domProps:{"value":(_vm.topicParameterName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.topicParameterName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"flex space-x-2"},[(_vm.editingParameterName && !_vm.isFinalized)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.errorTooltip(errors) || 'Set Parameter Name'),expression:"errorTooltip(errors) || 'Set Parameter Name'"}],staticClass:"text-neutral-600 disabled:cursor-not-allowed disabled:text-neutral-400",class:{ 'hover:text-neutral-700': !_vm.parameterExistsInSampleKeys },attrs:{"disabled":_vm.parameterExistsInSampleKeys || errors.length > 0 || !_vm.topicParameterName},on:{"click":function($event){return _vm.setParameterToSample(_vm.topicParameterName)}}},[_c('CheckIcon',{staticClass:"w-5 h-5"})],1):_vm._e(),(_vm.editingParameterName && !_vm.isFinalized && _vm.previousParameterValue)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Cancel Editing Parameter'),expression:"'Cancel Editing Parameter'"}],staticClass:"text-neutral-500 hover:text-neutral-700",on:{"click":_vm.cancelEditingParameter}},[_c('XIcon',{staticClass:"w-5 h-5"})],1):_vm._e()])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }