var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"workflowId",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"asset",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value)?[_vm._v(_vm._s(value.name))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Asset "+_vm._s(row.assetId)+" does not exist")])]}},{key:"asset-tooltip",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value)?_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('span',{staticClass:"px-2 pt-1 pb-0.5 text-xs rounded bg-neutral-200 text-neutral-900"},[_vm._v(" ID "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(row.assetId))])]),(value)?_c('span',[_vm._v(" "+_vm._s(value.name)+" ")]):_vm._e()]),(value)?_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" "+_vm._s(value.description)+" ")]):_vm._e()]):_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" Asset "+_vm._s(row.assetId)+" does not exist any more ")])])]}},{key:"timeliness",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(!_vm.isStreamingPipeline(row))?_c('TimelinessBadge',{attrs:{"value":value}}):_c('div',{staticClass:"px-1 py-0.5 text-neutral-600 bg-neutral-200 rounded"},[_vm._v("N/A")])]}},{key:"timeliness-tooltip",fn:function(ref){
var row = ref.row;
return [(_vm.isStreamingPipeline(row))?_c('div',{staticClass:"text-sm"},[_vm._v(" Quality metrics are not available for this type of DCPs ")]):_vm._e()]}},{key:"timeliness-icon",fn:function(){return [_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: "Timeliness in this view is updated whenever there is an expected execution of any data check-in pipeline. The expected execution of a data check-in pipeline is generally calculated based on the time elapsed since its previous execution, considering the dataset’s accrual periodicity.",
                    classes: 'max-w-lg break-words',
                }),expression:"{\n                    content: `Timeliness in this view is updated whenever there is an expected execution of any data check-in pipeline. The expected execution of a data check-in pipeline is generally calculated based on the time elapsed since its previous execution, considering the dataset’s accrual periodicity.`,\n                    classes: 'max-w-lg break-words',\n                }"}],staticClass:"w-4 h-4 text-yellow-500"})]},proxy:true},{key:"footerRow",fn:function(){return [(_vm.rejectedItems)?[_vm._v(" Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted ")]:_vm._e()]},proxy:true},{key:"noRowsMessage",fn:function(){return [(!_vm.rejectedItems)?_c('span',[_vm._v("No data to show")]):_c('span',[_vm._v("Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }