






















































































import { FormBlock } from '@/app/components';
import { maxLengthValidator, minLengthValidator, regexValidator } from '@/app/validators';
import { CheckIcon, PencilAltIcon, TrashIcon, XIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationProvider, extend } from 'vee-validate';
import ClickOutside from 'vue-click-outside';

extend('min', minLengthValidator);
extend('max', maxLengthValidator);
extend('regex', {
    ...regexValidator,
    message:
        'Parameter Name must contain only alphanumeric characters, dashes, underscores, spaces and at least one letter.',
});

export default defineComponent({
    name: 'MqttTopicParameter',
    directives: {
        ClickOutside,
    },
    components: {
        FormBlock,
        ValidationProvider,
        PencilAltIcon,
        CheckIcon,
        XIcon,
        TrashIcon,
    },
    props: {
        topicNameField: {
            type: String,
            default: null,
        },
        sample: {
            type: [Object, Array],
            default: null,
        },
        finalSample: {
            type: [Object, Array],
            default: null,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const topicParameterName = ref(props.topicNameField || null);
        const topicNameFieldValidator = ref<any>(null);
        const editingParameterName = ref(!props.topicNameField);
        const previousParameterValue = ref(props.topicNameField || null);

        const isSampleArray = computed(() => R.is(Array, props.sample));

        const sampleKeys = computed(() => {
            const keys: string[] = [];

            if (isSampleArray.value) {
                (props.sample as any).forEach((record: any) =>
                    Object.keys(record).forEach((key: string) => {
                        if (!keys.includes(key)) keys.push(key);
                    }),
                );
            } else {
                Object.keys(props.sample as any).forEach((key: string) => {
                    if (!keys.includes(key)) keys.push(key);
                });
            }
            return keys;
        });

        const parameterExistsInSampleKeys = computed(
            () => !!(topicParameterName.value && sampleKeys.value.includes(topicParameterName.value)),
        );

        const setParameter = (parameter: string | null, sampleToSet: any) => {
            if (isSampleArray.value) {
                sampleToSet.forEach((row: any) => {
                    if (previousParameterValue.value && (!parameter || previousParameterValue.value))
                        delete row[previousParameterValue.value];
                    if (parameter) row[parameter] = 'SUB_TOPIC';
                });
            } else {
                if (previousParameterValue.value && (!parameter || previousParameterValue.value))
                    delete sampleToSet[previousParameterValue.value];
                if (parameter) sampleToSet[parameter] = 'SUB_TOPIC';
            }
            return sampleToSet;
        };

        const setParameterToSample = (parameter: string | null) => {
            editingParameterName.value = false;
            emit('set-parameter-name', parameter);
            emit('modify-final-sample', setParameter(parameter, R.clone(props.finalSample)));
            emit('modify-sample', setParameter(parameter, R.clone(props.sample)));
            previousParameterValue.value = parameter;
        };

        const deleteParameterName = () => {
            topicParameterName.value = null;
            setParameterToSample(topicParameterName.value);
            editingParameterName.value = true;
        };

        const cancelEditingParameter = () => {
            editingParameterName.value = false;
            topicParameterName.value = previousParameterValue.value;
        };

        const errorTooltip = (errors: any) => {
            if (errors && errors.length > 0) return errors[0];
            if (parameterExistsInSampleKeys.value && previousParameterValue.value !== topicParameterName.value)
                return 'The provided Parameter Name already exists as a field in the Sample. Please define a different Parameter Name.';
            return null;
        };

        const resetValidationRef = () => {
            if (!topicParameterName.value) topicNameFieldValidator.value.reset();
        };

        return {
            topicNameFieldValidator,
            editingParameterName,
            previousParameterValue,
            isSampleArray,
            topicParameterName,
            deleteParameterName,
            resetValidationRef,
            cancelEditingParameter,
            parameterExistsInSampleKeys,
            setParameterToSample,
            errorTooltip,
        };
    },
});
