









import { PropType, defineComponent } from '@vue/composition-api';
import { AnonymisationStatistics, TaskStats } from '../../../types';

export default defineComponent({
    name: 'SensitiveRule',
    props: {
        statistics: { type: Object as PropType<TaskStats<AnonymisationStatistics>> },
    },
});
