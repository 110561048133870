




































































import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useCleaning } from '../../../composable';
import { CleaningFieldConfiguration, Condition, Constraint } from '../../../types/cleaning.type';
import * as R from 'ramda';

export default defineComponent({
    name: 'ConstraintText',
    props: {
        types: {
            type: Array,
            required: true,
        },
        constraint: {
            type: Object as PropType<Constraint | Condition>,
            required: true,
        },
        ending: {
            type: String,
            default: ',',
        },
        fields: {
            type: Array as PropType<CleaningFieldConfiguration[]>,
            default: () => [],
        },
        hasStats: {
            type: Boolean,
            default: false,
        },
        nested: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const getConstraintText = ref<any>();
        const getOutliersRuleText = ref<any>();
        const typesRef = computed(() => props.types);

        const isCondition = (constraint: Condition | Constraint): constraint is Condition => {
            return R.has('conditionUid', constraint) || R.has('conditions', constraint);
        };

        watch(
            () => props.types,
            () => {
                const cleaning = useCleaning(typesRef as any, props.fields);
                getConstraintText.value = cleaning.getConstraintText;
                getOutliersRuleText.value = cleaning.getOutliersRuleText;
            },
            { immediate: true },
        );

        return { getConstraintText, getOutliersRuleText, isCondition };
    },
});
