var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('FormBlock',{staticClass:"mt-8",attrs:{"title":"Topic Hierarchy","description":"The hierarchy including the topic and its sub-topic(s) (if any), where messages will be published, along with their corresponding quality of service (QoS)"}},[(_vm.hasSubtopics)?_c('label',{staticClass:"block w-full text-sm bg-blue-200 border border-blue-300 opacity-75 form-input",attrs:{"name":"Message","type":"text"}},[_vm._v(" By defining sub-topics, any messages posted in the main topic will be ignored. ")]):_vm._e(),(_vm.external && !_vm.hasSubtopics && !_vm.disabled)?_c('div',{staticClass:"flex items-center w-full space-x-4"},[_c('div',{staticClass:"flex items-center w-3/4"},[_c('div',{staticClass:"flex-initial w-40 mr-4 text-sm text-neutral-700"},[_c('span',[_vm._v("Topic")])]),_c('ValidationProvider',{staticClass:"block w-full",attrs:{"rules":{
                        required: true,
                        min: 2,
                        max: 20,
                        main_topic_regex_validator: /^[A-Za-z0-9]{2,}$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(errors.length ? errors[0] : _vm.mqttConnectionDetails.topics[0].name),expression:"errors.length ? errors[0] : mqttConnectionDetails.topics[0].name"}],staticClass:"block w-full text-sm form-input",class:{ 'border-red-600': errors.length > 0 },attrs:{"name":"Topic name","type":"text","placeholder":"Enter Main Topic"},domProps:{"value":_vm.mqttConnectionDetails.topics[0].name},on:{"input":function($event){return _vm.mainTopicChanged($event)}}})]}}],null,true)})],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mqttConnectionDetails.topics[0].qos),expression:"mqttConnectionDetails.topics[0].qos"},{name:"tooltip",rawName:"v-tooltip",value:(
                    'The expected consensus between the parts publisher-to-broker and broker-to-subscriber on the guarantee to dispatch an MQTT message successfully'
                ),expression:"\n                    'The expected consensus between the parts publisher-to-broker and broker-to-subscriber on the guarantee to dispatch an MQTT message successfully'\n                "}],staticClass:"w-1/3 py-2 pl-3 pr-8 text-sm form-input",attrs:{"name":"Quality of Service"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.mqttConnectionDetails.topics[0], "qos", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.qosOptions),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)]):_vm._l((_vm.mqttConnectionDetails.topics),function(subtopic,idx){return _c('div',{key:idx},[_c('div',{staticClass:"flex items-center w-full",class:{ 'space-x-4': !(_vm.disabled && !_vm.hasSubtopics) }},[_c('div',{staticClass:"w-3/4",class:{ 'w-1/3': !(_vm.disabled && !_vm.hasSubtopics) }},[(!_vm.isSubtopic(subtopic.name))?_c('div',{staticClass:"flex items-center"},[(!_vm.hasSubtopics)?_c('span',{staticClass:"w-32 mr-2 text-sm text-neutral-700"},[_vm._v("Topic")]):_vm._e(),_c('div',{class:{ 'mr-4': _vm.disabled && !_vm.hasSubtopics }},[_vm._v(" "+_vm._s(subtopic.name)+" ")])]):_c('div',{staticClass:"flex items-center w-full space-x-1"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.external ? 'Main Topic' : ''),expression:"external ? 'Main Topic' : ''"}]},[_vm._v(_vm._s(_vm.mqttConnectionDetails.topic))]),_c('span',[_vm._v("/")]),_c('ValidationProvider',{staticClass:"block w-full",attrs:{"rules":{
                                    required: true,
                                    max: 30,
                                    sub_topic_regex_validator: /^([a-zA-Z0-9]{2,}\/?)*[A-Za-z0-9]{2,}$/,
                                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                        errors.length
                                            ? errors[0]
                                            : subtopic.name.split(((_vm.mqttConnectionDetails.topic) + "/"))[1]
                                            ? ((subtopic.name.split(((_vm.mqttConnectionDetails.topic) + "/"))[1]) + ": the sub-topic in a hierarchy that will be used under the " + (_vm.external ? 'defined' : 'automatically generated') + " topic.")
                                            : ''
                                    ),expression:"\n                                        errors.length\n                                            ? errors[0]\n                                            : subtopic.name.split(`${mqttConnectionDetails.topic}/`)[1]\n                                            ? `${\n                                                  subtopic.name.split(`${mqttConnectionDetails.topic}/`)[1]\n                                              }: the sub-topic in a hierarchy that will be used under the ${\n                                                  external ? 'defined' : 'automatically generated'\n                                              } topic.`\n                                            : ''\n                                    "}],staticClass:"block w-full text-sm form-input",class:{ 'border-red-600': errors.length > 0, truncate: _vm.disabled },attrs:{"name":"Sub-topic path","type":"text","disabled":_vm.disabled},domProps:{"value":subtopic.name.split(((_vm.mqttConnectionDetails.topic) + "/"))[1]},on:{"input":function($event){return _vm.subtopicChanged($event, idx)}}})]}}],null,true)})],1)]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(subtopic.qos),expression:"subtopic.qos"},{name:"tooltip",rawName:"v-tooltip",value:(
                            'The expected consensus between the parts publisher-to-broker and broker-to-subscriber on the guarantee to dispatch an MQTT message successfully'
                        ),expression:"\n                            'The expected consensus between the parts publisher-to-broker and broker-to-subscriber on the guarantee to dispatch an MQTT message successfully'\n                        "}],staticClass:"w-1/4 py-2 pl-3 pr-8 text-sm form-input",attrs:{"name":"Quality of Service","disabled":_vm.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(subtopic, "qos", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.qosOptions),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),(_vm.isSubtopic(subtopic.name) && !_vm.disabled)?_c('TrashIcon',{staticClass:"flex-grow-0 flex-shrink-0 w-5 h-5 cursor-pointer text-neutral-600 hover:text-red-600",on:{"click":function($event){return _vm.removeSubtopic(idx)}}}):_vm._e()],1)])}),(!_vm.mainTopicNotSet && !_vm.disabled)?_c('div',{staticClass:"flex justify-between mt-4"},[_c('div',[(_vm.hasSameSubtopics)?_c('div',{staticClass:"flex items-end space-x-2 text-sm text-warn-700"},[_c('ExclamationIcon',{staticClass:"w-5 h-5"}),_c('div',[_vm._v("Duplicate sub-topics")])],1):_vm._e()]),_c('button',{staticClass:"flex items-center px-3 py-1 text-xs font-medium leading-5 text-white uppercase transition duration-150 ease-in-out border rounded border-neutral-600 hover:border-neutral-500 bg-neutral-600 hover:bg-neutral-500 focus:outline-none focus:ring-neutral focus:border-neutral-700 active:bg-neutral-700 disabled:bg-neutral-400 disabled:cursor-default disabled:border-neutral-300",attrs:{"type":"button","disabled":invalid},on:{"click":_vm.addSubtopic}},[_c('PlusIcon',{staticClass:"w-4 h-4 mr-1"}),_c('span',[_vm._v("Add Sub-Topic")])],1)]):_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }