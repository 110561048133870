var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-4"},[(_vm.files.length)?_vm._l((_vm.files),function(file,idx){return _c('div',{key:("file-" + idx),staticClass:"flex items-center space-x-2"},[_c('DocumentIcon',{staticClass:"flex-grow-0 flex-shrink-0 w-4 h-4"}),_c('div',{staticClass:"flex-grow"},[_vm._v(_vm._s(file.name || file.filename))]),_c('div',{staticClass:"flex-none w-40"},[(
                        _vm.progressId > idx ||
                        (_vm.progressId === idx && _vm.progress === 100) ||
                        file.status === _vm.FileUploadStatus.Pending
                    )?_c('TwProgressBar',{attrs:{"color":"bg-green-500","percentage":100,"height":"h-4"}},[_c('span',{staticClass:"flex justify-end w-full pr-2 text-xs text-white uppercase"},[_vm._v("100%")])]):(_vm.progressId === idx)?_c('TwProgressBar',{attrs:{"color":"bg-primary-500","percentage":_vm.progress,"height":"h-4"}},[_c('span',{staticClass:"flex justify-end w-full pr-2 text-xs text-white"},[_vm._v(_vm._s(_vm.progress)+"%")])]):(_vm.progressId < idx)?_c('TwProgressBar',{attrs:{"color":"bg-blue-500","percentage":0,"height":"h-4"}},[_c('span',{staticClass:"flex justify-end w-full pr-2 text-xs text-white"},[_vm._v("0%")])]):_vm._e()],1),_c('div',{staticClass:"flex-none w-24 font-semibold text-right"},[_vm._v(" "+_vm._s(_vm.formatBytes(file.size))+" ")]),(_vm.canDeleteFiles)?_c('button',{staticClass:"w-4",class:{
                    'text-neutral-300 cursor-not-allowed': _vm.disabled,
                    'text-red-800 hover:text-red-500': !_vm.disabled,
                },attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.removeFile(file)}}},[_c('XIcon',{staticClass:"w-4 h-4"})],1):_vm._e()],1)}):_c('div',{staticClass:"text-sm text-neutral-600"},[_vm._v("No files to be uploaded yet")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }