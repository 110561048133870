









































import { FormBlock } from '@/app/components';
import { computed, defineComponent, Ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';

export default defineComponent({
    name: 'RetrieveUntilSettings',
    components: {
        FormBlock,
        ValidationProvider,
    },
    model: {
        prop: 'endDate',
        event: 'update',
    },
    props: {
        title: {
            type: String,
            default: 'Retrieval Settings',
        },
        description: {
            type: String,
            default: 'Until when you want to retrieve data from Kafka topic?',
        },
        endDate: {
            type: [Date, String],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        running: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const retrievalEndDate: Ref<Date | string | undefined> = computed({
            get: () => props.endDate,
            set: (newDate: Date | string | undefined) => {
                emit('update', newDate);
            },
        });

        return {
            retrievalEndDate,
        };
    },
});
