































































import { TwButton } from '@/app/components';
import { useAxios } from '@/app/composable';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import { OrbitSpinner } from 'epic-spinners';
import { ValidationObserver } from 'vee-validate';
import { ApolloAPI } from '../../../api';
import { MqttHarvesterConfiguration, TFile } from '../../../types';
import { MqttProtocol, MqttTopicHierarchy, RetrieveUntilSettings, SampleUpload } from '../common';
import MqttConnectionDetails from './MqttConnectionDetails.vue';

export default defineComponent({
    name: 'SetupMqttHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<MqttHarvesterConfiguration>,
            required: true,
        },
        sample: {
            type: [Object, Array],
            default: null,
        },
        sampleFile: {
            type: File as PropType<TFile | null>,
            default: null,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        inDraftStatus: {
            type: Boolean,
            default: false,
        },
        pipelineFinalized: {
            type: Boolean,
            default: false,
        },
        pipelineId: {
            type: String,
            required: true,
        },
    },
    components: {
        ValidationObserver,
        OrbitSpinner,
        TwButton,
        MqttProtocol,
        SampleUpload,
        RetrieveUntilSettings,
        MqttConnectionDetails,
        MqttTopicHierarchy,
        ChevronRightIcon,
    },
    setup(props, { root, emit }) {
        const initialized = ref<boolean>(false);
        const initializationFailed = ref<boolean>(false);
        const mqttValidationRef = ref<any>(null);

        const { exec, loading } = useAxios(true);

        const mqttConfiguration: Ref<MqttHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: MqttHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const isInitialized = computed(() => !!mqttConfiguration.value.connectionDetails.username);

        const hasSameSubtopics = computed(() => {
            const subtopics: any = mqttConfiguration.value.connectionDetails?.topics
                ?.filter(
                    (subtopic: { name: string; qos: number }) =>
                        subtopic.name !== `${mqttConfiguration.value.connectionDetails.topic}/`,
                )
                .map((subtopic: { name: string; qos: number }) => subtopic.name);
            return !!subtopics.filter(
                (subtopic: { name: string; qos: number }, index: number) => subtopics.indexOf(subtopic) !== index,
            ).length;
        });

        const setCroppedSample = (cropped: boolean) => {
            mqttConfiguration.value.isSampleCropped = cropped;
        };

        const validateAndProceed = async () => {
            if (!mqttValidationRef.value) return;

            const valid = await mqttValidationRef.value.validate();
            if (!valid) return;

            // add 1 extra day in order to make it inclusive
            const inclusiveDate = dayjs.utc(mqttConfiguration.value.retrieval.endDate).add(1, 'day');

            if (!props.pipelineFinalized && inclusiveDate.isBefore(dayjs().utc())) {
                (root as any).$toastr.e(
                    'Retrieve Until Date is in the past. Please update it accordingly to continue.',
                    'Invalid Retrieve Until Date',
                );
                return;
            }

            emit('next-tab');
        };

        if (!isInitialized.value) {
            exec(
                ApolloAPI.createCredentials(props.pipelineId, {
                    protocolVersion: mqttConfiguration.value.connectionDetails.protocolVersion,
                    topics: mqttConfiguration.value.connectionDetails.topics,
                }),
            )
                .then((res: any) => {
                    if (res?.data) mqttConfiguration.value.connectionDetails = res.data;
                    initialized.value = true;
                })
                .catch(() => {
                    initializationFailed.value = true;
                });
        }

        return {
            loading,
            initialized,
            initializationFailed,
            hasSameSubtopics,
            mqttValidationRef,
            mqttConfiguration,
            setCroppedSample,
            validateAndProceed,
        };
    },
});
