











































































import { useFilters } from '@/app/composable';
import { WorkflowType } from '@/app/constants';
import { computed, defineComponent, ref, PropType } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import { ExecutionLog } from '../../types';

dayjs.extend(utc);

export default defineComponent({
    name: 'ExecutionLogs',
    props: {
        logs: {
            type: Array,
            required: true,
        },
        maxHeight: {
            type: String,
            default: '350px',
        },
        standalone: {
            type: Boolean,
            default: false,
        },
        hasPagination: {
            type: Boolean,
            default: false,
        },
        sort: {
            type: String,
            default: 'asc',
        },
        workflowType: {
            type: String as PropType<WorkflowType>,
            validator: (value: string) => (Object.values(WorkflowType) as string[]).includes(value),
        },
    },
    setup(props) {
        const { formatDateAs: formatDate } = useFilters();
        const contentRef = ref<any>(null);

        const longMessageToCopy = ref<any>(null);
        const copyToClipboard = () => {
            longMessageToCopy.value = document.querySelector('#longMessage');
            if (longMessageToCopy.value.value) {
                longMessageToCopy.value.setAttribute('type', 'text');
                longMessageToCopy.value.select();
                document.execCommand('copy');
                longMessageToCopy.value.setAttribute('type', 'hidden');
            }
        };

        const scrollDown = () => {
            if (contentRef.value) {
                contentRef.value.scrollTo({
                    top: contentRef.value.scrollHeight,
                    behavior: 'smooth',
                });
            }
        };

        const textColour = (type: string) => {
            switch (type) {
                case 'info':
                    return 'text-blue-300';
                case 'warn':
                    return 'text-orange-200';
                case 'error':
                    return 'text-red-200';
                default:
                    return 'text-neutral-200';
            }
        };

        const modifiedTimestamp = (timestamp: any) => {
            return `${formatDate(timestamp, true, 'DD/MM/YYYY')} ${formatDate(timestamp, true, 'HH:mm', 'UTC')}`;
        };

        const sortedLogs = computed<any[]>(() => {
            const clonedLogs = R.clone(props.logs) as ExecutionLog[];
            let sort = null;
            if (props.sort === 'asc') sort = R.sortWith([R.ascend(R.prop('timestamp'))]);
            else sort = R.sortWith([R.descend(R.prop('timestamp'))]);
            return sort(clonedLogs);
        });

        return {
            contentRef,
            textColour,
            modifiedTimestamp,
            scrollDown,
            sortedLogs,
            copyToClipboard,
            longMessageToCopy,
            WorkflowType,
        };
    },
});
