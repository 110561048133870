


































































import { ScheduleEditor, ViewSchedules } from '@/app/components/schedules';
import { UpdateScheduleDTO } from '@/app/types';
import { HarvesterBlockId } from '@/modules/apollo/constants';
import { ScheduleFrequency } from '@/modules/workflow-designer/constants';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
    name: 'Schedule',
    props: {
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
        error: {
            type: String,
            default: null,
        },
        pipelineId: {
            type: String,
            required: true,
        },
        closeEditor: {
            type: Boolean,
            required: false,
        },
        frameworkVersion: {
            type: String,
        },
        minimalLayout: {
            type: Boolean,
            default: false,
        },
        retrievalType: {
            type: String,
            required: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        frequencies: {
            type: Array as PropType<ScheduleFrequency[]>,
            default: () => ['hourly', 'daily', 'weekly', 'monthly'],
        },
        startDate: {
            type: [Date, Number],
            required: false,
        },
        defaultStartDate: {
            type: Boolean,
            default: false,
        },
        blockId: {
            type: String as PropType<HarvesterBlockId>,
            required: true,
        },
        schedulesPerFrequency: {
            type: Number,
            default: null,
        },
        loadingSchedule: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        requiredSchedulesMessage: {
            type: String,
            required: true,
        },
        frequency: {
            type: String,
            default: null,
        },
        batchable: {
            type: Boolean,
            default: false,
        },
        readOnlyMessage: {
            type: String,
            default: null,
        },
    },
    components: { ScheduleEditor, ViewSchedules },
    setup(props, { emit }) {
        const openScheduleEditor = ref(!props.schedules.length ? true : false);
        const errorInScheduleEditor = ref(null);
        const scheduleToBeEdited = ref<ScheduleType | null>(null);
        const scheduleToBeEditedIdx = ref<number | null>(null);
        const showScheduleEditCancelConfirmation = ref<boolean>(false);
        const changedSchedule: any = ref(null);
        const creationDifferencesState = ref<boolean>(false);
        const multipleSchedules = computed(
            () =>
                (props.retrievalType && props.retrievalType !== 'once') || props.frequency === ScheduleFrequency.Daily,
        );

        const saveSchedule = (schedule: ScheduleType) => {
            emit('add', schedule);
            closeScheduleEditor();
        };
        const updateSchedule = (schedule: UpdateScheduleDTO) => {
            emit('update', { idx: scheduleToBeEditedIdx.value, schedule });
            closeScheduleEditor();
        };
        const changeScheduleStatus = (schedule: any) => {
            emit('change-status', schedule);
        };
        const deleteSchedule = (schedule: any) => {
            emit('delete-schedule', schedule);
        };

        const differencesExist = computed(() => {
            if (scheduleToBeEdited.value && changedSchedule.value) {
                const scheduleToCompare = {
                    ...scheduleToBeEdited.value,
                    startDate: new Date(scheduleToBeEdited.value.startDate as any),
                    endDate: new Date(scheduleToBeEdited.value.endDate as any),
                };
                const checkDifferences = JSON.stringify(scheduleToCompare) !== JSON.stringify(changedSchedule.value);
                return checkDifferences;
            }
            return false;
        });

        const setChangedSchedule = (schedule: any) => {
            changedSchedule.value = schedule;
            emit('changed-schedule', differencesExist.value);
        };

        const addSchedule = () => {
            openScheduleEditor.value = true;
            scheduleToBeEdited.value = null;
            scheduleToBeEditedIdx.value = null;
        };

        const editSchedule = (event: { idx: number; schedule: ScheduleType }) => {
            const { idx, schedule } = event;
            if (scheduleToBeEdited.value && scheduleToBeEdited.value.id !== schedule.id) {
                emit('reset-error');
            }

            scheduleToBeEdited.value = schedule;
            scheduleToBeEditedIdx.value = idx;
            openScheduleEditor.value = true;
        };
        const closeScheduleEditor = () => {
            scheduleToBeEdited.value = null;
            scheduleToBeEditedIdx.value = null;
            openScheduleEditor.value = false;
        };

        const closeScheduleEditorAfterSave = () => {
            openScheduleEditor.value = false;
            scheduleToBeEdited.value = null;
            scheduleToBeEditedIdx.value = null;
            emit('reset-error');
        };

        const setCreationDifferencesState = (state: boolean) => {
            creationDifferencesState.value = state;
        };

        const disabledMessage = computed(() =>
            props.disabled
                ? 'The schedule will be automatically enabled once the configuration of the final step (i.e. the Loader Step) of the Data Check-in Pipeline is completed.'
                : null,
        );

        watch(
            () => props.error,
            (error: any) => {
                errorInScheduleEditor.value = R.clone(error);
            },
        );

        watch(
            () => props.closeEditor,
            (close: any) => {
                if (close) {
                    openScheduleEditor.value = false;
                    scheduleToBeEdited.value = null;
                    scheduleToBeEditedIdx.value = null;
                }
            },
        );

        return {
            openScheduleEditor,
            saveSchedule,
            changeScheduleStatus,
            deleteSchedule,
            editSchedule,
            scheduleToBeEdited,
            closeScheduleEditor,
            addSchedule,
            updateSchedule,
            errorInScheduleEditor,
            emit,
            differencesExist,
            showScheduleEditCancelConfirmation,
            setChangedSchedule,
            close,
            setCreationDifferencesState,
            closeScheduleEditorAfterSave,
            multipleSchedules,
            disabledMessage,
            uuidv4,
        };
    },
});
