import { render, staticRenderFns } from "./SensitiveAnonymisation.vue?vue&type=template&id=341abdd2&"
import script from "./SensitiveAnonymisation.vue?vue&type=script&lang=ts&"
export * from "./SensitiveAnonymisation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports