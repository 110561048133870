

















































































import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { AnonymisationField, BooleanAnonymisationField } from '../../types';
import HandleNullValuesFieldSection from './HandleNullValuesFieldSection.vue';
import PreviewAnonymisationRules from './PreviewAnonymisationRules.vue';
import { MappingFieldConfiguration } from '../../types/typings';
import { useQuasiIdentifierAnonymisation } from '../../composable';
import AnonymisationSection from './AnonymisationSection.vue';
import { isNil } from 'ramda';
import BooleanGroupRule from './rules/BooleanGroupRule.vue';

export default defineComponent({
    name: 'BooleanAnonymisationField',
    model: {
        prop: 'field',
        event: 'changed',
    },
    props: {
        field: {
            type: Object as PropType<BooleanAnonymisationField>,
            required: true,
        },
        inEdit: { type: Boolean, default: false },
        mappingField: {
            type: Object as PropType<MappingFieldConfiguration & { originalTitle: string }>,
            required: true,
        },
        sample: { type: Array, required: true },
    },
    components: { HandleNullValuesFieldSection, PreviewAnonymisationRules, AnonymisationSection, BooleanGroupRule },
    setup(props, { emit }) {
        const selectedField: Ref<BooleanAnonymisationField> = computed({
            get: () => props.field,
            set: (updatedField: AnonymisationField) => {
                emit('changed', updatedField);
            },
        });
        const mappingFieldRef = computed(() => props.mappingField);
        const sampleRef = computed(() => props.sample);
        const { rules, baseRule, getSampleValue } = useQuasiIdentifierAnonymisation(
            selectedField,
            mappingFieldRef,
            sampleRef,
        );

        const getExampleValue = (row: Record<string, any>, level: number): string | null => {
            const value = getSampleValue(row) as string | undefined;
            if (isNil(value)) return null;
            if (level === 0) return value;
            if (selectedField.value.options.show) return '{true, false}';
            return '*';
        };

        return { selectedField, rules, baseRule, getExampleValue };
    },
});
