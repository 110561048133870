









































































import { ConfirmModal, CopyToClipboardButton, FormBlock } from '@/app/components';
import { LargeFilesConnectionDetails } from '@/modules/apollo/types';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import { RefreshIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'S3StorageConnectionDetails',
    components: {
        FormBlock,
        RefreshIcon,
        CopyToClipboardButton,
        ConfirmModal,
    },
    model: {
        prop: 'connectionDetails',
    },
    props: {
        connectionDetails: {
            type: Object as PropType<LargeFilesConnectionDetails>,
            default: () => {},
        },
        resetting: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const showConfirmationModal = ref<boolean>(false);

        return { showConfirmationModal };
    },
});
