
























































































































import { FormBlock, TwAccordion, TwAccordionCard } from '@/app/components';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'S3StorageInstructions',
    components: { FormBlock, TwAccordion, TwAccordionCard },
});
