




















































import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { AnonymisationField, MaskingAnonymisationField } from '../../types';
import HandleNullValuesFieldSection from './HandleNullValuesFieldSection.vue';
import PreviewAnonymisationRules from './PreviewAnonymisationRules.vue';
import { MappingFieldConfiguration } from '../../types/typings';
import { useQuasiIdentifierAnonymisation } from '../../composable';
import { isNil } from 'ramda';
import MaskingRule from './rules/MaskingRule.vue';

export default defineComponent({
    name: 'MaskingAnonymisationField',
    model: {
        prop: 'field',
        event: 'changed',
    },
    props: {
        field: {
            type: Object as PropType<MaskingAnonymisationField>,
            required: true,
        },
        inEdit: { type: Boolean, default: false },
        mappingField: {
            type: Object as PropType<MappingFieldConfiguration & { originalTitle: string }>,
            required: true,
        },
        sample: { type: Array, required: true },
    },
    components: { HandleNullValuesFieldSection, PreviewAnonymisationRules, MaskingRule },
    setup(props, { emit }) {
        const selectedField: Ref<MaskingAnonymisationField> = computed({
            get: () => props.field,
            set: (updatedField: AnonymisationField) => {
                emit('changed', updatedField);
            },
        });
        const mappingFieldRef = computed(() => props.mappingField);
        const sampleRef = computed(() => props.sample);
        const { getSampleValue, maxStringLength, baseRule, rules } = useQuasiIdentifierAnonymisation(
            selectedField,
            mappingFieldRef,
            sampleRef,
        );

        const getExampleValue = (row: Record<string, any>, level: number): string | null => {
            const value = getSampleValue(row) as string | undefined;
            if (isNil(value)) return null;
            if (level === 0) return value;
            const prefix = Array(maxStringLength.value - value.length + 1).join(
                selectedField.value.options.paddingChar,
            );
            const temp = prefix + value;
            const slicedValue = temp.slice(0, -level);
            const suffix = Array(level + 1).join(props.field.options.maskingChar);
            return slicedValue + suffix;
        };

        return { selectedField, rules, baseRule, getExampleValue };
    },
});
