import { MonitoringAPI } from '@/app/api';
import { WorkflowType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { useAxios } from '@/app/composable';
import { ref } from '@vue/composition-api';
import { ExecutionStatus } from '../constants';
import { ExecutionMetrics, ExecutionStreamingMetrics, ExecutionsResponse } from '../types';

export function useExecutions(
    workflowId: string | number,
    workflowType: WorkflowType = WorkflowType.DataCheckin,
    isLongRunning = false,
) {
    const axiosRunner = useAxios(true);

    const query: MonitoringExecutionQuery = {
        query: {
            workflowId: String(workflowId),
            dateRange: {
                start: null,
                end: null,
            },
        },
        facets: {
            status: [],
            workflowType: [],
            failedBlockId: [],
        },
        sortBy: {
            field: '',
            asc: true,
        },
        pagination: {
            index: 0,
            pageSize: 100,
        },
    };

    const currentPage = ref<number>(1);
    const loading = ref<boolean>(false);

    const executionMetrics = ref<ExecutionMetrics | ExecutionStreamingMetrics | null>(null);

    const getMetrics = async () => {
        axiosRunner
            .exec(
                MonitoringAPI.executionMetrics(
                    {
                        ...query,
                        facets: {
                            ...query.facets,
                            //send all statuses as they should not affect the metrics calculation
                            status: Object.values(ExecutionStatus),
                        },
                    },
                    workflowType,
                ),
            )
            .then((res) => {
                executionMetrics.value = res?.data?.results;
            });
    };

    const getStreamingMetrics = async () => {
        axiosRunner.exec(MonitoringAPI.executionStreamingMetrics(workflowId as string, workflowType)).then((res) => {
            executionMetrics.value = res?.data;
        });
    };

    const getExecutionMetrics = () => (isLongRunning ? getStreamingMetrics() : getMetrics());

    const getExecutionsOrExecutionLogs = async (
        monitoringQuery: MonitoringExecutionQuery,
    ): Promise<ExecutionsResponse> => {
        loading.value = true;
        const response = isLongRunning
            ? await axiosRunner.exec(MonitoringAPI.longRunningExecutionLogs(monitoringQuery))
            : await axiosRunner.exec(MonitoringAPI.executions(monitoringQuery, workflowType));
        loading.value = false;
        return response?.data;
    };

    const { exec, error } = axiosRunner;

    return {
        currentPage,
        loading,
        exec,
        error,
        executionMetrics,
        getExecutionsOrExecutionLogs,
        getExecutionMetrics,
    };
}
