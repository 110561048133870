
















































































































































































































































































import { AccessLevelDynamicBadge, CopyToClipboardButton, SvgImage } from '@/app/components';
import { useFeatureFlags, useFilters } from '@/app/composable';
import store from '@/app/store';
import {
    BellIcon,
    ClockIcon,
    DocumentAddIcon,
    DuplicateIcon,
    LinkIcon,
    MinusIcon,
    PencilAltIcon,
    RefreshIcon,
    TrashIcon,
} from '@vue-hero-icons/outline';
import {
    AdjustmentsIcon,
    BanIcon,
    CalendarIcon,
    CheckIcon,
    DotsHorizontalIcon,
    ExclamationIcon,
    LockClosedIcon,
    UserIcon,
    XIcon,
} from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import * as R from 'ramda';
import { TaskExecutionStatus, WorkflowExecutionStatus } from '../constants';
import { IndexedApolloPipeline, IndexedApolloPipelineTask, IndexedHarvesterSourceType } from '../types';

export default defineComponent({
    name: 'DataCheckinPipelineOverview',
    components: {
        UserIcon,
        CalendarIcon,
        TrashIcon,
        PencilAltIcon,
        DocumentAddIcon,
        DuplicateIcon,
        LinkIcon,
        ClockIcon,
        RefreshIcon,
        AdjustmentsIcon,
        CheckIcon,
        DotsHorizontalIcon,
        XIcon,
        ExclamationIcon,
        BanIcon,
        LockClosedIcon,
        SvgImage,
        AccessLevelDynamicBadge,
        CopyToClipboardButton,
        BellIcon,
        MinusIcon,
    },
    props: {
        pipeline: {
            type: Object as PropType<IndexedApolloPipeline>,
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        alert: {
            type: Object as PropType<{ entityId: string | number; alertId: string; enabled: boolean }>,
            required: false,
        },
    },
    setup(props, { emit, root }: { emit: any; root: any }) {
        const { formatDate, formatDateTime } = useFilters();

        const { flag } = useFeatureFlags();
        const isAlertsEnabled = flag('alerts');

        const canRetry = computed(() => props.pipeline.executionStatus === WorkflowExecutionStatus.Cancelled);

        const orderedTasks = computed(() => R.sort(R.ascend<any>(R.prop('order')), props.pipeline.tasks));

        const statusTask: any = computed(() => {
            for (let idx = 0; idx < orderedTasks.value.length; idx += 1)
                if ([TaskExecutionStatus.Draft, TaskExecutionStatus.Updating].includes(orderedTasks.value[idx].status))
                    return orderedTasks.value[idx];

            for (let idx = 0; idx < orderedTasks.value.length; idx += 1)
                if (orderedTasks.value[idx].status !== TaskExecutionStatus.Completed) {
                    return orderedTasks.value[idx];
                }

            return orderedTasks.value[orderedTasks.value.length - 1];
        });

        const statusMessage = computed(() => {
            if (canRetry.value) return WorkflowExecutionStatus.Cancelled;
            if (props.pipeline.executionStatus === WorkflowExecutionStatus.Ready) return 'execution pending';
            if (props.pipeline.executionStatus === WorkflowExecutionStatus.Updating) return 'update';
            if (props.pipeline.executionStatus === WorkflowExecutionStatus.Draft)
                return `configuration:  ${statusTask.value.type}`;
            return props.pipeline.executionStatus;
        });

        const statusClass = computed(() => {
            switch (props.pipeline.executionStatus) {
                case WorkflowExecutionStatus.Updating:
                    return 'text-white bg-purple-500';
                case WorkflowExecutionStatus.Failed:
                    return 'text-white bg-red-600';
                case WorkflowExecutionStatus.Skipped:
                    return 'text-white bg-neutral-600';
                case WorkflowExecutionStatus.Deprecated:
                    return 'text-white bg-cherry-900';
                case WorkflowExecutionStatus.Cancelled:
                    return 'text-white bg-orange-800';
                case WorkflowExecutionStatus.Suspended:
                    return 'text-white bg-red-500';
                case WorkflowExecutionStatus.Completed:
                    return 'text-white bg-green-800';
                case WorkflowExecutionStatus.Queued:
                    return 'text-white bg-slate-500';
                case WorkflowExecutionStatus.Running:
                    return 'text-white bg-slate-700';
                case WorkflowExecutionStatus.Ready:
                    return 'text-white bg-teal-600';
                case WorkflowExecutionStatus.Draft:
                    return 'text-white bg-slateblue-700';
                default:
                    return 'text-white bg-slateblue-700';
            }
        });

        const harvesterSource = computed((): { label: string; colour: string; fileType?: string } | null => {
            let harvesterOption: { label: string; colour: string; fileType?: string } | null = null;
            if (props.pipeline?.harvesterSource) {
                switch (props.pipeline.harvesterSource) {
                    case IndexedHarvesterSourceType.Api:
                        harvesterOption = { label: 'Data Provider API', colour: 'bg-orange-300' };
                        break;
                    case IndexedHarvesterSourceType.InternalApi:
                        harvesterOption = { label: 'Platform API', colour: 'bg-green-300' };
                        break;
                    case IndexedHarvesterSourceType.File:
                        harvesterOption = {
                            label: 'File',
                            colour: 'bg-blue-300',
                        };
                        break;
                    case IndexedHarvesterSourceType.LargeFiles:
                        harvesterOption = {
                            label: 'Large Files',
                            colour: 'bg-slate-200',
                        };
                        break;
                    case IndexedHarvesterSourceType.Kafka:
                        harvesterOption = {
                            label: 'Kafka',
                            colour: 'bg-cherry-300',
                        };
                        break;
                    case IndexedHarvesterSourceType.ExternalKafka:
                        harvesterOption = {
                            label: 'External Kafka',
                            colour: 'bg-cherry-300',
                        };
                        break;
                    case IndexedHarvesterSourceType.MQTT:
                        harvesterOption = {
                            label: 'MQTT',
                            colour: 'bg-olive-300',
                        };
                        break;
                    case IndexedHarvesterSourceType.ExternalMQTT:
                        harvesterOption = {
                            label: 'External MQTT',
                            colour: 'bg-olive-300',
                        };
                        break;
                    case IndexedHarvesterSourceType.BigQuery:
                        harvesterOption = {
                            label: 'BigQuery',
                            colour: 'bg-purple-200',
                        };
                        break;
                    case IndexedHarvesterSourceType.SQL:
                        harvesterOption = {
                            label: 'SQL',
                            colour: 'bg-teal-300',
                        };
                        break;
                    case IndexedHarvesterSourceType.SAPHana:
                        harvesterOption = {
                            label: 'SAP Hana',
                            colour: 'bg-slateblue-300',
                        };
                        break;
                    default:
                        return { label: 'No harvester option', colour: 'bg-red-300' };
                }

                if (
                    props.pipeline.harvesterSource !== IndexedHarvesterSourceType.InternalApi &&
                    props.pipeline.fileType
                ) {
                    harvesterOption['fileType'] = ['parquet', 'other'].includes(props.pipeline.fileType)
                        ? props.pipeline.fileType
                        : props.pipeline.fileType.toUpperCase();
                }
            }
            return harvesterOption;
        });

        const user = computed(() => store.state.auth.user);
        const isUserPipelineCreator = computed(() => user.value.id === Number(props.pipeline?.createdBy?.id));

        const editPipeline = async (pipeline: any) => {
            if (props.pipeline.executionStatus === WorkflowExecutionStatus.Suspended) {
                (root as any).$toastr.w(
                    `This data check-in pipeline is suspended as its output asset (dataset) was deleted. You need to edit the Loader step and configure a new output asset in order to be able to use this pipeline again.`,
                    'Warning',
                );
            } else {
                emit('edit', pipeline);
            }
        };

        const appendData = async (pipeline: any) => {
            root.$router.push({
                name: 'harvester',
                params: { id: pipeline.id, queryParams: props.queryParams },
            });
        };

        const displayAnyWarnings = (task: IndexedApolloPipelineTask): void => {
            if (task.type === 'harvester' && props.pipeline.executionStatus === WorkflowExecutionStatus.Suspended)
                (root as any).$toastr.w(
                    `This data check-in pipeline is suspended as its output asset (dataset) was deleted. You need to edit the Loader step and configure a new output asset in order to be able to use this pipeline again`,
                    'Warning',
                );
        };

        const toTasks = (name: string, pipeline: any) => {
            return {
                name,
                params: { id: pipeline.id, queryParams: props.queryParams },
            };
        };

        const destroyPipeline = async (pipeline: any) => {
            if (!pipeline.canUse) {
                (root as any).$toastr.e('The Data Check-in Pipeline is locked', 'Error');
            } else {
                emit('destroy', pipeline);
            }
        };

        const goToAsset = (assetId: number) => {
            root.$router.push({ name: 'assets:view', params: { id: assetId } });
        };

        const isFinalized = computed(() => {
            if (props.pipeline.executionStatus === WorkflowExecutionStatus.Suspended) return true;
            return props.pipeline.tasks.every(
                (task: any) =>
                    !!task && ![WorkflowExecutionStatus.Draft, WorkflowExecutionStatus.Updating].includes(task.status),
            );
        });

        const accessLevel = computed(() => props.pipeline && props.pipeline?.accessLevel);

        return {
            IndexedHarvesterSourceType,
            dayjs,
            formatDate,
            formatDateTime,
            statusMessage,
            statusClass,
            WorkflowExecutionStatus,
            TaskExecutionStatus,
            orderedTasks,
            statusTask,
            canRetry,
            isUserPipelineCreator,
            editPipeline,
            appendData,
            toTasks,
            destroyPipeline,
            isFinalized,
            accessLevel,
            harvesterSource,
            isAlertsEnabled,
            goToAsset,
            displayAnyWarnings,
        };
    },
});
