
















































































import { MonitoringAPI } from '@/app/api';
import { TailwindTable } from '@/app/components';

import { ColumnType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { ExecutionStatus } from '@/modules/workflow-designer/constants';
import { useAxios } from '@/app/composable';
import { QuestionMarkCircleIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, PropType, ref, Ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import relativeTime from 'dayjs/plugin/relativeTime';
import ExecutionError from './ExecutionError.vue';

dayjs.extend(relativeTime);

export default defineComponent({
    name: 'IncidentsTimeline',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
    },
    components: { TailwindTable, ExecutionError, QuestionMarkCircleIcon },
    setup(props, { emit, root }) {
        const { exec, loading } = useAxios(true);

        const executions: Ref<any> = ref<any>({
            results: [],
            pagination: {},
            accessControl: { rejectedItems: 0 },
            deletedPipelines: 0,
        });

        const fetchExecutions = async (query: MonitoringExecutionQuery) => {
            await exec(
                MonitoringAPI.executions({
                    ...query,
                    facets: {
                        ...query.facets,
                        status: [ExecutionStatus.Failed],
                    },
                }),
            )
                .then((res: any) => {
                    executions.value = res.data;
                    emit('update-show-results-warning', res.data.pagination.total >= 10000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = computed(() => [
            {
                key: 'createdAt',
                label: 'Created At',
                type: ColumnType.Datetime,
                showAfter: 'sm',
                sortable: { enabled: true, key: 'created_at' },
                formatInUtc: true,
            },
            {
                key: ['workflow', 'id'],
                label: 'Pipeline ID',
                type: ColumnType.Uid,
                showAfter: 'sm',
                copy: true,
                link: (value: number, row: any) => {
                    return value
                        ? {
                              name: 'data-checkin-jobs:history',
                              params: { id: `${value}` },
                              query: {
                                  executionId: row.executionId,
                              },
                          }
                        : null;
                },
            },
            {
                key: ['workflow', 'name'],
                label: 'Name',
                type: ColumnType.String,
            },
            {
                key: 'errors',
                label: 'Failure Reason',
                type: ColumnType.Array,
            },
        ]);

        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        const rejectedItems = computed(
            () => executions.value?.accessControl?.rejectedItems ?? 0 + executions.value.deletedPipelines ?? 0,
        );

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || !R.equals(oldQuery, query)) fetchExecutions(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return { executions, loading, columns, changeSort, rejectedItems };
    },
});
