














































































































































































































































































import { useFilters } from '@/app/composable';
import { ChevronRightIcon, ExclamationIcon, XIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { TransformationType } from '../../constants';
import { StatsPerField, TransformationInfo } from '../../types';
import FieldPath from '../FieldPath.vue';
import TransformationDetails from './TransformationDetails.vue';

export default defineComponent({
    name: 'ConceptOverview',
    components: { ExclamationIcon, TransformationDetails, FieldPath, ChevronRightIcon, XIcon },
    props: {
        concept: {
            type: Object,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        successfulTransformations: {
            type: Object as PropType<StatsPerField>,
            required: false,
            default: null,
        },
        failedTransformations: {
            type: Object,
            required: false,
            default: null,
        },
        failedTransformationReasons: {
            type: String,
            required: false,
            default: null,
        },
        message: {
            type: Object,
            required: false,
        },
        validationErrors: {
            type: Object as PropType<
                Record<number, { message: string | null; description?: string | null; type?: string; title?: string }>
            >,
            required: true,
        },
        noTransformations: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const expanded = ref<boolean>(false);

        const { formatLargeNumber } = useFilters();

        const getTransformations = (field: Readonly<any>): Array<TransformationInfo> => {
            const transformations: Array<TransformationInfo> = [];

            if (field.source.type !== field.target.type)
                transformations.push({ type: TransformationType.TypeCast, target: field.target.type });

            if (field.transformation?.sourceDateFormat) {
                const target = field.target.type === 'datetime' ? 'ISO 8601' : 'YYYY-MM-DD';
                if (field.transformation.sourceDateFormat !== target)
                    transformations.push({
                        type: TransformationType.DatetimeFormat,
                        source: field.transformation.sourceDateFormat,
                        target,
                    });
            }

            if (field.transformation?.sourceTimezone && field.transformation.sourceTimezone !== 'UTC')
                transformations.push({
                    type: TransformationType.Timezone,
                    source: field.transformation.sourceTimezone,
                    target: 'UTC',
                });

            if (field.transformation?.measurementType)
                transformations.push({
                    type: TransformationType.Unit,
                    source: field.transformation.sourceUnit,
                    target: field.transformation.targetUnit,
                    measurementType: field.transformation.measurementType,
                });

            if (field.transformation?.thousandsSeperator)
                transformations.push({
                    type: TransformationType.ThousandsSeparator,
                    thousandsSeperator: field.transformation.thousandsSeperator,
                });

            if (field.transformation?.decimalPoint && field.transformation.decimalPoint !== '.')
                transformations.push({
                    type: TransformationType.DecimalPoint,
                    decimalPoint: field.transformation.decimalPoint,
                });

            if (field.transformation?.oneElementArrays) {
                const fields: string[] = [];
                for (const idx of field.transformation.oneElementArrays) {
                    fields.push(field.target.path[idx + 1]);
                }
                transformations.push({ type: TransformationType.NestingLevel, target: fields.join(', ') });
            }

            if (transformations.length === 0) transformations.push({ type: TransformationType.None });

            if (field.annotation)
                transformations.push({ type: TransformationType.Annotation, annotation: field.annotation });

            if (field.alias) transformations.push({ type: TransformationType.Alias, alias: field.alias });

            return transformations;
        };

        const getTransformationText = (transformation: TransformationInfo, hasStats = false) => {
            switch (transformation.type) {
                case TransformationType.None:
                    return `No transformation ${hasStats ? 'was performed' : 'will be performed'} for this field.`;
                case TransformationType.TypeCast:
                    if (transformation.target === 'integer')
                        return `Field values ${hasStats ? 'were' : 'will be'} transformed and truncated to <b>${
                            transformation.target
                        }</b> data type, if required.`;
                    return `Field values ${hasStats ? 'were' : 'will be'} transformed to <b>${
                        transformation.target
                    }</b> data type.`;
                case TransformationType.DatetimeFormat:
                    if (transformation.source === 'infer')
                        return `Field values ${hasStats ? 'were' : 'will be'} transformed to <b>${
                            transformation.target
                        }</b> format.`;
                    return `Field values ${hasStats ? 'were' : 'will be'} transformed from <b>${
                        transformation.source
                    }</b> format to <b>${transformation.target}</b> format.`;
                case TransformationType.DecimalPoint:
                    return `The <b>comma</b> decimal point ${
                        hasStats ? 'was' : 'will be'
                    } replaced by <b>dot</b> in the field values.`;
                case TransformationType.ThousandsSeparator:
                    return `The <b>${
                        transformation.thousandsSeperator === '.' ? 'dot' : 'comma'
                    }</b> thousands separator ${hasStats ? 'was' : 'will be'} <b>removed</b> from the field values.`;
                case TransformationType.Timezone:
                    return `Field values ${hasStats ? 'were' : 'will be'} transformed from <b>${
                        transformation.source
                    }</b> timezone to <b>${transformation.target}</b> timezone, if there ${
                        hasStats ? 'was' : 'is'
                    } no timezone information in the data.`;
                case TransformationType.Unit:
                    if (transformation.source === transformation.target)
                        return `Field values of measurement type <b>${
                            transformation.measurementType
                        }</b> are already in measurement unit <b>${transformation.target}</b> so no transformation ${
                            hasStats ? 'was' : 'will be'
                        } performed.`;
                    return `Field values of measurement type <b>${transformation.measurementType}</b> ${
                        hasStats ? 'were' : 'will be'
                    } transformed from <b>${transformation.source}</b> measurement unit to <b>${
                        transformation.target
                    }</b> measurement unit.`;
                case TransformationType.NestingLevel:
                    return `The structure of the field ${
                        hasStats ? 'was' : 'will be'
                    } adapted to address mapping path inconsistencies, handling any extra arrays under <b>${
                        transformation.target
                    }</b> in the path as one-element arrays.`;
                case TransformationType.Annotation:
                    return `An annotation <b>${transformation.annotation}</b> ${
                        hasStats ? 'was' : 'will be'
                    } added for this field.`;
                case TransformationType.Alias:
                    return `An alias <b>${transformation.alias}</b> ${
                        hasStats ? 'was' : 'will be'
                    } added for this field.`;
                default:
                    return '';
            }
        };

        const transformations = computed(() => {
            return getTransformations(props.concept);
        });

        const failedTransformationReason = computed(() => {
            if (props.message?.stats && props.message?.failedTransformations && props.concept.source.id) {
                let fieldId: any = null;
                Object.entries(props.message.stats).forEach((entry: any) => {
                    const [key, value]: any = entry;
                    if (props.concept.source.id === value.source_id) fieldId = key;
                });
                if (fieldId) return props.message.failedTransformations[fieldId];
            }
            return null;
        });

        const sameSourceTargetType = computed(() => {
            let targetType = props.concept.target.type;
            if (['double', 'integer', 'float'].includes(targetType)) targetType = 'number';
            return targetType === props.concept.source.type;
        });

        const conceptClicked = () => {
            expanded.value = !expanded.value;
        };

        const trimPath = () => {
            props.concept.target.path.splice(-1, 1);
            props.concept.target.parentIds.splice(-1, 1);
        };

        return {
            expanded,
            trimPath,
            conceptClicked,
            transformations,
            getTransformationText,
            failedTransformationReason,
            formatLargeNumber,
            sameSourceTargetType,
        };
    },
});
