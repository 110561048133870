var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-2"},[(_vm.params.length > 0)?_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"},[_c('div',{staticClass:"overflow-hidden border-neutral-200 sm:rounded-lg",class:{ 'border-b': _vm.params.length > 1 }},[_c('table',{staticClass:"min-w-full divide-y divide-neutral-200"},[_vm._m(0),_c('tbody',{staticClass:"bg-white divide-y divide-neutral-200"},_vm._l((_vm.params),function(param,index){return _c('tr',{key:("param-" + (param.key.type) + "-" + (param.key.name) + (_vm.isDuplicateParam(param.key.name, param.key.type) ? ("-" + index) : '') + "-" + (param.value.value)),class:{ 'bg-red-50': _vm.isDuplicateParam(param.key.name, param.key.type) }},[_c('td',{staticClass:"px-6 py-4 whitespace-nowrap"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"p-1 rounded-full bg-neutral-100 text-neutral-700"},[(param.key.type === 'url')?_c('LinkIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('URL Parameter'),expression:"'URL Parameter'"}],staticClass:"w-4 h-4"}):(param.key.type === 'query')?_c('QuestionMarkCircleIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Query Parameter'),expression:"'Query Parameter'"}],staticClass:"w-4 h-4"}):(param.key.type === 'body')?_c('DocumentDuplicateIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Body Parameter'),expression:"'Body Parameter'"}],staticClass:"w-4 h-4"}):_vm._e()],1),_c('div',{staticClass:"w-24 ml-2"},[_c('div',{staticClass:"text-sm font-medium leading-5 text-neutral-900"},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                                        content:
                                                            param.key.name.length >= 10 ? param.key.name : null,
                                                        classes: 'max-w-lg break-all',
                                                    }),expression:"{\n                                                        content:\n                                                            param.key.name.length >= 10 ? param.key.name : null,\n                                                        classes: 'max-w-lg break-all',\n                                                    }"}],staticClass:"truncate"},[_c('span',[_vm._v(_vm._s(param.key.name))])])])]),(_vm.isDuplicateParam(param.key.name, param.key.type))?_c('div',{staticClass:"text-red-700"},[_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Duplicate Parameter'),expression:"'Duplicate Parameter'"}],staticClass:"flex-initial w-5 h-5 mr-2"})],1):_vm._e()])]),_c('td',{staticClass:"w-8 px-6 py-4 whitespace-nowrap"},[(_vm.inVault(param.value.value))?_c('div',{staticClass:"flex text-neutral-700"},[_c('LockClosedIcon',{staticClass:"w-4 h-4 mr-1"}),_c('div',{staticClass:"flex-initial text-sm italic text-neutral-600"},[_vm._v(" This value is stored and secured in Vault. ")])],1):(!_vm.isNil(param.value.value))?_c('div',{staticClass:"w-56 text-sm leading-5 text-neutral-900"},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                                content:
                                                    param.value.category !== 'dynamic' &&
                                                    param.value.value &&
                                                    ("" + (param.value.value)).length >= 15
                                                        ? param.value.category === 'authentication'
                                                            ? ("" + (param.value.value)).slice(1, -1)
                                                            : param.value.value
                                                        : null,
                                                classes: 'max-w-lg break-all',
                                            }),expression:"{\n                                                content:\n                                                    param.value.category !== 'dynamic' &&\n                                                    param.value.value &&\n                                                    `${param.value.value}`.length >= 15\n                                                        ? param.value.category === 'authentication'\n                                                            ? `${param.value.value}`.slice(1, -1)\n                                                            : param.value.value\n                                                        : null,\n                                                classes: 'max-w-lg break-all',\n                                            }"}],staticClass:"truncate"},[_c('span',{class:_vm.valueClasses(param.value.category)},[(
                                                        param.value.category === 'authentication' &&
                                                        param.value.value
                                                    )?_c('span',[_vm._v(_vm._s(param.value.value)+" ")]):(
                                                        param.value.value && param.value.category === 'dynamic'
                                                    )?_c('span',[_vm._v(" "+_vm._s(param.value.value.option)+" "),(param.value.value.value)?[_vm._v(": "+_vm._s(param.value.value.value))]:_vm._e()],2):_c('span',[_vm._v(_vm._s(param.value.value))])])])]):_c('div',{staticClass:"text-sm leading-5 text-red-500"},[_vm._v("Missing value")]),(param.value.format)?_c('div',{staticClass:"text-xs leading-5 text-neutral-500"},[_vm._v(" Format: "+_vm._s(param.value.format)+" ")]):_vm._e()]),_c('td',{staticClass:"px-6 py-4 text-sm leading-5 uppercase text-neutral-500 whitespace-nowrap"},[_vm._v(" "+_vm._s(param.key.type)+" ")]),_c('td',{staticClass:"px-6 py-4"},[(param.sensitive)?_c('div',{staticClass:"flex flex-col items-center text-sm tracking-wider text-neutral-500"},[_vm._v(" YES ")]):_c('div',{staticClass:"flex flex-col items-center text-sm tracking-wider uppercase text-neutral-500"},[_vm._v(" NO ")])]),_c('td',{staticClass:"px-6 py-4 whitespace-nowrap"},[_c('div',{staticClass:"flex items-center space-x-2"},[(!_vm.disabled)?_c('button',{staticClass:"w-6 h-6 pl-1 text-primary-600 hover:text-primary-700",on:{"click":function($event){return _vm.configureParameter(index)}}},[_c('PencilAltIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Edit Parameter'),expression:"'Edit Parameter'"}],staticClass:"w-4 h-4"})],1):_vm._e(),(
                                                !_vm.disabled &&
                                                param.key.type === 'query' &&
                                                param.value.category !== 'pagination'
                                            )?_c('button',{staticClass:"w-6 h-6 text-neutral-600 hover:text-neutral-700",on:{"click":function($event){return _vm.removeParameter(index)}}},[_c('TrashIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove Parameter'),expression:"'Remove Parameter'"}],staticClass:"w-4 h-4 text-neutral-600 hover:text-neutral-700"})],1):_vm._e()])])])}),0)])])])])]):_c('div',{staticClass:"text-sm italic text-neutral-500"},[_vm._v("No request parameters defined")]),_c('div',{staticClass:"flex flex-row-reverse"},[(!_vm.disabled)?_c('button',{staticClass:"inline-flex items-center py-1 pl-2 pr-3 text-xs font-medium leading-5 text-white uppercase transition duration-150 ease-in-out border rounded border-neutral-600 hover:border-neutral-500 bg-neutral-600 hover:bg-neutral-500 focus:outline-none focus:ring-neutral focus:border-neutral-700 active:bg-neutral-700 disabled:bg-neutral-400",class:{ 'mt-2': _vm.params.length > 0 },attrs:{"type":"button"},on:{"click":_vm.addParameter}},[_c('PlusIcon',{staticClass:"w-4 h-4 mr-1"}),_vm._v(" Add Query Parameter ")],1):_vm._e()]),_c('portal',{attrs:{"to":"modals"}},[(_vm.parameterInEdit)?_c('ParameterModal',{attrs:{"parameter":_vm.parameterInEdit.parameter,"method":_vm.method,"loginResponseKeys":_vm.testLoginResponseKeys,"editing":!_vm.isNil(_vm.parameterInEdit.index)},on:{"ok":function($event){return _vm.setParameter($event, _vm.parameterInEdit.index)},"cancel":function($event){_vm.parameterInEdit = undefined}}}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-neutral-600 bg-neutral-50"},[_vm._v(" Parameter ")]),_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-neutral-600 bg-neutral-50"},[_vm._v(" Value ")]),_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-neutral-600 bg-neutral-50"},[_vm._v(" Type ")]),_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center uppercase text-neutral-600 bg-neutral-50"},[_vm._v(" Sensitive ")]),_c('th',{staticClass:"px-6 py-3 bg-neutral-50"})])])}]

export { render, staticRenderFns }