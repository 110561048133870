












































import { PencilAltIcon } from '@vue-hero-icons/outline';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'FakeDataCheckinPipelineOverview',
    components: { PencilAltIcon },
});
