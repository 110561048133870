













































































































import { ModelBrowser, PathBuilder, WaitModal } from '@/app/components';
import { useFilters, useModelBrowser } from '@/app/composable';
import { Concept, Model } from '@/app/interfaces';
import store from '@/app/store';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { useMappingPrediction } from '../../composable';
import { MappingFilter } from '../../constants';
import {
    FieldConfiguration,
    FieldPrediction,
    MappingConfiguration,
    Stats,
    StatsPerField,
    TaskStats,
} from '../../types';
import MappingDetails from './MappingDetails.vue';
import MappingPlayground from './MappingPlayground.vue';

export default defineComponent({
    name: 'MappingConfiguration',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<MappingConfiguration>,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        hasFailed: {
            type: Boolean,
            default: false,
        },
        hasCompleted: {
            type: Boolean,
            default: false,
        },
        stats: {
            type: Object as PropType<TaskStats<Stats<StatsPerField>>>,
            default: null,
        },
        validationErrors: {
            type: Object as PropType<
                Record<number, { message: string | null; description?: string | null; type?: string; title?: string }>
            >,
            required: true,
        },
        basePath: {
            type: Array as PropType<string[] | undefined>,
            default: () => [],
        },
        sample: {
            type: Array,
            required: true,
        },
        restrictingConcept: {
            type: Object as PropType<Concept>,
            default: null,
        },
        loadingRefresh: {
            type: Boolean,
            default: false,
        },
    },
    components: { ModelBrowser, MappingPlayground, MappingDetails, PathBuilder, WaitModal },
    setup(props, { emit, root }) {
        const goToConcept = ref<string[]>([]);
        const modelBrowserModel = ref<Model | null>(null);
        const path = ref<Concept[]>([]);
        const selectedMappings = ref<any[]>([]);
        const activeFilter = ref<MappingFilter>(MappingFilter.All);
        const detailsKey = ref(uuidv4());

        const config = computed(() => props.configuration);
        const basePath = computed(() => props.basePath);

        const { capitalise } = useFilters();
        const { getConceptFromId, getModelFromId, getConceptFromUid } = useModelBrowser();
        const { predict, loading, cancel } = useMappingPrediction(config, basePath);

        const domainName = computed(() =>
            props.configuration.domain ? capitalise(props.configuration.domain.name) : 'None',
        );

        const standardName = computed(() =>
            props.configuration.standard
                ? `${capitalise(props.configuration.standard.standard)} ${props.configuration.standard.version}`
                : 'None',
        );

        const categoryName = computed(() =>
            props.configuration.concept ? capitalise(props.configuration.concept.name) : 'None',
        );

        const noTransformations = computed(() => {
            if (!props.hasFailed || !props.stats?.latestExecutionStats) return false;
            return props.stats.latestExecutionStats.transformedValues === 0;
        });

        const hasStatsPerField = computed(
            () => props.stats?.successfulStats && props.stats?.successfulStats?.statsPerField?.length > 0,
        );

        const selectedConceptId = computed<number | null>(() => {
            const selectedIds = R.uniq(R.map(R.view(R.lensPath(['target', 'parentIds', -1])), selectedMappings.value));
            if (selectedIds.length === 0) return props.configuration?.concept?.id;
            if (selectedIds.length === 1) return selectedIds[0];
            return null;
        });

        const selectedConcept = computed<Concept | null>(() =>
            selectedConceptId.value ? getConceptFromId(selectedConceptId.value) : null,
        );

        const countMultiple = computed(() => {
            if (selectedMappings.value.length === 1) {
                const { id, path: targetPath } = selectedMappings.value[0].target;
                return props.configuration.fields.reduce((count: number, field: any) => {
                    if (field.target.id === id && R.equals(field.target.path, targetPath)) return count + 1;
                    return count;
                }, 0);
            }

            return 0;
        });

        const setModel = (newModel: Model) => {
            modelBrowserModel.value = newModel;
        };

        const setPath = (newPath: Concept[]) => {
            path.value = newPath;
        };

        const setConcept = (field: any, concept: any, prediction: any = null) => {
            emit('set-concept', field, concept, prediction);
            const idx = props.configuration.fields.findIndex(
                (f: FieldConfiguration) => f.source.id === field.source.id,
            );
            if (activeFilter.value !== MappingFilter.Unidentified && !prediction)
                selectedMappings.value = [R.clone(props.configuration.fields[idx])];
            else {
                const index = selectedMappings.value.findIndex(
                    (sm: FieldConfiguration) => props.configuration.fields[idx].source.id === sm.source.id,
                );
                if (index >= 0) selectedMappings.value.splice(index, 1);
            }
        };

        const setCustomizedConcepts = (customizedConcepts: any) => {
            emit('change', { ...props.configuration, customizedConcepts });
        };

        const hasChange = () => {
            emit('changed', selectedMappings.value[0].source.id);
        };

        const filterMapping = (option: MappingFilter) => {
            activeFilter.value = option;
        };

        const updateSelected = (value: any, multiple = false) => {
            const idx = selectedMappings.value.findIndex((obj) => obj.source?.id === value.source?.id);
            if (multiple) {
                if (~idx) selectedMappings.value.splice(idx, 1);
                else selectedMappings.value.push(value);
            } else {
                if (~idx && selectedMappings.value.length === 1) selectedMappings.value.splice(0);
                else selectedMappings.value = [value];
            }
        };

        const performPrediction = async () => {
            const predictionConcept = selectedConceptId.value ? getConceptFromId(selectedConceptId.value) : null;
            const predictionResponse: any = await predict(
                selectedMappings.value,
                predictionConcept && predictionConcept.referenceId
                    ? predictionConcept.referenceId
                    : selectedConceptId.value,
                selectedConceptId.value ? getConceptFromId(selectedConceptId.value)?.domain : undefined,
            );
            const selections = R.clone(selectedMappings.value);
            for (let idx = 0; idx < selections.length; idx++) {
                const field: FieldConfiguration = selections[idx];
                const fieldPrediction: FieldPrediction | null = predictionResponse[field.source.id];

                if (fieldPrediction && fieldPrediction.matchings) {
                    const concept = getConceptFromId(fieldPrediction.matchings.target);
                    const prediction = fieldPrediction.matchings;
                    setConcept(field, concept, prediction);
                    emit('changed', field.source.id);
                }
            }
        };

        const clearMapping = (field: any, full: boolean = true) => {
            emit('clear-mapping', field.source.id, field.target, field.transformation, full);
            const idx = selectedMappings.value.findIndex((obj: any) => obj.source?.id === field.source?.id);
            if (~idx) {
                const clearedField = props.configuration.fields.find((obj: any) => obj.source?.id === field.source?.id);
                if (clearedField) {
                    selectedMappings.value.splice(idx, 1, clearedField);
                    detailsKey.value = uuidv4();
                }
            }
        };

        const setRestrictedConcept = (conceptId?: number) => {
            // set the restricted model and domain in model browser
            // this is need to restrict what can be dragged from the
            // model browser onto the mapping configuration pane
            // it sets the selected concept parent to what is selected
            // or by default to the main concept of the mapping
            const domain: Model | null = props.configuration.domain
                ? getModelFromId(props.configuration.domain.id)
                : null;
            if (domain) {
                store.dispatch.modelBrowser.setMappingSelection({
                    model: domain.uid,
                    concept: conceptId || props.configuration.concept?.id,
                });
            }
        };

        const clearSelection = () => {
            selectedMappings.value.splice(0);
            setRestrictedConcept();
        };

        const clearAllMappings = () => {
            selectedMappings.value.forEach((field: any) => clearMapping(field));
        };

        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') clearSelection();
        };

        const clearPath = () => {
            if (props.restrictingConcept) goToConcept.value = [props.restrictingConcept.uid];
        };

        const conceptClicked = (conceptUid: string) => {
            goToConcept.value = [conceptUid];
        };

        const newValidation = (isValid: boolean) => {
            if (!isValid) activeFilter.value = MappingFilter.Invalid;
            else if (activeFilter.value === MappingFilter.Invalid) activeFilter.value = MappingFilter.All;
            detailsKey.value = uuidv4();
        };

        const updateModelBrowserWithSelectedField = (field: FieldConfiguration | undefined) => {
            // When the selection changes we clear the path of the model browser
            // if a valid field is foudn the we use the path uids to construct the path in the model browser
            const goToPathOfUids = [];
            if (field?.target?.pathUids) {
                for (let p = 0; p < field.target.pathUids.length; p++) {
                    const uid = field.target.pathUids[p];
                    goToPathOfUids.push(uid);
                    const fieldConcept = getConceptFromUid(uid);
                    if (fieldConcept.referenceConceptId)
                        goToPathOfUids.push(getConceptFromId(fieldConcept.referenceConceptId).uid);
                }
            }
            // finally if there is a selected concept then we select that
            if (field && field.target.id) goToPathOfUids.push(getConceptFromId(field.target.id).uid);
            if (goToPathOfUids.length > 0) goToConcept.value = goToPathOfUids;
        };

        watch(
            () => selectedMappings.value,
            (mappings: FieldConfiguration[]) => {
                if (mappings.length > 0) {
                    updateModelBrowserWithSelectedField(mappings[0]);
                    emit('change', {
                        ...props.configuration,
                        fields: props.configuration.fields.map((f: FieldConfiguration) => {
                            for (let m = 0; m < mappings.length; m++)
                                if (f.source.id === mappings[m].source.id) return mappings[m];
                            return f;
                        }),
                    });
                } else if (props.restrictingConcept) goToConcept.value = [props.restrictingConcept.uid];

                setRestrictedConcept(
                    mappings.length > 0 && mappings[0]?.target.parentIds && mappings[0].target.parentIds.length > 0
                        ? mappings[0].target.parentIds[mappings[0].target.parentIds.length - 1]
                        : undefined,
                );
            },
            { deep: true },
        );

        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        return {
            domainName,
            standardName,
            categoryName,
            noTransformations,
            hasStatsPerField,
            goToConcept,
            selectedMappings,
            activeFilter,
            selectedConcept,
            countMultiple,
            modelBrowserModel,
            path,
            loading,
            detailsKey,
            cancel,
            filterMapping,
            setModel,
            setPath,
            setConcept,
            setCustomizedConcepts,
            performPrediction,
            hasChange,
            clearMapping,
            updateSelected,
            clearSelection,
            clearAllMappings,
            clearPath,
            conceptClicked,
            newValidation,
        };
    },
});
