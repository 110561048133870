

























































































































import { ButtonGroup, ConfirmModal, FormBlock } from '@/app/components';
import store from '@/app/store';
import { HarvesterBlockId, KafkaProcessingOptions } from '@/modules/apollo/constants';
import { HarvesterConfiguration } from '@/modules/apollo/types';
import { ScheduleFrequency } from '@/modules/workflow-designer/constants';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import Schedules from './Schedules.vue';

export default defineComponent({
    name: 'ProcessingOptions',
    components: {
        FormBlock,
        ValidationProvider,
        Schedules,
        ButtonGroup,
        ConfirmModal,
    },
    props: {
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
        processingOptions: {
            type: Array,
            default: () => [KafkaProcessingOptions.RealTime, KafkaProcessingOptions.TimeBased],
        },
        configuration: {
            type: Object as PropType<HarvesterConfiguration>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        pipelineId: {
            type: String,
            required: true,
        },
        pipelineCreatedById: {
            type: Number,
        },
        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        inDraftStatus: {
            type: Boolean,
            default: false,
        },
        blockId: {
            type: String as PropType<HarvesterBlockId>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const frequency = ref<ScheduleFrequency | null>(null);
        const newFrequency = ref<ScheduleFrequency | null>(null);
        const loadingSchedules = ref(false);
        const showChangeFrequencyModal = ref(false);
        const newProcessingOption = ref<KafkaProcessingOptions | null>(null);
        const showChangeProcessingOptionModal = ref(false);
        const user = computed(() => store.state.auth.user);

        const updateSchedules = (updatedSchedules: ScheduleType[]) => {
            emit('update-schedules', updatedSchedules);
            if (updatedSchedules.length) {
                if (updatedSchedules.find((schedule: ScheduleType) => schedule.frequency === ScheduleFrequency.Hourly))
                    frequency.value = ScheduleFrequency.Hourly;
                if (updatedSchedules.find((schedule: ScheduleType) => schedule.frequency === ScheduleFrequency.Daily))
                    frequency.value = ScheduleFrequency.Daily;
            } else frequency.value = chosenFrequency.value;
        };

        const schedulesPerFrequency = computed(() => (chosenFrequency.value === ScheduleFrequency.Hourly ? 1 : 12));

        const chosenFrequency = computed(() => {
            if (frequency.value) return frequency.value;
            return ScheduleFrequency.Hourly;
        });

        const changeFrequency = (newFreq: ScheduleFrequency) => {
            frequency.value = newFreq;
            showChangeFrequencyModal.value = false;
            newFrequency.value = null;
        };

        const checkChangeFrequency = (newFreq: ScheduleFrequency) => {
            if (props.schedules.length) {
                showChangeFrequencyModal.value = true;
                newFrequency.value = newFreq;
            } else {
                changeFrequency(newFreq);
            }
        };

        const changeProcessingOption = async (newProcOption: KafkaProcessingOptions) => {
            emit('update-schedules', []);
            emit('update-processing-option', newProcOption);
            showChangeProcessingOptionModal.value = false;
            newProcessingOption.value = null;
        };

        const checkChangeProcessingOption = (event: any) => {
            const newProcOption = event.target.value;
            if (props.schedules.length) {
                showChangeProcessingOptionModal.value = true;
                newProcessingOption.value = newProcOption;
            } else {
                changeProcessingOption(newProcOption);
            }
        };

        return {
            user,
            updateSchedules,
            changeFrequency,
            schedulesPerFrequency,
            showChangeFrequencyModal,
            newFrequency,
            checkChangeFrequency,
            chosenFrequency,
            showChangeProcessingOptionModal,
            newProcessingOption,
            checkChangeProcessingOption,
            changeProcessingOption,
            KafkaProcessingOptions,
            loadingSchedules,
            ScheduleFrequency,
        };
    },
});
