



























































































































































































import { QueryBuilder } from '@/app/components';
import { ExclamationCircleIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { useCleaningConstraints } from '../../../composable/cleaning-constraints';
import { HarvesterBlockId } from '../../../constants';
import { CleaningConfiguration, Condition, Constraint } from '../../../types/cleaning.type';
import SampleValues from '../../SampleValues.vue';
import OutliersRule from '../OutliersRule.vue';
import EditConstraint from './EditConstraint.vue';
import ViewConstraint from './ViewConstraint.vue';

export default defineComponent({
    name: 'EditAdvancedContstraint',
    components: { QueryBuilder, EditConstraint, ViewConstraint, OutliersRule, SampleValues, ExclamationCircleIcon },
    props: {
        configuration: {
            type: Object as PropType<CleaningConfiguration>,
            required: true,
        },
        constraint: {
            type: Object as PropType<Constraint>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        harvesterBlockId: {
            type: String as PropType<HarvesterBlockId>,
            required: true,
        },
        selectedFieldsTypes: {
            type: Array as PropType<string[]>,
            required: true,
        },
        previousProcessedSample: {
            type: Array,
            default: null,
        },
        sample: {
            type: Array,
            required: true,
        },
        multipleOutlierFields: {
            type: Boolean,
            default: false,
        },
        canRunManyTimes: {
            type: Boolean,
            default: false,
        },
        allowDropOrDefaultOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const editingConstraint = ref<boolean>(false);
        const editableConstraint = ref(props.constraint);
        const selectedFields = ref<any[]>([]);
        const isNew = ref(editableConstraint.value.structure?.conditions.length === 0);
        const sameConditionsInGroup = ref<boolean>(false);
        const emptyGroups = ref<boolean>(false);
        const multipleSelectedField = ref<boolean>(false);

        const {
            selectedFieldsTypes: involvedFieldsTypes,
            availableConstraintOptions,
            alreadyDefinedConstraintTypes,
            referencedFields,
        } = useCleaningConstraints(props.configuration, selectedFields);

        const canCreateConstraint = computed(() => {
            if (editingConstraint.value || sameConditionsInGroup.value || emptyGroups.value) return false;
            if (
                !editableConstraint.value.structure?.conditions?.length ||
                !editableConstraint.value?.outliersRule?.type
            )
                return false;
            if (editableConstraint.value.outliersRule.type) {
                if (
                    editableConstraint.value.outliersRule.type === 'DEFAULT_VALUE' &&
                    (editableConstraint.value.outliersRule.replaceValue === null ||
                        editableConstraint.value.outliersRule.replaceValue === '')
                ) {
                    return false;
                }
                return !(
                    editableConstraint.value.outliersRule.type === 'PREVIOUS_VALUE' &&
                    (editableConstraint.value.outliersRule.secondaryRule?.replaceValue === null ||
                        editableConstraint.value.outliersRule.secondaryRule?.replaceValue === '')
                );
            }
            return true;
        });

        const newConditionTemplate = ref({
            fieldName: null,
            multipleOperator: 'none',
            type: null,
            details: null,
        });

        const newGroupTemplate = ref({
            operator: 'AND',
            conditions: [],
        });

        const themeCalculator = (query: any) => {
            switch (query.operator) {
                case 'AND':
                    return 'purple';
                case 'OR':
                    return 'teal';
                default:
                    return null;
            }
        };

        const multipleOperatorOptions = [
            {
                value: 'element',
                title: 'Same element position',
                description:
                    'The values of the specific field that are in the same array position (element) in each record need to satisfy the cleaning rule',
                available: props.multipleOutlierFields,
            },
            {
                value: 'all',
                title: 'All array values',
                description:
                    'All values of the specific field (across all array elements) in each record need to satisfy the cleaning rule',
                available: true,
            },
            {
                value: 'any',
                title: 'Any array value',
                description:
                    'A value of the specific field in any array position (element) in each record should satisfy the cleaning rule',
                available: true,
            },
        ];

        const hasConditions = computed(() => editableConstraint.value.structure?.conditions?.length);

        const fieldChanged = (fieldName: number, query?: any) => {
            if (query) {
                query.details = null;
                query.type = null;
                query.multipleOperator = 'none';
                query.parentFieldName = (props.constraint?.fieldName as string)?.replaceAll('__', '.');
            }
            selectedFields.value = [];
            multipleSelectedField.value = false;
            const foundField = props.configuration.fields.find((f: any) => f.name === fieldName);
            if (foundField) {
                selectedFields.value = [foundField];
                multipleSelectedField.value =
                    !!foundField.multiple || foundField.path.some((pathPart: string) => pathPart.endsWith('[]'));
            }
        };

        const saveConstraint = () => {
            if (isNew.value) {
                emit('save-create');
            } else {
                emit('save-update');
            }
        };

        const checkGroup = (conditions: Condition[]) => {
            const nonAdvancedConditions = conditions
                .filter((c) => c.operator === undefined)
                .map((c) => {
                    const result = R.clone(c);
                    delete result.conditionUid;
                    return result;
                })
                .map((c) => JSON.stringify(c));
            if (nonAdvancedConditions.length !== new Set(nonAdvancedConditions).size) {
                sameConditionsInGroup.value = true;
            }
            conditions.forEach((c) => {
                if (c.conditions !== undefined) {
                    if (c.conditions.length > 0) checkGroup(c.conditions);
                    else emptyGroups.value = true;
                }
            });
        };

        const handleEmptyConditions = (isRoot: boolean) => {
            if (isRoot) emit('delete-constraint');
        };

        const getFieldType = (fieldName: string) => {
            if (!fieldName) return [];
            const field = props.configuration.fields.find((f: any) => f.name === fieldName);
            return field ? [field.type] : [];
        };

        watch(editableConstraint.value, (constraint: Constraint) => {
            if (constraint.structure?.conditions?.length) {
                sameConditionsInGroup.value = false;
                emptyGroups.value = false;
                checkGroup(constraint.structure.conditions);
            }
        });

        return {
            isNew,
            editingConstraint,
            editableConstraint,
            newConditionTemplate,
            newGroupTemplate,
            themeCalculator,
            alreadyDefinedConstraintTypes,
            availableConstraintOptions,
            referencedFields,
            hasConditions,
            canCreateConstraint,
            selectedFields,
            fieldChanged,
            involvedFieldsTypes,
            saveConstraint,
            emptyGroups,
            sameConditionsInGroup,
            handleEmptyConditions,
            getFieldType,
            multipleSelectedField,
            multipleOperatorOptions,
        };
    },
});
