











































import { AlertBanner } from '@/app/components';
import { FileType } from '@/modules/apollo/constants';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { clone, is, isEmpty, isNil } from 'ramda';

export default defineComponent({
    name: 'SampleReview',
    components: {
        AlertBanner,
    },
    props: {
        sample: {
            type: [Array, Object],
            required: true,
        },
        csvSampleLimit: {
            type: Number,
            default: 10,
        },
        fileType: {
            type: String as PropType<FileType>,
            required: true,
        },
    },
    setup(props) {
        const sampleFields = computed(() => {
            if (isNil(props.sample) || isEmpty(props.sample)) return [];
            if (is(Array, props.sample)) return Object.keys(props.sample[0] as any[]);
            return Object.keys(props.sample as any);
        });

        const invalidSampleColumns = computed(() => {
            const invalidColumns: string[] = [];
            if (props.fileType !== FileType.CSV) return [];
            let croppedSample: any | any[] = clone(props.sample);

            if (!is(Array, croppedSample)) {
                croppedSample = [croppedSample];
            }

            croppedSample = croppedSample?.slice(0, props.csvSampleLimit);

            const reversedSample: any = croppedSample?.reduce((acc: any, row: any) => {
                Object.keys(row).forEach((key: string) => {
                    if (!acc[key]) acc[key] = [];
                    acc[key].push(row[key]);
                });
                return acc;
            }, {});

            if (reversedSample)
                Object.keys(reversedSample).forEach((key: string) => {
                    if (reversedSample[key].every((value: any) => value instanceof Date && isNaN(value as any))) {
                        invalidColumns.push(key);
                    }
                });

            return invalidColumns;
        });

        return { sampleFields, invalidSampleColumns };
    },
});
