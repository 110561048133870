























































































































































import { CopyToClipboardButton, FormBlock, JsonParser, TwAccordion, TwAccordionCard } from '@/app/components';
import { AccessTokenScope } from '@/app/constants';
import { AccessTokenModal } from '@/modules/user/views';
import { PropType, defineComponent, ref } from '@vue/composition-api';
import { InternalApiHarvesterConfiguration } from '../../../types';

export default defineComponent({
    name: 'ReviewInternalApiHarvester',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object as PropType<InternalApiHarvesterConfiguration>,
            required: true,
        },
        sample: {
            type: [Object, Array],
            default: null,
        },
        pipelineId: {
            type: String,
            required: true,
        },
    },
    components: { FormBlock, CopyToClipboardButton, JsonParser, TwAccordion, TwAccordionCard, AccessTokenModal },
    setup(props) {
        const placeholder = '<YOUR_EXECUTION_ID>';
        const postURL = `${process.env.VUE_APP_BACKEND_URL}/api/apollo/${props.pipelineId}/harvester/upload-and-run`;
        const monitoringURL = `${process.env.VUE_APP_BACKEND_URL}/api/monitoring/executions/data-checkin/${props.pipelineId}/execution/${placeholder}/status`;

        const scopes = [
            { ...AccessTokenScope.Upload, checked: true, disabled: true },
            { ...AccessTokenScope.Monitor, checked: true, disabled: false },
        ];

        const showAccessTokenModal = ref(false);

        return { placeholder, postURL, monitoringURL, showAccessTokenModal, scopes };
    },
});
