





































































































import { FormBlock, SQLEditor } from '@/app/components';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { SQLRetrievalMethod } from '../../../constants';

export default defineComponent({
    name: 'DataRetrievalDetails',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<{ method: SQLRetrievalMethod; query: string | null; table: string | null }>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        containsModificationTerms: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'SQL database',
        },
    },
    components: { FormBlock, ValidationProvider, SQLEditor },
    setup: (props, { emit }) => {
        const retrievalDetailsConfiguration: Ref<{
            method: SQLRetrievalMethod;
            query: string | null;
            table: string | null;
            databaseSchema?: string | null;
        }> = computed({
            get: () => props.configuration,
            set: (newConfiguration: {}) => {
                emit('change', newConfiguration);
            },
        });

        const methodChanged = (value: SQLRetrievalMethod) => {
            emit('changed');
            if (value === SQLRetrievalMethod.Query) {
                retrievalDetailsConfiguration.value.table = null;
                if (retrievalDetailsConfiguration.value.databaseSchema !== undefined) {
                    retrievalDetailsConfiguration.value.databaseSchema = null;
                }
            } else if (value === SQLRetrievalMethod.Table) retrievalDetailsConfiguration.value.query = null;
        };

        return { retrievalDetailsConfiguration, SQLRetrievalMethod, methodChanged };
    },
});
