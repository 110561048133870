import * as R from 'ramda';
import strftime from 'strftime';

export function getDynamicDate(option: string, date: Date, value: number): any {
    const newDate = R.clone(date);
    value = Number(value);

    switch (option) {
        case 'current_date':
        case 'current_timestamp':
            return newDate;
        case 'minutes_ago':
            return newDate.setMinutes(newDate.getMinutes() - value);
        case 'hours_ago':
            return newDate.setHours(newDate.getHours() - value);
        case 'days_ago':
            return newDate.setDate(newDate.getDate() - value);
        case 'months_ago':
            return newDate.setMonth(newDate.getMonth() - value);
        case 'years_ago':
            return newDate.setFullYear(newDate.getFullYear() - value);
        case 'minutes_later':
            return newDate.setMinutes(newDate.getMinutes() + value);
        case 'hours_later':
            return newDate.setHours(newDate.getHours() + value);
        case 'days_later':
            return newDate.setDate(newDate.getDate() + value);
        case 'months_later':
            return newDate.setMonth(newDate.getMonth() + value);
        case 'years_later':
            return newDate.setFullYear(newDate.getFullYear() + value);
        case 'start_of_the_minute':
            return newDate.setSeconds(0);
        case 'start_of_the_hour':
            return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newDate.getHours(), 0, 0);
        case 'start_of_the_day':
            return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0);
        case 'start_of_the_month':
            return new Date(newDate.getFullYear(), newDate.getMonth(), 1, 0, 0, 0);
        case 'start_of_the_year':
            return new Date(newDate.getFullYear(), 0, 1, 0, 0, 0);
        case 'end_of_the_minute':
            return newDate.setSeconds(59);
        case 'end_of_the_hour':
            return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newDate.getHours(), 59, 59);
        case 'end_of_the_day':
            return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59);
        case 'end_of_the_month':
            return new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0, 23, 59, 59);
        case 'end_of_the_year':
            return new Date(newDate.getFullYear(), 11, 31, 23, 59, 59);
        default:
            return newDate;
    }
}

export function formatDate(format: string | null, date: Date): string {
    switch (format) {
        case 'YYYYMMDD':
            return strftime('%Y%m%d', date);
        case 'YYYY-MM-DD':
            return strftime('%Y-%m-%d', date);
        case 'YYYY.MM.DD':
            return strftime('%Y.%m.%d', date);
        case 'YYYY/MM/DD':
            return strftime('%Y/%m/%d', date);
        case 'DDMMYYYY':
            return strftime('%d%m%Y', date);
        case 'DD-MM-YYYY':
            return strftime('%d-%m-%Y', date);
        case 'DD.MM.YYYY':
            return strftime('%d.%m.%Y', date);
        case 'DD/MM/YYYY':
            return strftime('%d/%m/%Y', date);
        case 'MMDDYYYY':
            return strftime('%m%d%Y', date);
        case 'MM-DD-YYYY':
            return strftime('%m-%d-%Y', date);
        case 'MM.DD.YYYY':
            return strftime('%m.%d.%Y', date);
        case 'MM/DD/YYYY':
            return strftime('%m/%d/%Y', date);
        case 'D-Mon-YYYY':
            return strftime('%-d-%b-%Y', date);
        case 'Month D, YYYY':
            return strftime('%B %-d, %Y', date);
        case 'Mon D, YYYY':
            return strftime('%b %-d, %Y', date);
        case 'ISO 8601':
            return strftime('%Y-%m-%dT%H:%M:%S.000Z', date);
        case 'Unix Timestamp (nanoseconds)': {
            const timezoneOffset = new Date().getTimezoneOffset();
            const utc = new Date(date.getTime() - timezoneOffset * 60 * 1000);
            return parseInt((utc.getTime() * 1000000).toString(), 10).toString();
        }
        case 'Unix Timestamp (microseconds)': {
            const timezoneOffset = new Date().getTimezoneOffset();
            const utc = new Date(date.getTime() - timezoneOffset * 60 * 1000);
            return parseInt((utc.getTime() * 1000).toString(), 10).toString();
        }
        case 'Unix Timestamp (milliseconds)': {
            const timezoneOffset = new Date().getTimezoneOffset();
            const utc = new Date(date.getTime() - timezoneOffset * 60 * 1000);
            return parseInt(utc.getTime().toString(), 10).toString();
        }
        case 'Unix Timestamp':
        case 'Unix Timestamp (seconds)': {
            const timezoneOffset = new Date().getTimezoneOffset();
            const utc = new Date(date.getTime() - timezoneOffset * 60 * 1000);
            return parseInt((utc.getTime() / 1000).toString(), 10).toString();
        }
        case 'YYYYMMDDhhmmss':
            return strftime('%Y%m%d%H%M%S', date);
        case 'YYYY-MM-DD hh:mm:ss':
            return strftime('%Y-%m-%d %H:%M:%S', date);
        case 'YYYY-MM-DD hhmmss':
            return strftime('%Y-%m-%d %H%M%S', date);
        case 'YYYY-MM-DD hh:mm:ss PM/AM':
            return strftime('%Y-%m-%d %H:%M:%S %p', date);
        case 'YYYY-MM-DD hh:mm PM/AM':
            return strftime('%Y-%m-%d %H:%M %p', date);
        case 'YYYY.MM.DD hh:mm:ss':
            return strftime('%Y.%m.%d %H:%M:%S', date);
        case 'YYYY.MM.DD hhmmss':
            return strftime('%Y.%m.%d %H%M%S', date);
        case 'YYYY.MM.DD hh:mm:ss PM/AM':
            return strftime('%Y.%m.%d %H:%M:%S %p', date);
        case 'YYYY.MM.DD hh:mm PM/AM':
            return strftime('%Y.%m.%d %H:%M %p', date);
        case 'YYYY/MM/DD hh:mm:ss':
            return strftime('%Y/%m/%d %H:%M:%S', date);
        case 'YYYY/MM/DD hhmmss':
            return strftime('%Y/%m/%d %H%M%S', date);
        case 'YYYY/MM/DD hh:mm:ss PM/AM':
            return strftime('%Y/%m/%d %H:%M:%S %p', date);
        case 'YYYY/MM/DD hh:mm PM/AM':
            return strftime('%Y/%m/%d %H:%M %p', date);
        case 'YYYYMMDD hh:mm:ss':
            return strftime('%Y%m%d %H:%M:%S', date);
        case 'YYYYMMDD hhmmss':
            return strftime('%Y%m%d %H%M%S', date);
        case 'YYYYMMDD hh:mm:ss PM/AM':
            return strftime('%Y%m%d %H:%M:%S %p', date);
        case 'YYYYMMDD hh:mm PM/AM':
            return strftime('%Y%m%d %H:%M %p', date);
        case 'DD-MM-YYYY hh:mm:ss':
            return strftime('%d-%m-%Y %H:%M:%S', date);
        case 'DD-MM-YYYY hhmmss':
            return strftime('%d-%m-%Y %H%M%S', date);
        case 'DD-MM-YYYY hh:mm:ss PM/AM':
            return strftime('%d-%m-%Y %H:%M:%S %p', date);
        case 'DD-MM-YYYY hh:mm PM/AM':
            return strftime('%d-%m-%Y %H:%M %p', date);
        case 'DD.MM.YYYY hh:mm:ss':
            return strftime('%d.%m.%Y %H:%M:%S', date);
        case 'DD.MM.YYYY hhmmss':
            return strftime('%d.%m.%Y %H%M%S', date);
        case 'DD.MM.YYYY hh:mm:ss PM/AM':
            return strftime('%d.%m.%Y %H:%M:%S %p', date);
        case 'DD.MM.YYYY hh:mm PM/AM':
            return strftime('%d.%m.%Y %H:%M %p', date);
        case 'DD/MM/YYYY hh:mm:ss':
            return strftime('%d/%m/%Y %H:%M:%S', date);
        case 'DD/MM/YYYY hhmmss':
            return strftime('%d/%m/%Y %H%M%S', date);
        case 'DD/MM/YYYY hh:mm:ss PM/AM':
            return strftime('%d/%m/%Y %H:%M:%S %p', date);
        case 'DD/MM/YYYY hh:mm PM/AM':
            return strftime('%d/%m/%Y %H:%M %p', date);
        case 'DDMMYYYY hh:mm:ss':
            return strftime('%d%m%Y %H:%M:%S', date);
        case 'DDMMYYYY hhmmss':
            return strftime('%d%m%Y %H%M%S', date);
        case 'DDMMYYYY hh:mm:ss PM/AM':
            return strftime('%d%m%Y %H:%M:%S %p', date);
        case 'DDMMYYYY hh:mm PM/AM':
            return strftime('%d%m%Y %H:%M %p', date);
        case 'MM-DD-YYYY hh:mm:ss':
            return strftime('%m-%d-%Y %H:%M:%S', date);
        case 'MM-DD-YYYY hhmmss':
            return strftime('%m-%d-%Y %H%M%S', date);
        case 'MM-DD-YYYY hh:mm:ss PM/AM':
            return strftime('%m-%d-%Y %H:%M:%S %p', date);
        case 'MM-DD-YYYY hh:mm PM/AM':
            return strftime('%m-%d-%Y %H:%M %p', date);
        case 'MM.DD.YYYY hh:mm:ss':
            return strftime('%m.%d.%Y %H:%M:%S', date);
        case 'MM.DD.YYYY hhmmss':
            return strftime('%m.%d.%Y %H%M%S', date);
        case 'MM.DD.YYYY hh:mm:ss PM/AM':
            return strftime('%m.%d.%Y %H:%M:%S %p', date);
        case 'MM.DD.YYYY hh:mm PM/AM':
            return strftime('%m.%d.%Y %H:%M %p', date);
        case 'MM/DD/YYYY hh:mm:ss':
            return strftime('%m/%d/%Y %H:%M:%S', date);
        case 'MM/DD/YYYY hhmmss':
            return strftime('%m/%d/%Y %H%M%S', date);
        case 'MM/DD/YYYY hh:mm:ss PM/AM':
            return strftime('%m/%d/%Y %H:%M:%S %p', date);
        case 'MM/DD/YYYY hh:mm PM/AM':
            return strftime('%m/%d/%Y %H:%M %p', date);
        case 'MMDDYYYY hh:mm:ss':
            return strftime('%m%d%Y %H:%M:%S', date);
        case 'MMDDYYYY hhmmss':
            return strftime('%m%d%Y %H%M%S', date);
        case 'MMDDYYYY hh:mm:ss PM/AM':
            return strftime('%m%d%Y %H:%M:%S %p', date);
        case 'MMDDYYYY hh:mm PM/AM':
            return strftime('%m%d%Y %H:%M %p', date);
        case 'D-Mon-YYYY hh:mm:ss':
            return strftime('%-d-%b-%Y %H:%M:%S', date);
        case 'D-Mon-YYYY hhmmss':
            return strftime('%-d-%b-%Y %H%M%S', date);
        case 'D-Mon-YYYY hh:mm:ss PM/AM':
            return strftime('%-d-%b-%Y %H:%M:%S %p', date);
        case 'D-Mon-YYYY hh:mm PM/AM':
            return strftime('%-d-%b-%Y %H:%M %p', date);
        case 'Month D, YYYY hh:mm:ss':
            return strftime('%B %-d, %Y %H:%M:%S', date);
        case 'Month D, YYYY hhmmss':
            return strftime('%B %-d, %Y %H%M%S', date);
        case 'Month D, YYYY hh:mm:ss PM/AM':
            return strftime('%B %-d, %Y %H:%M:%S %p', date);
        case 'Month D, YYYY hh:mm PM/AM':
            return strftime('%B %-d, %Y %H:%M %p', date);
        case 'Mon D, YYYY hh:mm:ss':
            return strftime('%b %-d, %Y %H:%M:%S', date);
        case 'Mon D, YYYY hhmmss':
            return strftime('%b %-d, %Y %H%M%S', date);
        case 'Mon D, YYYY hh:mm:ss PM/AM':
            return strftime('%b %-d, %Y %H:%M:%S %p', date);
        case 'Mon D, YYYY hh:mm PM/AM':
            return strftime('%b %-d, %Y %H:%M %p', date);
        default:
            return '';
    }
}
