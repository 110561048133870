



















import { defineComponent, PropType } from '@vue/composition-api';
import { AccessTokenScope } from '../types/access-token-scope.interface';

export default defineComponent({
    name: 'AccessTokenScope',
    model: {
        prop: 'scope',
        event: 'update',
    },
    props: {
        scope: {
            type: Object as PropType<AccessTokenScope>,
            required: true,
        },
    },
    setup(props, { emit }: { emit: any }) {
        const onChange = (e: any) => {
            emit('update', { ...props.scope, checked: e.target.checked });
        };

        return { onChange };
    },
});
