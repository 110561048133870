






























































































































import { MonitoringAPI } from '@/app/api';
import { ConfirmModal, HtmlModal, ProcessedSampleView, SvgImage, TwButton } from '@/app/components';
import { useAxios, useExecutionErrors, useModelBrowser, useSockets } from '@/app/composable';
import store from '@/app/store';
import { S } from '@/app/utilities';
import { ModelsAPI } from '@/modules/data-model/api';
import { Status } from '@/modules/data-model/constants';
import { ArrowCircleUpIcon, LightningBoltIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import camelcaseKeys from 'camelcase-keys';
import * as R from 'ramda';
import { ApolloAPI } from '../api';
import { ApolloTaskShell, MappingConfiguration as MappingConf, MappingInfo, MappingReview } from '../components';
import { useApolloPipeline, useApolloTask, useMapping, useSampleRun } from '../composable';
import { AlternateNaming, MappingStep, PreprocessingBlockId, TaskExecutionStatus, TaskStatus } from '../constants';
import {
    ApolloTask,
    FieldConfiguration,
    MappingConfiguration,
    MappingStepType,
    MappingTask,
    Stats,
    StatsPerField,
    TaskStats,
    WizardAction,
} from '../types';

export default defineComponent({
    name: 'Mapping',
    metaInfo() {
        return { title: `Mapping${(this as any).task ? ` for: ${(this as any).task.pipeline.name}` : ''}` };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        step: {
            type: String,
            required: false,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: { ApolloTaskShell, ArrowCircleUpIcon, LightningBoltIcon, HtmlModal, TwButton, SvgImage, ConfirmModal },
    setup(props, { root }) {
        const mapping: MappingTask = {
            blockId: PreprocessingBlockId.Mapping,
            steps: [
                { key: MappingStep.Info, name: 'Info', component: MappingInfo, scrollable: true },
                { key: MappingStep.Configuration, name: 'Configuration', component: MappingConf, scrollable: false },
                { key: MappingStep.Review, name: 'Review Rules', component: ProcessedSampleView, scrollable: false },
                { key: MappingStep.Confirm, name: 'Confirm', component: MappingReview, scrollable: false },
            ],
        };
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;

        const task = ref<ApolloTask<MappingConfiguration> | undefined>();
        const currentStep = ref<MappingStep>(MappingStep.Info);
        const mappingComponent = ref<any>(null);
        const originalConfiguration = ref<MappingConfiguration | null>(null);
        const validationTimestamp = ref<Date>(new Date());
        const stats = ref<TaskStats<Stats<StatsPerField>> | null>(null);
        const showResetAliasesModal = ref<boolean>(false);
        const showConfirmModalForClearingMapping = ref<boolean>(false);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const loadingFinalization = ref<boolean>(false);
        const loadingRefresh = ref<boolean>(true);
        const resettingMapping = ref<boolean>(false);
        const lazyFieldChanged = ref<boolean>(false);
        const upgradingMapping = ref<boolean>(false);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'validate',
                label: 'Validate',
                show:
                    !isFinalized.value && currentStep.value === MappingStep.Configuration && mappedConceptsExist.value,
                enabled: true,
                color: 'neutral',
                icon: LightningBoltIcon,
            },
            {
                key: 'sample-run',
                show:
                    !isFinalized.value &&
                    currentStep.value === MappingStep.Configuration &&
                    !task.value?.processedSample?.length &&
                    canRunOrViewSampleRun.value,
                enabled: !pageLoading.value && mappedConceptsExist.value,
            },
            {
                key: 'view-processed-sample',
                show:
                    !isFinalized.value &&
                    currentStep.value === MappingStep.Configuration &&
                    !!task.value?.processedSample?.length &&
                    canRunOrViewSampleRun.value,
                enabled: true,
            },
            {
                key: 'save',
                show: currentStep.value === MappingStep.Configuration && !isFinalized.value,
                enabled: hasChanges.value,
                showCancel: currentStep.value === MappingStep.Configuration && !isFinalized.value && hasChanges.value,
            },
            {
                key: 'revise',
                show: canRevise.value,
                enabled: true,
            },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: canFinalize.value,
            },
        ]);

        const pageLoading = computed(() => {
            const modelBrowserLoading = domainLoading.value || modelsLoading.value || conceptsLoading.value;
            const inConfigurationStep = !isFinalized.value && currentStep.value === MappingStep.Configuration;
            return (
                loadingFinalization.value ||
                loadingRefresh.value ||
                loading.value ||
                (inConfigurationStep && modelBrowserLoading) ||
                resettingMapping.value
            );
        });

        const currentStepInfo = computed(
            () => mapping.steps.find((step: MappingStepType) => step.key === currentStep.value) as MappingStepType,
        );

        const { errorMessage } = useExecutionErrors();

        const updateProcessedSample = async (sampleData: any, sampleStats: any = null) => {
            if (!task.value) return;
            task.value.processedSample = sampleData ?? [];
            if (sampleData) next();
            else {
                await save(false);
                if (!sampleStats) return;
                // execution sends message in snake case so we need to transform it to camel case
                const statsCorrected = camelcaseKeys(sampleStats, { deep: true });
                updateExecutionValidationErrors(statsCorrected);
            }
        };

        const updateExecutionValidationErrors = (executionStats?: Stats<StatsPerField>) => {
            if (!isUnderRevise.value || !hasChangesAfterRevise.value)
                executionStats?.statsPerField
                    .filter((s: StatsPerField) => !R.isNil(s.errorCode))
                    .forEach((s: StatsPerField) => {
                        const mappedField = task.value?.configuration.fields.find(
                            (field: FieldConfiguration) => field.source.id === s.id,
                        );
                        const originalMappedField = originalConfiguration.value?.fields.find(
                            (field: FieldConfiguration) => field.source.id === s.id,
                        );
                        if (!mappedField || !originalMappedField) return;
                        const executionError = errorMessage(s.errorCode as number).error;

                        // use root set to add key so it can be properly reacted
                        root.$set(validationErrors.value, s.id, {
                            message: 'Failed on Sample',
                            title: executionError.title,
                            description: executionError.description,
                            type: 'sample',
                        });
                        mappedField.temp.invalid = true;
                        mappedField.temp.errorCode = s.errorCode as number;
                        // mark both as invalid so no changes are identified
                        originalMappedField.temp.invalid = true;
                        originalMappedField.temp.errorCode = s.errorCode as number;
                    });
            else {
                task.value?.configuration.fields
                    .filter((field) => field.temp.invalid && !!field.temp.errorCode)
                    .forEach((field) => {
                        const executionError = errorMessage(field.temp.errorCode).error;

                        // use root set to add key so it can be properly reacted
                        root.$set(validationErrors.value, field.source.id, {
                            message: 'Failed on Sample',
                            title: executionError.title,
                            description: executionError.description,
                            type: 'sample',
                        });
                    });
            }
        };

        const { loading, error, exec } = useAxios(true);
        const {
            validate,
            sample,
            basePath,
            validationErrors,
            refreshMapping,
            resetMapping,
            setConcept,
            migrate,
            initEmptyField,
            getValidConfiguration,
        } = useMapping(task);

        const {
            isFinalized,
            hasFailed,
            hasCompleted,
            canRevise,
            shouldUpdateAssetsAfterRevise,
            updateAssetsAfterRevise,
            inUpdateStatus,
            inDraftStatus,
        } = useApolloTask(task);
        const { fetchPipeline, isUnderRevise } = useApolloPipeline(props.id, undefined, false);
        const {
            models,
            model,
            concepts,
            domainLoading,
            modelsLoading,
            conceptsLoading,
            restrictingConcept,
            refreshModels,
            resetModelBrowser,
            getConceptFromId,
            getModelFromId,
            fetchedConceptsFrom,
        } = useModelBrowser();
        const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();
        const { loadingSampleRun, executeSampleRun, onMessage } = useSampleRun(task, root, updateProcessedSample);

        // Detects if we need to inform the user to do a mapping upgrade
        const mappingNeedsUpgrade = computed(() => {
            if (!task.value || !task.value.configuration || !task.value.configuration.domain || isFinalized.value)
                return false;

            if (task.value?.configuration.domain?.id && !isFinalized.value)
                for (let i = 0; i < models.value.length; i++)
                    if (models.value[i].id === task.value.configuration.domain.id) return false;

            // if no matching domain is found in the iteration above an upgrade is needed
            return true;
        });

        const noValidationErrors = computed(
            () =>
                R.isEmpty(validationErrors.value) ||
                Object.keys(validationErrors.value).every((err: any) => R.isNil(err.message)),
        );

        const canRunOrViewSampleRun = computed(
            () => !inUpdateStatus.value || hasChangesAfterRevise.value || noValidationErrors.value,
        );

        const allowNext = computed(
            () =>
                !!task.value?.configuration?.domain &&
                !!task.value?.configuration?.concept &&
                !pageLoading.value &&
                (currentStep.value !== MappingStep.Configuration || canRunOrViewSampleRun.value),
        );

        const isValid = computed<boolean>(
            () => task.value?.configuration.fields.filter((obj: any) => obj.temp && obj.temp.invalid).length === 0,
        );

        const canFinalize = computed(
            () =>
                currentStep.value === MappingStep.Confirm &&
                !!task.value &&
                task.value.configuration.fields.length > 0 &&
                isValid.value,
        );

        const hasChanges = computed(
            () =>
                lazyFieldChanged.value ||
                JSON.stringify(originalConfiguration.value) !== JSON.stringify(task.value?.configuration),
        );

        const hasChangesAfterRevise = computed(
            () => !!task.value?.configuration.hasChangesAfterRevise || hasChanges.value,
        );

        const modelReady = computed(
            () =>
                !modelsLoading.value &&
                !domainLoading.value &&
                model.value &&
                task.value?.configuration?.domain?.uid &&
                model.value.uid === task.value.configuration.domain.uid,
        );

        const mappedConceptsExist = computed(() => {
            const unmappedConcepts = task.value?.configuration.fields.filter(
                (obj: any) => !('target' in obj && 'id' in obj.target && obj.target.id),
            );
            return unmappedConcepts?.length !== task.value?.configuration.fields.length;
        });

        const updateTask = (clearProcessedSample = true): Promise<void> => {
            return new Promise((resolve, reject) => {
                if (!task.value) return;
                if (inUpdateStatus.value) task.value.configuration.hasChangesAfterRevise = hasChanges.value;
                exec(ApolloAPI.updateTask(props.id, 'mapping', task.value.configuration, clearProcessedSample))
                    .then((res: any) => {
                        task.value = res?.data;
                        if (task.value) originalConfiguration.value = R.clone(task.value.configuration);
                        resolve();
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        };

        const checkForClearingMapping = () => {
            if (!originalConfiguration.value || !task.value || !task.value.configuration) return false;
            if (!originalConfiguration.value.domain || !task.value.configuration.domain) return false;
            if (originalConfiguration.value.domain.id !== task.value.configuration.domain.id) return true;
            if (
                JSON.stringify(originalConfiguration.value.standard) !==
                JSON.stringify(task.value.configuration.standard)
            )
                return true;
            if (originalConfiguration.value.concept?.id !== task.value.configuration.concept?.id) return true;
            return false;
        };

        const checkForResetAliases = () => {
            // If previous alternate naming was alias, then confirm reset aliases
            if (!originalConfiguration.value || !task.value || !task.value.configuration) return false;
            if (!originalConfiguration.value.alternateNaming || !task.value.configuration.alternateNaming) return false;
            if (
                originalConfiguration.value.alternateNaming === AlternateNaming.Alias &&
                task.value.configuration.alternateNaming !== AlternateNaming.Alias
            )
                return true;
            return false;
        };

        const getValidStep = (step: MappingStep) => {
            if (step > MappingStep.Info && (!task.value?.configuration?.domain || !task.value?.configuration?.concept))
                return MappingStep.Info;
            if (step > MappingStep.Configuration) {
                if (!mappedConceptsExist.value) return MappingStep.Configuration;
                if (task.value?.processedSample && task.value.processedSample.length === 0)
                    return MappingStep.Configuration;
                const valid = validate();
                if (!valid) return MappingStep.Configuration;
            }
            return step;
        };

        const previous = () => {
            if (mappingComponent.value && currentStep.value === MappingStep.Configuration)
                mappingComponent.value.clearSelection();
            currentStep.value -= 1;
            updateCurrentStep();
        };

        const next = async () => {
            if (currentStep.value === MappingStep.Info) {
                if (checkForClearingMapping()) {
                    showConfirmModalForClearingMapping.value = true;
                    return;
                }

                if (checkForResetAliases()) {
                    showResetAliasesModal.value = true;
                    return;
                }

                if (task.value?.status === TaskStatus.Updating) await refreshMapping();
            }

            if (task.value?.configuration.fields.length === 0) await resetMapping();

            if (currentStep.value !== MappingStep.Info && !mappedConceptsExist.value) {
                (root as any).$toastr.e('At least one field must be mapped', 'Failed');
                return;
            }

            if (currentStep.value === MappingStep.Configuration) {
                if (mappingComponent.value) mappingComponent.value.clearSelection();

                const valid = validate();
                if (!valid) {
                    (root as any).$toastr.e(
                        'Please address the validation errors before proceeding to the next step',
                        'Validation Failed',
                    );
                    return;
                }

                // execute sample run before proceeding to the Review Rules tab
                if (task.value?.processedSample && task.value.processedSample.length === 0) {
                    runOnSample();
                    return;
                }
            }

            if (currentStep.value === MappingStep.Info) {
                const saved = await save(false);
                if (!saved) return;
            }

            currentStep.value += 1;
            updateCurrentStep();
        };

        const changeStep = async (step: MappingStep) => {
            if (step < currentStep.value) previous();
            else if (step > currentStep.value) next();
        };

        const runValidation = () => {
            const valid = validate();
            if (valid) (root as any).$toastr.i('Mapping configuration is valid', 'Success');
            validationTimestamp.value = new Date();
            if (mappingComponent.value) mappingComponent.value.newValidation(isValid.value);
        };

        const save = async (notify: boolean = true): Promise<boolean> => {
            if (!task.value) return false;
            try {
                await updateTask(task.value.processedSample?.length === 0 ? true : false);
                await exec(ApolloAPI.lock(props.id));
                lazyFieldChanged.value = false;
                if (notify) (root as any).$toastr.s('Mapping configuration saved successfully', 'Success');
                return true;
            } catch (e) {
                (root as any).$toastr.e('Saving mapping configuration failed', 'Failed');
                return false;
            }
        };

        const reviseTask = async () => {
            if (!task.value) return;
            try {
                await exec(ApolloAPI.reviseTask(props.id, 'mapping')).then(() => {
                    if (task.value) {
                        task.value.status = TaskStatus.Updating;
                        task.value.executionStatus = TaskExecutionStatus.Updating;
                        task.value.configuration.hasChangesAfterRevise = false;
                        task.value.processedSample = [];
                    }
                });
                currentStep.value = MappingStep.Configuration;
                updateCurrentStep();
                (root as any).$toastr.s(
                    'The configuration of the mapping step is now available for updates.',
                    'Success',
                );
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the mapping step failed', 'Failed');
            }
        };

        const finalize = async () => {
            validate();

            if (!isValid.value || !task.value) return;

            loadingFinalization.value = true;

            if (shouldUpdateAssetsAfterRevise()) {
                await updateAssetsAfterRevise();
                restartedStep.value = true;
            }
            await exec(ApolloAPI.finalizeTask(props.id, 'mapping'));
            showFinalizeModal.value = true;

            loadingFinalization.value = false;
        };

        const runOnSample = async () => {
            const valid = validate();

            if (valid) {
                const saved = await save(false);
                if (saved) executeSampleRun();
            } else
                (root as any).$toastr.e(
                    'Please address the validation errors before performing a Sample Run',
                    'Validation Failed',
                );
        };

        /**
         * Remove any stats/ failed transformations related to the mapping the user has just modified or removed
         */
        const removeInvalidTransformation = (sourceId: any) => {
            if (stats.value?.latestExecutionStats?.statsPerField?.length) {
                stats.value.latestExecutionStats.statsPerField = stats.value?.latestExecutionStats?.statsPerField?.filter(
                    (s) => s.id !== sourceId,
                );
            }
        };

        /**
         * Deletes the 'modified' property from the previous/ current mapping configurations
         * in order to be able to properly compare them to identify any changes
         */
        const removePropertyFromMapping = (fieldId: any, config: any) => {
            // remove the 'modified' property from the already existing mapping
            const mappingExists: any = config.fields.filter((field: any) => field.source.id === fieldId);

            const clonedMapping = R.clone(mappingExists[0]);
            if (clonedMapping && 'modified' in clonedMapping.temp) {
                delete clonedMapping.temp.modified;
            }

            return clonedMapping;
        };

        const revisedMapping = (fieldId: any) => {
            if (!task.value) return;
            task.value.processedSample = [];
            // remove any errors after sample run
            if (validationErrors.value[fieldId]?.type === 'sample') {
                validationErrors.value[fieldId] = { message: null, description: null };
                const mappedField = task.value?.configuration.fields.find((field: any) => field.source.id === fieldId);
                if (mappedField) mappedField.temp.invalid = false;
            }
            if (fieldId && task.value && task.value.status === TaskStatus.Updating) {
                // remove the 'modified' property from the already existing mapping
                const clonedMappingAlreadyExists = removePropertyFromMapping(fieldId, task.value.configuration);
                // remove the 'modified' property from the new mapping
                const clonedNewlyAddedMapping = removePropertyFromMapping(fieldId, task.value.configuration);

                const hasDifference =
                    JSON.stringify(clonedNewlyAddedMapping) !== JSON.stringify(clonedMappingAlreadyExists);

                /**
                 * - Add the 'modified' property only if this mapping already existed and was saved and there is a change i.e. in the mapping details
                 * - If the user changes the field and then changes it back to the old value, then it will stay as 'modified'
                 */
                if (
                    clonedMappingAlreadyExists &&
                    clonedMappingAlreadyExists.target.id &&
                    clonedMappingAlreadyExists.target.id === clonedNewlyAddedMapping.target.id &&
                    hasDifference
                ) {
                    const idx: any = task.value.configuration.fields.findIndex(
                        (field: any) => field.source.id === fieldId,
                    );

                    if (idx >= 0 && task.value.configuration.fields[idx].temp) {
                        task.value.configuration.fields[idx].temp.modified = true;
                    }
                    removeInvalidTransformation(fieldId);
                }
            }
        };

        const conceptChanged = (field: any, concept: any, prediction: any) => {
            setConcept(field, concept, prediction);
            if (currentStep.value === MappingStep.Configuration) revisedMapping(field.source.id);
        };

        const showWarningAboutDeprecatedFields = (deprecatedNames: string[]) => {
            if (deprecatedNames.length > 0) {
                const many = deprecatedNames.length > 1;
                (root as any).$toastr.w(
                    `In the latest version of the model you are using, field${many ? 's' : ''} '${S.sanitizeHtml(
                        many ? deprecatedNames.join(', ') : deprecatedNames[0],
                    )}' ${many ? 'have' : 'has'} been deprecated`,
                    'Warning',
                );
            }
        };

        const upgradeMapping = async () => {
            if (!task.value) return;
            upgradingMapping.value = true;
            try {
                await refreshModels();
                if (task.value.configuration.concept && task.value.configuration.domain) {
                    const concept = task.value.configuration.concept;
                    const domain = task.value.configuration.domain;
                    const fields = task.value.configuration.fields;

                    // retrieves a map where the key is the old id and the value is the new concept
                    const idMappings = await ModelsAPI.domainLatestMapping(task.value.configuration.domain.id).then(
                        (res: any) => {
                            return res.data;
                        },
                    );

                    // concept or domain is deprecated
                    const conceptDeprecated = idMappings[concept.id].status === Status.Deprecated;
                    const domainDeprecated = idMappings[domain.id].status === Status.Deprecated;
                    const deprecated = conceptDeprecated || domainDeprecated;

                    // get upgraded mapping
                    const upgradedMapping = migrate(domain, concept, fields, idMappings, deprecated);
                    task.value.configuration.fields = upgradedMapping.fields;
                    task.value.configuration.domain = upgradedMapping.domain;
                    task.value.configuration.concept = upgradedMapping.concept;

                    // mapping has changed, clear processed sample
                    task.value.processedSample = [];

                    validate();

                    if (deprecated) {
                        if (domainDeprecated)
                            (root as any).$toastr.w(
                                `Mapping reset because domain '${S.sanitizeHtml(domain.name)}' is deprecated`,
                                'Warning',
                            );
                        else if (conceptDeprecated)
                            (root as any).$toastr.w(
                                `Mapping reset because concept '${S.sanitizeHtml(
                                    concept.name,
                                )}' is deprecated in the latest version of the model`,
                                'Warning',
                            );
                        previous();
                    } else {
                        (root as any).$toastr.s(
                            'Mapping upgraded to the latest version of the model successfully',
                            'Success',
                        );
                        showWarningAboutDeprecatedFields(upgradedMapping.deprecatedFields);
                    }
                    resetModelBrowser();
                } else {
                    throw new Error('Concept not defined!');
                }
            } catch (e) {
                (root as any).$toastr.e('Upgrading mapping to the latest version of the model failed', 'Failed');
            } finally {
                upgradingMapping.value = false;
            }
        };

        const deprecateFields = async (deprecatedConceptIds: number[]) => {
            if (!task.value) return;
            task.value.processedSample = [];
            const deprecatedNames: string[] = [];
            for (let f = 0; f < task.value.configuration.fields.length; f++) {
                const field = task.value.configuration.fields[f];
                if (field.target.id && field.target.title && deprecatedConceptIds.includes(field.target.id)) {
                    deprecatedNames.push(field.target.title);
                    task.value.configuration.fields[f] = initEmptyField(field.source);
                }
            }
            showWarningAboutDeprecatedFields(deprecatedNames);

            await updateTask();
        };

        const clearMappingAndProceed = async () => {
            if (!task.value) return;
            resettingMapping.value = true;
            task.value.processedSample = [];
            await resetMapping();
            await updateTask()
                .then(() => {
                    showConfirmModalForClearingMapping.value = false;
                    next();
                })
                .catch(() => {
                    (root as any).$toastr.e('Saving mapping configuration automatically failed', 'Failed');
                })
                .finally(() => {
                    resettingMapping.value = false;
                });
        };

        const clearAliasesAndProceed = async () => {
            if (!task.value) return;
            task.value.configuration.fields.forEach((field: any) => {
                field.alias = null;
            });
            await updateTask().then(() => {
                showResetAliasesModal.value = false;
                next();
            });
        };

        const discardChangesAndProceed = () => {
            if (!task.value) return;
            if (originalConfiguration.value) task.value.configuration = R.clone(originalConfiguration.value);
            showConfirmModalForClearingMapping.value = false;
            showResetAliasesModal.value = false;
            next();
        };

        // Remove 'modified' property and invalid transformation (if exists) of a removed mapping
        const clearMapping = (sourceId: any, target: any, transformation: any, full: boolean = true) => {
            if (!task.value) return;

            task.value.configuration.fields = task.value.configuration.fields.map(
                (field: { source: any; target: any; transformation?: any; temp: any }) => {
                    if (field.source.id === sourceId) {
                        if (full) return initEmptyField(field.source);
                        else
                            return {
                                ...field,
                                target: { ...field.target, id: null, title: null, type: null },
                                temp: { ...field.temp, invalid: false },
                            };
                    }
                    // If other concepts with the same target id and path exist then reset their order
                    else {
                        if (target?.id === field.target.id && R.equals(target?.path, field.target.path))
                            if (field.transformation?.order) {
                                // If order is greater than concepts count and is not 1 then decrease it
                                if (
                                    field.transformation?.order !== 1 &&
                                    field.transformation?.order > transformation.order
                                )
                                    field.transformation.order--;
                            }
                    }
                    return field;
                },
            );
            if (task.value.status === TaskStatus.Updating) {
                const fieldMapping: any = task.value.configuration.fields.find(
                    (field: any) => field.source.id === sourceId,
                );
                if (fieldMapping && 'modified' in fieldMapping.temp) {
                    delete fieldMapping.temp.modified;
                }
                removeInvalidTransformation(sourceId);
            } else {
                revisedMapping(sourceId);
            }
        };

        const updateCurrentStep = () => {
            if (currentStepInfo.value) {
                const step = currentStepInfo.value.name.split(' ')[0].toLowerCase();
                root.$router.replace({
                    name: 'mapping',
                    params: { id: props.id, step, queryParams: props.queryParams },
                });
            }
        };

        const confirmedModal = async () => {
            if (showResetAliasesModal.value) await clearAliasesAndProceed();
            else if (showConfirmModalForClearingMapping.value) await clearMappingAndProceed();
        };

        // If changes detected, clear current processed sample so to execute new sample run
        watch(
            () => hasChanges.value,
            async (changes: boolean) => {
                if (changes && currentStep.value === MappingStep.Configuration && task.value) {
                    const isAnnotationChange =
                        JSON.stringify(
                            originalConfiguration.value?.fields.map((f: any) => R.omit(['annotation', 'alias'], f)),
                        ) ===
                        JSON.stringify(
                            task.value.configuration.fields.map((f: any) => R.omit(['annotation', 'alias'], f)),
                        );
                    if (!isAnnotationChange) task.value.processedSample = [];
                }
            },
        );

        watch(
            () => currentStep.value,
            (value: any) => {
                if (value === MappingStep.Configuration && task.value) {
                    if (task.value.configuration.domain)
                        store.dispatch.modelBrowser.setModel({
                            model: getModelFromId(task.value.configuration.domain.id)?.uid,
                        });
                    if (task.value.configuration.concept)
                        store.dispatch.modelBrowser.setRestrictingConcept({
                            concept: task.value.configuration.concept.id,
                        });
                }
            },
        );

        watch(
            () => concepts.value,
            async (modelConcepts: any) => {
                // only if a high level concept is already selected and it's the same as the one already existed in the mapping config.
                if (
                    currentStep.value === MappingStep.Configuration &&
                    modelConcepts.length &&
                    task.value &&
                    restrictingConcept.value?.id === task.value.configuration?.concept?.id
                ) {
                    const deprecatedFields = [];
                    for (let f = 0; f < task.value.configuration.fields.length; f++) {
                        const field = task.value.configuration.fields[f];
                        if (!R.isNil(field.target.id) && !getConceptFromId(field.target.id))
                            deprecatedFields.push(field.target.id);
                    }
                    if (deprecatedFields.length) deprecateFields(deprecatedFields);
                }
            },
        );

        watch(
            () => [task.value, modelReady.value],
            async () => {
                if (task.value?.status === TaskStatus.Updating && loadingRefresh.value && !mappingNeedsUpgrade.value) {
                    if (modelReady.value) {
                        await refreshMapping();
                        loadingRefresh.value = false;
                    }
                } else {
                    loadingRefresh.value = false;
                }
            },
        );

        watch(
            () => [task.value?.configuration, concepts.value, modelReady.value],
            async () => {
                if (!modelReady.value) return;
                // do nothing in case mapping has not been retrieved yet
                if (!task.value || !task.value.configuration) return;
                // do nothing if step is finalised
                if (isFinalized.value) return;
                // do nothing if not in actual mapping configuration
                if (currentStep.value !== MappingStep.Configuration) return;
                // do nothing if concepts currently loaded from model browser is not the selected for this mapping (are fetched asynchrously later on)
                if (mappingNeedsUpgrade.value) return;
                // restricting concept is not defined yet
                if (restrictingConcept.value?.id !== task.value.configuration.concept?.id) return;

                const validConfiguration = getValidConfiguration(task.value.configuration);

                // if current configuration is not valid and there is no pending upgrade
                // then fix up configuration based on valid one
                if (JSON.stringify(validConfiguration) !== JSON.stringify(task.value.configuration)) {
                    if (task.value?.configuration?.concept)
                        (root as any).$toastr.w(
                            'Some mapped fields seem to be using concepts that are no longer available. These mappings are now removed from your mapping. Please save to apply the change!',
                            'Warning',
                        );
                    task.value.configuration = validConfiguration;
                    await updateProcessedSample(null);
                }
            },
        );

        fetchPipeline().then(() =>
            exec(ApolloAPI.getTask(props.id, 'mapping')).then((res: any) => {
                task.value = res?.data;
                if (!task.value) return;

                originalConfiguration.value = R.clone(task.value.configuration);

                if (!inDraftStatus.value) {
                    if (!inUpdateStatus.value && root.$route.params.step) root.$router.push({ name: '404' });
                    else {
                        if (!inUpdateStatus.value || isUnderRevise.value) {
                            exec(MonitoringAPI.taskStats(props.id, task.value.id)).then((resStats: any) => {
                                stats.value = resStats?.data;
                                updateExecutionValidationErrors(stats.value?.latestExecutionStats);
                            });
                        }
                        currentStep.value = MappingStep.Configuration;
                    }
                }
                if (inDraftStatus.value || inUpdateStatus.value) {
                    if (root.$route.params.step) {
                        const foundStep = mapping.steps.find(
                            (step: MappingStepType) =>
                                step.name.split(' ')[0].toLowerCase() === root.$route.params.step,
                        );
                        if (foundStep) {
                            currentStep.value = getValidStep(foundStep.key);
                            if (currentStep.value !== foundStep.key) updateCurrentStep();
                        } else root.$router.push({ name: '404' });
                    } else {
                        if (
                            task.value?.configuration &&
                            task.value.configuration.domain &&
                            task.value.configuration.concept
                        )
                            currentStep.value = MappingStep.Configuration;
                        updateCurrentStep();
                    }
                }
            }),
        );

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, props.id);
        });

        onMounted(async () => {
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        });

        onUnmounted(async () => {
            await exec(ApolloAPI.unlock(props.id));
        });

        return {
            MappingStep,
            currentStep,
            task,
            mapping,
            error,
            allowNext,
            wizardActions,
            mappedConceptsExist,
            isMacOS,
            showResetAliasesModal,
            showConfirmModalForClearingMapping,
            showFinalizeModal,
            restartedStep,
            mappingNeedsUpgrade,
            isFinalized,
            validationTimestamp,
            model,
            concepts,
            validationErrors,
            currentStepInfo,
            basePath,
            sample,
            loadingSampleRun,
            pageLoading,
            restrictingConcept,
            mappingComponent,
            hasFailed,
            hasCompleted,
            stats,
            lazyFieldChanged,
            hasChanges,
            fetchedConceptsFrom,
            loadingRefresh,
            upgradingMapping,
            changeStep,
            runValidation,
            runOnSample,
            upgradeMapping,
            reviseTask,
            finalize,
            conceptChanged,
            discardChangesAndProceed,
            revisedMapping,
            clearMapping,
            next,
            save,
            confirmedModal,
        };
    },
});
