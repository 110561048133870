





import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import {
    AnonymisationStatistics,
    IdentifierAnonymisationField,
    IdentifierAnonymisationMethod,
    TaskStats,
} from '../../../types';

export default defineComponent({
    name: 'IdentifierRule',
    props: {
        field: {
            type: Object as PropType<IdentifierAnonymisationField>,
            required: true,
        },
        statistics: { type: Object as PropType<TaskStats<AnonymisationStatistics>> },
    },
    setup(props) {
        const methods: Ref<
            {
                key: IdentifierAnonymisationMethod;
                label: string;
                description: string;
            }[]
        > = computed(() => [
            {
                key: 'drop',
                description: `This column ${props.statistics ? 'was dropped' : 'will be dropped'} from the dataset.`,
                label: 'Drop Column',
            },
        ]);

        const selectedMethod: Ref<
            | {
                  key: IdentifierAnonymisationMethod;
                  label: string;
                  description: string;
              }
            | undefined
        > = computed(() =>
            methods.value.find(
                (m: { key: IdentifierAnonymisationMethod; label: string; description: string }) =>
                    props.field.options.anonymisationMethod === m.key,
            ),
        );

        return { selectedMethod };
    },
});
