









































































































































































import { ExternalKafkaHarvesterConfiguration } from '@/modules/apollo/types';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { FormBlock, AlertBanner } from '@/app/components';
import { ValidationProvider } from 'vee-validate';
import { KafkaSaslMechanism, KafkaSecurityProtocol } from '@/modules/apollo/constants';

export default defineComponent({
    name: 'ConnectionDetails',
    components: {
        ValidationProvider,
        FormBlock,
        AlertBanner,
    },
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<ExternalKafkaHarvesterConfiguration>,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const securityProtocols = Object.values(KafkaSecurityProtocol);
        const saslMechanisms = Object.values(KafkaSaslMechanism);

        const isNoneSaslMechanish = computed(
            () => props.configuration.connectionDetails.saslMechanism === KafkaSaslMechanism.None,
        );

        const isSaslSecurityProtocol = computed(
            () =>
                props.configuration.connectionDetails?.securityProtocol &&
                [KafkaSecurityProtocol.SaslPlaintext, KafkaSecurityProtocol.SaslSSL].includes(
                    props.configuration.connectionDetails.securityProtocol,
                ),
        );

        const changeSecurityProtocol = () => {
            if (!isSaslSecurityProtocol.value) {
                props.configuration.connectionDetails.saslMechanism = KafkaSaslMechanism.None;
                emit('reset-connection-details');
            }
        };

        const changeSASLMechanism = async () => {
            if (props.configuration.connectionDetails.saslMechanism === KafkaSaslMechanism.None) {
                emit('reset-connection-details');
            }
        };

        return {
            securityProtocols,
            saslMechanisms,
            isNoneSaslMechanish,
            isSaslSecurityProtocol,
            changeSecurityProtocol,
            changeSASLMechanism,
        };
    },
});
