





























import * as R from 'ramda';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { useSampleFields } from '../composable';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { Scrollbar } from '@/app/components';
import { CleaningFieldConfiguration } from '../types/cleaning.type';

export default defineComponent({
    name: 'SampleValues',
    components: { ChevronRightIcon, Scrollbar },
    props: {
        fields: {
            type: Array as PropType<CleaningFieldConfiguration[]>,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        previousProcessedSample: {
            type: Array,
            default: null,
        },
        size: {
            type: Number,
            default: 20,
        },
    },
    setup(props) {
        const { extractFieldSample } = useSampleFields();

        const fieldsSample = computed(() => {
            if (props.sample) {
                return props.fields.map((field: any) => {
                    const col: any = R.pick(['title', 'path', 'order', 'type'], field);
                    if (props.previousProcessedSample) {
                        col.sample = extractFieldSample(props.previousProcessedSample, field.title, field.path);
                    } else {
                        col.sample = extractFieldSample(props.sample, field.originalName, field.originalPath);
                    }
                    return col;
                });
            }
            return [];
        });

        return { fieldsSample };
    },
});
