

























import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { AnonymisationField, IdentifierAnonymisationMethod, IdentifierOptionsField } from '../../types';
import AnonymisationSection from './AnonymisationSection.vue';
import IdentifierRule from './rules/IdentifierRule.vue';

export default defineComponent({
    name: 'IdentifierAnonymisation',
    model: {
        prop: 'field',
        event: 'changed',
    },
    props: {
        field: { type: Object as PropType<AnonymisationField>, required: true },
        inEdit: { type: Boolean, default: false },
    },
    components: { AnonymisationSection, IdentifierRule },
    setup: (props, { emit }) => {
        const fieldOptions: Ref<IdentifierOptionsField> = computed({
            get: () => props.field.options as IdentifierOptionsField,
            set: (updatedOptions: IdentifierOptionsField) =>
                emit('changed', { ...props.field, options: updatedOptions }),
        });

        const methods: {
            key: IdentifierAnonymisationMethod;
            label: string;
            description: string;
        }[] = [
            {
                key: 'drop',
                description: 'This column will be dropped from the dataset.',
                label: 'Drop Column',
            },
        ];

        const selectedMethod: Ref<
            | {
                  key: IdentifierAnonymisationMethod;
                  label: string;
                  description: string;
              }
            | undefined
        > = computed(() =>
            methods.find(
                (m: { key: IdentifierAnonymisationMethod; label: string; description: string }) =>
                    fieldOptions.value.anonymisationMethod === m.key,
            ),
        );

        return { selectedMethod, methods, fieldOptions };
    },
});
