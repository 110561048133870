















import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api';
import { useAxios } from '@/app/composable';
import { FormModal } from '@/app/components';
import { AccessTokenAPI } from '../api';
import { AccessToken, AccessTokenScopes } from '.';
import { AccessTokenScope } from '../types/access-token-scope.interface';

export default defineComponent({
    name: 'AccessTokenModal',
    components: { FormModal, AccessTokenScopes, AccessToken },
    props: {
        scopes: {
            type: Array as PropType<AccessTokenScope[]>,
            default: () => [],
        },
    },
    setup(props, { root }) {
        const { exec } = useAxios(true);

        const isTokenGenerated = ref<boolean>(false);

        const token: any = reactive({
            name: null,
            key: '',
        });

        const generateToken = () => {
            const scopes: string[] = props.scopes
                .filter((scope: AccessTokenScope) => scope.checked)
                .map((scope: AccessTokenScope) => scope.name);

            const payload = {
                name: token.name,
                scopes,
            };

            exec(AccessTokenAPI.generateToken(payload))
                .then((res: any) => {
                    token.key = res.data.key;
                    token.name = null;
                    isTokenGenerated.value = true;
                })
                .catch(() => (root as any).$toastr.e('The token could not be generated due to an error.', 'Error'));
        };

        const title = computed(() => {
            if (props.scopes.length > 1) return 'Generate an access token';
            else if (props.scopes.length === 1) return `Generate an access token with ${props.scopes[0].name}`;
            return '';
        });

        return { token, isTokenGenerated, title, generateToken };
    },
});
