











































import { TrashIcon, XIcon } from '@vue-hero-icons/outline';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'MappingSelectedFields',
    props: {
        selected: {
            type: Number,
            required: true,
        },
    },
    components: { TrashIcon, XIcon },
});
