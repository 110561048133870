




















































import { defineComponent, PropType } from '@vue/composition-api';
import { DocumentIcon, XIcon } from '@vue-hero-icons/outline';
import { TwProgressBar } from '@/app/components';
import { useFilters } from '@/app/composable';
import { clone } from 'ramda';
import { FileUploadStatus } from '../../../constants';
import { TFile } from '../../../types';

export default defineComponent({
    name: 'FilesUpload',
    model: {
        prop: 'files',
        event: 'update-files',
    },
    props: {
        files: {
            type: Array as PropType<TFile[]>,
            required: true,
        },
        progress: {
            type: Number,
            default: 0,
        },
        progressId: {
            type: Number,
            default: -1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        canDeleteFiles: {
            type: Boolean,
            default: true,
        },
    },
    components: { DocumentIcon, XIcon, TwProgressBar },
    setup(props, { emit }) {
        const { formatBytes } = useFilters();

        const removeFile = (file: any) => {
            const files = clone(props.files);

            const idx = files.findIndex((f: any) => f === file);
            if (~idx) {
                files.splice(idx, 1);
            }

            // update v-model files props
            emit('update-files', files);
            // emit remove-file event to inform parent component
            emit('remove-file', file);
        };

        return {
            formatBytes,
            removeFile,
            FileUploadStatus,
        };
    },
});
