






























import { useExecutionErrors } from '@/app/composable';
import { HashtagIcon } from '@vue-hero-icons/outline';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ExecutionError',
    props: {
        error: { type: Number, required: true },
        extended: { type: Boolean, default: false },
        occurrences: { type: Number, required: false },
        totalOccurrences: { type: Number, required: false },
        details: { type: String, required: false },
    },
    components: { HashtagIcon },
    setup() {
        const { errorMessage, errorOccurrencePercentage } = useExecutionErrors();

        return { errorMessage, errorOccurrencePercentage };
    },
});
