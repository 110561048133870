





















































import * as R from 'ramda';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { AlertBanner, FormBlock, JsonParser } from '@/app/components';
import { ApolloTask, ExternalKafkaHarvesterConfiguration, TFile } from '@/modules/apollo/types';
import { useApolloTask } from '@/modules/apollo/composable';

export default defineComponent({
    name: 'ReviewExternalKafkaHarvester',
    components: {
        AlertBanner,
        FormBlock,
        JsonParser,
    },
    model: {
        prop: 'task',
    },
    props: {
        task: {
            type: Object as PropType<ApolloTask<ExternalKafkaHarvesterConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        sample: {
            type: [Array, Object],
            required: false,
        },
        sampleFile: {
            type: File as PropType<TFile>,
            required: false,
        },
        finalSample: {
            type: [Array, Object],
            required: false,
        },
    },
    setup(props) {
        const { isFinalized, inDraftStatus } = useApolloTask(computed(() => props.task));
        const defaultBasePath = 'res';
        const separator = '||';

        const isSampleArray = computed(() => R.is(Array, props.sample));

        const displaySampleIsArrayBanner = computed(
            () => isSampleArray.value && !isFinalized.value && !props.loading && !!props.sampleFile,
        );

        const focusPath = computed(() =>
            props.task.configuration.response?.basePath !== defaultBasePath
                ? props.task.configuration.response?.basePath
                : null,
        );

        const sampleSelectionInfo = computed(() => {
            if (props.sampleFile)
                return {
                    title: 'Uploaded Sample Selection',
                    description:
                        'The complete sample uploaded by the user. The user needs to select the concepts that should be further processed and stored. The concepts that are not selected will be discarded.',
                };
            return {
                title: 'Kafka Response Selection',
                description:
                    'The complete Kafka response retrieved when testing the Kafka connection. The user needs to select the concepts that should be further processed and stored. The concepts that are not selected will be discarded.',
            };
        });

        const sampleSummaryText = computed(() => (props.sampleFile ? 'Uploaded Sample' : 'Kafka Response'));

        if (props.task.configuration.response) props.task.configuration.response.basePath = defaultBasePath;

        return {
            defaultBasePath,
            separator,
            isFinalized,
            inDraftStatus,
            displaySampleIsArrayBanner,
            focusPath,
            sampleSelectionInfo,
            sampleSummaryText,
        };
    },
});
