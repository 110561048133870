
























































































































































































































import { ApiHarvesterMethod, ApiHarvesterParameter } from '@/modules/apollo/types';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { clone, isNil } from 'ramda';
import {
    LinkIcon,
    LockClosedIcon,
    PencilAltIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    DocumentDuplicateIcon,
    TrashIcon,
} from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import ParameterModal from './ParameterModal.vue';

export default defineComponent({
    name: 'APIRequestParameters',
    model: { prop: 'parameters', event: 'changed' },
    props: {
        parameters: {
            type: Array as PropType<ApiHarvesterParameter[]>,
            required: true,
        },
        method: { type: String as PropType<ApiHarvesterMethod> },
        disabled: { type: Boolean, default: false },
        testLoginResponseKeys: { type: Array as PropType<string[]>, default: () => [] },
    },
    components: {
        ParameterModal,
        LinkIcon,
        ExclamationIcon,
        LockClosedIcon,
        PencilAltIcon,
        PlusIcon,
        QuestionMarkCircleIcon,
        DocumentDuplicateIcon,
        TrashIcon,
    },
    setup(props, { emit }) {
        const parameterInEdit = ref<{ parameter: ApiHarvesterParameter; index?: number | undefined } | undefined>();
        const params = computed({
            get: () => props.parameters,
            set: (newParams: ApiHarvesterParameter[]) => {
                emit('changed', newParams);
            },
        });

        const isDuplicateParam = (key: string, keyType: 'query' | 'url' | 'body') => {
            return (
                params.value.filter(
                    (param: ApiHarvesterParameter) => param.key.name === key && param.key.type === keyType,
                ).length > 1
            );
        };

        const inVault = (str: string | number | undefined): boolean => {
            if (!isNil(str)) {
                return `${str}`.startsWith('vault://');
            }
            return false;
        };

        const valueClasses = (type: string | undefined) => {
            switch (type) {
                case 'dynamic':
                    return 'p-1 bg-green-200 rounded-lg';
                case 'authentication':
                    return 'p-1 bg-purple-200 rounded-lg';
                case 'pagination':
                    return 'p-1 bg-orange-200 rounded-lg';
                default:
                    return '';
            }
        };

        const configureParameter = (index: number) => {
            parameterInEdit.value = { parameter: clone(params.value[index]), index };
        };

        const addParameter = () => {
            parameterInEdit.value = {
                parameter: {
                    key: {
                        name: '',
                        type: 'query',
                    },
                    value: {
                        value: undefined,
                        type: undefined,
                        format: undefined,
                        category: undefined,
                    },
                    sensitive: false,
                },
            };
        };

        const setParameter = (parameter: ApiHarvesterParameter, index?: number | undefined) => {
            const newParams = clone(params.value);
            if (!isNil(index)) newParams[index] = parameter;
            else newParams.push(parameter);
            params.value = newParams;
            parameterInEdit.value = undefined;
        };

        const removeParameter = (index: number) => {
            if (!isNil(index)) params.value.splice(index, 1);
        };

        return {
            params,
            isDuplicateParam,
            inVault,
            valueClasses,
            configureParameter,
            addParameter,
            parameterInEdit,
            setParameter,
            removeParameter,
            isNil,
        };
    },
});
