






























































































































import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { AnonymisationField, DateAnonymisationField } from '../../types';
import HandleNullValuesFieldSection from './HandleNullValuesFieldSection.vue';
import PreviewAnonymisationRules from './PreviewAnonymisationRules.vue';
import { MappingFieldConfiguration } from '../../types/typings';
import { useQuasiIdentifierAnonymisation } from '../../composable';
import { isNil } from 'ramda';
import AnonymisationSection from './AnonymisationSection.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CalendarIcon } from '@vue-hero-icons/outline';
import DateRule from './rules/DateRule.vue';

dayjs.extend(utc);

export default defineComponent({
    name: 'DateAnonymisationField',
    model: {
        prop: 'field',
        event: 'changed',
    },
    props: {
        field: {
            type: Object as PropType<DateAnonymisationField>,
            required: true,
        },
        inEdit: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        mappingField: {
            type: Object as PropType<MappingFieldConfiguration & { originalTitle: string }>,
            required: true,
        },
        sample: { type: Array, required: true },
    },
    components: {
        HandleNullValuesFieldSection,
        PreviewAnonymisationRules,
        AnonymisationSection,
        CalendarIcon,
        DateRule,
    },
    setup(props, { emit }) {
        const generalisationTypeInfo: Record<
            'date' | 'datetime' | 'time',
            {
                format: string;
                levelOptions: { level: number; label: string }[];
                rules: Record<number, { label: string; dateFunc: Function }>;
                description: string;
            }
        > = {
            date: {
                format: `YYYY-MM-DD`,
                levelOptions: [
                    {
                        level: 2,
                        label: 'Month',
                    },
                    {
                        level: 3,
                        label: 'Year (Decade)',
                    },
                ],
                rules: {
                    1: { label: 'Day', dateFunc: (d: dayjs.Dayjs) => d.set('date', 1) },
                    2: { label: 'Month and day', dateFunc: (d: dayjs.Dayjs) => d.set('month', 0) },
                    3: { label: 'All parts', dateFunc: (d: dayjs.Dayjs) => d.set('year', d.year() - (d.year() % 10)) },
                },
                description: `The field values will likely be generalized by resetting different parts of the date, starting
                        from the day until the year. The anonymisation algorithm will try to reset as few datetime parts
                        as possible.`,
            },
            datetime: {
                format: `YYYY-MM-DDTHH:mm:ss UTC`,
                levelOptions: [
                    {
                        level: 3,
                        label: 'Hours',
                    },
                    {
                        level: 4,
                        label: 'Day',
                    },
                    {
                        level: 5,
                        label: 'Month',
                    },
                    {
                        level: 6,
                        label: 'Year (Decade)',
                    },
                ],
                rules: {
                    1: { label: 'Seconds', dateFunc: (d: dayjs.Dayjs) => d.set('second', 0).set('millisecond', 0) },
                    2: { label: 'Minutes and seconds', dateFunc: (d: dayjs.Dayjs) => d.set('minute', 0) },
                    3: { label: 'Hours, minutes and seconds', dateFunc: (d: dayjs.Dayjs) => d.set('hour', 0) },
                    4: { label: 'Day, hours, minutes and seconds', dateFunc: (d: dayjs.Dayjs) => d.set('date', 1) },
                    5: {
                        label: 'Month, day, hours, minutes and seconds',
                        dateFunc: (d: dayjs.Dayjs) => d.set('month', 0),
                    },
                    6: { label: 'All parts', dateFunc: (d: dayjs.Dayjs) => d.set('year', d.year() - (d.year() % 10)) },
                },
                description: `The field values will likely be generalized by resetting different parts of the datetime,
                        starting from the seconds until the year. The anonymisation algorithm will try to reset as few
                        datetime parts as possible.`,
            },
            time: {
                format: `HH:mm:ss UTC`,
                levelOptions: [
                    {
                        level: 2,
                        label: 'Minutes',
                    },
                    {
                        level: 3,
                        label: 'Hours',
                    },
                ],
                rules: {
                    1: { label: 'Seconds', dateFunc: (d: dayjs.Dayjs) => d.set('second', 0).set('millisecond', 0) },
                    2: { label: 'Minutes and seconds', dateFunc: (d: dayjs.Dayjs) => d.set('minute', 0) },
                    3: { label: 'All parts', dateFunc: (d: dayjs.Dayjs) => d.set('year', d.year() - (d.year() % 10)) },
                },
                description: `The field values will likely be generalized by resetting different parts of the time, starting
                        from the seconds until the hours. The anonymisation algorithm will try to reset as few time
                        parts as possible.`,
            },
        };

        const selectedField: Ref<DateAnonymisationField> = computed({
            get: () => props.field,
            set: (updatedField: AnonymisationField) => {
                emit('changed', updatedField);
            },
        });
        const mappingFieldRef = computed(() => props.mappingField);
        const sampleRef = computed(() => props.sample);
        const { getSampleValue, baseRule, rules } = useQuasiIdentifierAnonymisation(
            selectedField,
            mappingFieldRef,
            sampleRef,
        );

        const getExampleValue = (row: Record<string, any>, level: number): string | null => {
            const value = getSampleValue(row) as string | undefined;
            if (isNil(value)) return null;
            let d = dayjs(value).utc();

            if (level !== 0) {
                for (const levelRuleKey in generalisationTypeInfo[selectedField.value.generalisation].rules) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            generalisationTypeInfo[selectedField.value.generalisation].rules,
                            levelRuleKey,
                        )
                    ) {
                        const levelRule =
                            generalisationTypeInfo[selectedField.value.generalisation].rules[levelRuleKey];
                        if (level >= parseInt(levelRuleKey, 10)) {
                            d = levelRule.dateFunc(d);
                        }
                    }
                }
            }

            return d.format(generalisationTypeInfo[selectedField.value.generalisation].format);
        };

        return { selectedField, rules, baseRule, generalisationTypeInfo, getExampleValue };
    },
});
