



















import { FormBlock } from '@/app/components';
import { computed, defineComponent, Ref } from '@vue/composition-api';

export default defineComponent({
    name: 'MqttProtocol',
    model: {
        prop: 'protocolVersion',
        event: 'change',
    },
    props: {
        protocolVersion: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { FormBlock },
    setup(props, { emit }) {
        const selectedProtocolVersion: Ref<string | null> = computed({
            get: () => props.protocolVersion,
            set: (newProtocolVersion: string | null) => {
                emit('change', newProtocolVersion);
            },
        });

        const protocolVersionOptions = [
            { label: 'v5.0', value: '5' },
            { label: 'v3.1.1', value: '3.1.1' },
            { label: 'v3.1', value: '3.1' },
        ];

        return { selectedProtocolVersion, protocolVersionOptions };
    },
});
