



















































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { CheckCircleIcon } from '@vue-hero-icons/outline';
import { Scrollbar } from '@/app/components';

// this is an eslint limitation where it doesn't recognise that
// row and index are part of a type and is complaining that they
// are not being used
type ExampleValueCalculator = (row: any, index: number) => any; //eslint-disable-line no-unused-vars

export default defineComponent({
    name: 'PreviewAnonymisationRules',
    props: {
        baseRule: {
            type: Object as PropType<{
                level: number;
                beforeTitleMessage: string;
                title: string;
                afterTitleMessage: string;
            }>,
            required: true,
        },
        numberOfRules: { type: Number, default: 0 },
        sample: { type: Array as PropType<Array<any>>, required: true },
        exampleValueCalculator: { type: Function as PropType<ExampleValueCalculator>, required: true },
        showExampleTable: { type: Boolean, default: true },
    },
    components: { CheckCircleIcon, Scrollbar },
    setup(props) {
        const limittedSample = computed(() => {
            if (props.sample.length > 5) {
                return props.sample.slice(0, 5);
            }
            return props.sample;
        });
        return { limittedSample };
    },
});
