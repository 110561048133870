































































































































































import { ApolloTask, KafkaConnectionDetails, KafkaHarvesterConfiguration, TFile } from '@/modules/apollo/types';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { ChevronRightIcon, RefreshIcon } from '@vue-hero-icons/outline';
import { TwButton, FormBlock, CopyToClipboardButton, ConfirmModal } from '@/app/components';
import { KafkaProcessingOptions } from '@/modules/apollo/constants';
import { SampleUpload, ProcessingOptions, RetrieveUntilSettings } from '../common';
import { OrbitSpinner } from 'epic-spinners';
import { ValidationObserver } from 'vee-validate';
import { useAxios } from '@vue-composable/axios';
import { ApolloAPI } from '@/modules/apollo/api';
import { useApolloTask } from '@/modules/apollo/composable';
import { ScheduleType } from '@/modules/workflow-designer/types';
import dayjs from 'dayjs';

export default defineComponent({
    name: 'SetupKafkaHarvester',
    components: {
        ValidationObserver,
        ChevronRightIcon,
        TwButton,
        OrbitSpinner,
        FormBlock,
        CopyToClipboardButton,
        RefreshIcon,
        SampleUpload,
        ConfirmModal,
        ProcessingOptions,
        RetrieveUntilSettings,
    },
    props: {
        task: {
            type: Object as PropType<ApolloTask<KafkaHarvesterConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        sample: {
            type: [Array, Object],
            required: false,
        },
        sampleFile: {
            type: File as PropType<TFile>,
            required: false,
        },
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
    },
    setup(props, { emit, root }) {
        const { exec } = useAxios(true);
        const { isFinalized, inDraftStatus, inDeprecatedStatus, pipelineFinalized } = useApolloTask(
            computed(() => props.task),
        );
        const kafkaValidationRef = ref<any>(null);
        const initializing = ref<boolean>(false);
        const initializationFailed = ref<boolean>(false);
        const resettingPassword = ref<boolean>(false);
        const showResetPasswordModal = ref<boolean>(false);

        const connectionDetails = computed<KafkaConnectionDetails>(() => props.task.configuration.connectionDetails);

        const isInitialized = computed(() => !!connectionDetails.value.username);

        const maxEndDateForProcessing = computed(() =>
            props.task.configuration.retrieval?.endDate ? new Date(props.task.configuration.retrieval.endDate) : null,
        );

        const invalidSchedulesEndDate = computed(() => {
            const today = dayjs().utc();
            const invalidSchedules = props.schedules.filter((schedule: ScheduleType) =>
                dayjs.utc(schedule.endDate).add(1, 'day').isBefore(today),
            );
            return !!invalidSchedules.length;
        });

        const validateAndProceed = async () => {
            if (!kafkaValidationRef.value) return;
            const valid = await kafkaValidationRef.value.validate();
            if (valid) {
                const inclusiveDate = dayjs.utc(props.task.configuration.retrieval.endDate).add(1, 'day'); // add 1 extra day in order to make it inclusive
                if (
                    !pipelineFinalized.value &&
                    (inclusiveDate.isBefore(dayjs().utc()) || invalidSchedulesEndDate.value)
                ) {
                    let errorMessage = 'Retrieve Until Date is in the past. Please update it accordingly to continue.';
                    if (props.task.configuration.processing === KafkaProcessingOptions.TimeBased)
                        errorMessage =
                            'End Dates of Schedules are in the past. Please update them accordingly to continue.';

                    (root as any).$toastr.e(errorMessage, 'Invalid Retrieve Until Date');
                } else {
                    emit('next-tab');
                }
            }
        };

        const initialize = async () => {
            initializationFailed.value = false;
            initializing.value = true;
            exec(ApolloAPI.createCredentials(props.task.pipeline.id))
                .then((res: any) => {
                    emit('update-connection-details', res.data);
                })
                .catch(() => {
                    initializationFailed.value = true;
                })
                .finally(() => {
                    initializing.value = false;
                });
        };

        const resetPassword = async () => {
            showResetPasswordModal.value = false;
            resettingPassword.value = true;
            exec(ApolloAPI.resetPassword(props.task.pipeline.id))
                .then((res) => {
                    emit('update-connection-details', { ...connectionDetails.value, password: res?.data });
                    (root as any).$toastr.s('Kafka password has been reset!', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to reset kafka password', 'Failed');
                })
                .finally(() => {
                    resettingPassword.value = false;
                });
        };

        const confirmResetPassword = () => {
            showResetPasswordModal.value = true;
        };

        if (!initializing.value && !isInitialized.value) {
            initialize();
        }

        return {
            KafkaProcessingOptions,
            inDraftStatus,
            kafkaValidationRef,
            connectionDetails,
            isFinalized,
            inDeprecatedStatus,
            initializing,
            initializationFailed,
            isInitialized,
            resettingPassword,
            showResetPasswordModal,
            maxEndDateForProcessing,
            pipelineFinalized,
            validateAndProceed,
            confirmResetPassword,
            resetPassword,
        };
    },
});
