





















































































































































































import { AlertBanner, FacetsFilters, Pagination, Scrollbar, SearchBox, SvgImage, TwSelect } from '@/app/components/';
import { TButton } from '@/app/components/common';
import { FacetFilterType, useFacetsFilters, useQueryParams } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { useSockets } from '@/app/composable/socket';
import { WorkflowType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import {
    ExecutionSortingOption,
    ExecutionStatus,
    ExecutionType,
    MessageType,
} from '@/modules/workflow-designer/constants';
import { ChevronLeftIcon, FilterIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ExecutionAPI, WorkflowAPI } from '../../api';
import { useExecutionHistoryFacets } from '../../composable';
import { useExecutions } from '../../composable/execution';
import { EventMessage, ExecutionsResponse, Workflow } from '../../types';
import ExecutionHistoryStatistics from './ExecutionHistoryStatistics.vue';
import ExecutionLogs from './ExecutionLogs.vue';
import Executions from './Executions.vue';

export default defineComponent({
    name: 'ExecutionHistory',
    metaInfo() {
        return {
            title: `Execution History${(this as any).workflow ? ` for: ${(this as any).workflow.name}` : ''}`,
        };
    },
    components: {
        OrbitSpinner,
        SvgImage,
        Pagination,
        Scrollbar,
        TwSelect,
        ExecutionHistoryStatistics,
        Executions,
        FilterIcon,
        ChevronLeftIcon,
        FacetsFilters,
        SearchBox,
        TButton,
        AlertBanner,
        ExecutionLogs,
    },
    props: {
        workflowId: {
            type: [String, Number],
            required: true,
        },
        workflowType: {
            type: String as PropType<WorkflowType>,
            required: true,
            validator: (value: string) => (Object.values(WorkflowType) as string[]).includes(value),
        },
        routeName: {
            type: String,
            required: true,
        },
        isLongRunning: {
            type: Boolean,
            default: false,
        },
        unwantedFacetKeys: { type: Array as PropType<string[]>, default: () => ['workflowType'] },
        facetLabelPreprocess: {
            type: Object as PropType<Record<string, Function>>,
            default: () => {
                return {};
            },
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    setup(props, { root }) {
        const PAGE_SIZE = props.isLongRunning ? 20 : 10;
        const {
            subscribe,
            unsubscribe,
            WebSocketsEvents,
            leaveSocketRoom,
            joinSocketRoom,
            WebSocketsRoomTypes,
        } = useSockets();
        const router = useRouter();
        const { get, set, onChange } = useQueryParams(root, router, props.routeName, props.queryParams);
        const sortingOptions = ExecutionSortingOption.all();
        const expandExecutionId = ref<string | undefined>(get('executionId'));
        const executionsResponse = ref<ExecutionsResponse | null>(null);
        const openFilters = ref<boolean>(true);
        const page = ref<number>(get('page') ? parseInt(get('page'), 10) : 1);
        const searchText = ref<string>(get('search') || '');
        const sortBy = ref<string>(get('sortBy') ? get('sortBy') : sortingOptions[0].label);
        const showCancelWarning = ref<boolean>(false);
        const {
            executionHistoryFacets,
            reducedExecutionHistoryFacets,
            executionHistoryFilters,
            reducedExecutionHistoryFilters,
            textNumberFilters,
        } = useExecutionHistoryFacets(get, onChange, props.workflowType, props.isLongRunning);

        const isAnalytics = computed(() => props.workflowType === WorkflowType.Analytics);

        const { setFacetsFilters, getValue, capitalize } = useFacetsFilters();
        const query = ref<MonitoringExecutionQuery>({
            query: {
                workflowId: String(props.workflowId),
                executionId: null,
                dateRange: {
                    start: null,
                    end: null,
                },
                message: '',
            },
            facets: reducedExecutionHistoryFacets.value,
            sortBy: {
                field: props.isLongRunning ? 'timestamp' : 'created_at',
                asc: false,
            },
            pagination: {
                index: 0,
                pageSize: PAGE_SIZE,
            },
        });

        const setPage = (newPage: number) => {
            page.value = newPage;
            set('page', page.value, 1);
        };

        const {
            loading,
            executionMetrics,
            exec,
            error,
            getExecutionsOrExecutionLogs,
            getExecutionMetrics,
        } = useExecutions(props.workflowId, props.workflowType, props.isLongRunning);

        getExecutionMetrics();

        const loadExecutionsOrExecutionLogs = async () => {
            query.value.pagination.index = (page.value - 1) * PAGE_SIZE;
            query.value.query.dateRange = {
                start: reducedExecutionHistoryFilters.value?.dateRange?.min,
                end: reducedExecutionHistoryFilters.value?.dateRange?.max,
            };
            query.value.query.message = searchText.value;

            if (!props.isLongRunning) {
                query.value.query.executionId = reducedExecutionHistoryFilters.value.executionId?.id;
                const sortingOption = ExecutionSortingOption.findByLabel(sortBy.value);
                const sortField = sortingOption?.fieldPaths[0][0];
                query.value.sortBy.field = sortField.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);
                query.value.sortBy.asc = sortingOption?.asc ? true : false;
                query.value.facets = reducedExecutionHistoryFacets.value;
            }
            executionsResponse.value = await getExecutionsOrExecutionLogs(query.value);
        };

        loadExecutionsOrExecutionLogs();

        const executionsOrLongRunningLogs = computed(() => executionsResponse.value?.results || []);
        const pagination = computed(() => executionsResponse.value?.pagination);
        const facets = computed(() => {
            // remove unwanted facets workflow
            const cleanFacets = Object.keys(R.omit(props.unwantedFacetKeys, executionsResponse.value?.facets));

            // use props.facetLabelPreprocess to calculate label if any
            return cleanFacets.reduce((acc: Record<string, any>, facetKey: string) => {
                if (!executionsResponse.value?.facets) return acc;

                // if preprocess function found then use it
                if (
                    R.has(facetKey, props.facetLabelPreprocess) &&
                    props.facetLabelPreprocess[facetKey] &&
                    executionsResponse.value?.facets
                )
                    acc[facetKey] = executionsResponse.value.facets[facetKey].map((value: any) => {
                        return {
                            ...value,
                            label: props.facetLabelPreprocess[facetKey](value.value),
                        };
                    });
                // otherwise just return the array
                else acc[facetKey] = executionsResponse.value.facets[facetKey];

                return acc;
            }, {});
        });

        const customError = computed(() => {
            if (error?.value?.response?.status === 403)
                return { title: 'Access Forbidden!', message: 'You do not have access to the specific pipeline' };
            return undefined;
        });

        const changePage = (newPage: number) => {
            setPage(newPage);
            loadExecutionsOrExecutionLogs();
        };

        const changeSorting = (value: any) => {
            set('sortBy', value);
            loadExecutionsOrExecutionLogs();
        };

        const workflow = ref<Workflow | undefined>();

        const backTo = () => {
            root.$router.push({
                name: isAnalytics.value ? 'workflows' : 'data-checkin-jobs',
                query: JSON.parse(props.queryParams),
            });
        };

        const loadWorkflow = () => {
            exec(WorkflowAPI.getWorkflow(props.workflowId))
                .then((res: any) => {
                    workflow.value = res.data;
                })
                .catch((e) => {
                    if (e.response?.status === 403) {
                        (root as any).$toastr.e('You do not have access to view this page.', 'Access Denied');
                        backTo();
                    }
                });
        };

        const cancelExecution = (uid: string | number) => {
            exec(ExecutionAPI.cancel(uid, props.workflowId))
                .then(() => {
                    (root as any).$toastr.s(
                        'The request to cancel the execution has been successfully submitted',
                        'Success',
                    );
                    showCancelWarning.value = true;
                })
                .catch(() => {
                    (root as any).$toastr.e('Execution has failed to be cancelled', 'Error');
                });
        };

        const onEnter = () => {
            set('search', searchText.value, '');
            setPage(1);
            loadExecutionsOrExecutionLogs();
        };

        const clearSearch = () => {
            searchText.value = '';
            set('search', '');
            setPage(1);
            loadExecutionsOrExecutionLogs();
        };

        const facetChanged = (facetKey: string, facetValue: any, facetType: FacetFilterType) => {
            setFacetsFilters(reducedExecutionHistoryFacets.value, facetKey, facetValue, facetType);
            set(facetKey, getValue(reducedExecutionHistoryFacets.value, facetKey, facetType));
            setPage(1);
            loadExecutionsOrExecutionLogs();
        };

        const filterChanged = (filterKey: string, filterValue: any, filterType: FacetFilterType, minOrMax = '') => {
            setFacetsFilters(reducedExecutionHistoryFilters.value, filterKey, filterValue, filterType, minOrMax);
            set(
                `${filterKey}${capitalize(minOrMax)}`,
                getValue(reducedExecutionHistoryFilters.value, filterKey, filterType, minOrMax),
            );
            setPage(1);
            loadExecutionsOrExecutionLogs();
        };

        const clearFilters = () => {
            if (query.value.facets) {
                Object.keys(query.value.facets).forEach((facet: any) => {
                    if (query.value.facets) {
                        query.value.facets[facet].splice(0);
                        set(facet, query.value.facets[facet].join(','));
                    }
                });
            }
            Object.keys(query.value.query).forEach((filter: any) => {
                switch (R.type(query.value.query[filter])) {
                    case 'Object':
                        if (
                            Object.keys(query.value.query[filter]).includes('start') &&
                            Object.keys(query.value.query[filter]).includes('end')
                        ) {
                            reducedExecutionHistoryFilters.value[filter].min = null;
                            reducedExecutionHistoryFilters.value[filter].max = null;
                            set(`${filter}Min`, null);
                            set(`${filter}Max`, null);
                        }
                        break;
                    case 'Array':
                        query.value.query[filter].splice(0);
                        set(filter, query.value.query[filter].join(','));
                        break;
                    default:
                        if (Object.keys(textNumberFilters.value).includes(filter)) {
                            textNumberFilters.value[filter] = null;
                        }
                        if (
                            R.type(reducedExecutionHistoryFilters.value[filter]) === 'Object' &&
                            Object.keys(reducedExecutionHistoryFilters.value[filter]).includes('id')
                        ) {
                            reducedExecutionHistoryFilters.value[filter].id = null;
                        }
                        query.value.query[filter] = null;
                        set(filter, null);
                }
            });
            query.value.query.workflowId = String(props.workflowId);
            setPage(1);
            loadExecutionsOrExecutionLogs();
        };

        loadWorkflow();

        const onMessage = (data: EventMessage) => {
            if (data.type === MessageType.Status && data.body.executionType === ExecutionType.Normal) {
                const toBeUpdatedExecution = executionsOrLongRunningLogs.value?.filter(
                    (e: any) => e.executionId === data.executionId,
                )[0];
                toBeUpdatedExecution.status = data.body.status as ExecutionStatus;
                if (data.body.status !== ExecutionStatus.Running) showCancelWarning.value = false;
            } else if (data.type === MessageType.Log) {
                const toBeUpdatedExecution: any = executionsOrLongRunningLogs.value?.filter(
                    (e: any) => e.executionId === data.executionId,
                )[0];

                if (toBeUpdatedExecution && toBeUpdatedExecution.logs) {
                    toBeUpdatedExecution.logs.push({
                        level: data.body.level,
                        timestamp: dayjs.utc(data.body.timestamp),
                        message: data.body.message,
                    });
                }
            }
            loadExecutionsOrExecutionLogs();
        };

        onBeforeMount(() => {
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
            joinSocketRoom(WebSocketsRoomTypes.Workflow, props.workflowId);
        });
        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, props.workflowId);
        });

        onChange(() => {
            expandExecutionId.value = get('executionId');
            changePage(get('page') ?? 1);
        });

        watch(
            () => page.value,
            (pageNum) => {
                if (pageNum) changePage(pageNum);
            },
        );

        return {
            loading,
            sortingOptions,
            sortBy,
            workflow,
            pagination,
            cancelExecution,
            expandExecutionId,
            executionMetrics,
            executionsOrLongRunningLogs,
            changeSorting,
            openFilters,
            facets,
            executionHistoryFacets,
            facetChanged,
            filterChanged,
            executionHistoryFilters,
            reducedExecutionHistoryFilters,
            clearSearch,
            onEnter,
            searchText,
            textNumberFilters,
            clearFilters,
            query,
            showCancelWarning,
            isAnalytics,
            customError,
            setPage,
            backTo,
        };
    },
});
