





















import { FormBlock } from '@/app/components';
import { computed, defineComponent, PropType, Ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { SQLDatabaseType } from '../../../constants';

export default defineComponent({
    name: 'SQLDatabaseDetails',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<{
                sqlType: SQLDatabaseType;
            }>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormBlock,
        ValidationProvider,
    },
    setup: (props, { emit }) => {
        const databaseDetailsConfiguration: Ref<{
            sqlType: SQLDatabaseType;
        }> = computed({
            get: () => props.configuration,
            set: (newConfiguration: {}) => {
                emit('change', newConfiguration);
            },
        });

        const sqlTypes: { value: SQLDatabaseType; label: string }[] = [
            { value: SQLDatabaseType.MsSQL, label: 'SQL Server (v14+)' },
            { value: SQLDatabaseType.PostgresSQL, label: 'PostgreSQL (v8+)' },
            { value: SQLDatabaseType.MySQL, label: 'MySQL (v5.7+)' },
            { value: SQLDatabaseType.MariaDB, label: 'MariaDB (v5.5+)' },
        ];

        return {
            databaseDetailsConfiguration,
            sqlTypes,
        };
    },
});
