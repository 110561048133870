


















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'AnonymisationSection',
    props: {
        prominent: { type: Boolean, default: false },
    },
});
