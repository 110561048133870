
































































































import { PropType, Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { AlertBanner, ButtonGroup, ConfirmModal, InputErrorIcon } from '@/app/components';
import {
    ApiHarvesterMethod,
    ApiHarvesterPagination,
    ApiHarvesterParameter,
    ApiPaginationOption,
} from '@/modules/apollo/types';
import { ApiPaginationOptions } from '@/modules/apollo/constants';
import { clone, omit } from 'ramda';
import { S } from '@/app/utilities';
import { ValidationProvider } from 'vee-validate';

export default defineComponent({
    name: 'APIPagination',
    model: { prop: 'paginationConfiguration', event: 'changed' },
    props: {
        paginationConfiguration: {
            type: Object as PropType<{ configuration: ApiHarvesterPagination; parameters: ApiHarvesterParameter[] }>,
            required: true,
        },
        method: { type: String as PropType<ApiHarvesterMethod> },
        disabled: { type: Boolean, default: false },
    },
    components: { ConfirmModal, InputErrorIcon, AlertBanner, ValidationProvider, ButtonGroup },
    setup: (props, { emit }) => {
        const paginationTypes: Record<
            string,
            { parameter: ApiHarvesterParameter; title: string; description: string }[]
        > = {
            offset: [
                {
                    parameter: {
                        key: {
                            name: 'limit',
                            type: 'query',
                        },
                        value: {
                            value: 100,
                            type: 'integer',
                            category: 'pagination',
                        },
                        sensitive: false,
                        optional: false,
                        paginateWith: 'limit',
                    },
                    title: 'Limit',
                    description: 'How many items to include in each page',
                },

                {
                    parameter: {
                        key: {
                            name: 'offset',
                            type: 'query',
                        },
                        value: {
                            value: 0,
                            type: 'integer',
                            category: 'pagination',
                        },
                        sensitive: false,
                        optional: false,
                        paginateWith: 'offset',
                    },
                    title: 'Offset',
                    description: 'The starting offset value',
                },
            ],
            page: [
                {
                    parameter: {
                        key: {
                            name: 'limit',
                            type: 'query',
                        },
                        value: {
                            value: 100,
                            type: 'integer',
                            category: 'pagination',
                        },
                        sensitive: false,
                        optional: true,
                        paginateWith: 'limit',
                    },
                    title: 'Limit',
                    description: 'How many items to include in each page',
                },
                {
                    parameter: {
                        key: {
                            name: 'page',
                            type: 'query',
                        },
                        value: {
                            value: 1,
                            type: 'integer',
                            category: 'pagination',
                        },
                        sensitive: false,
                        optional: false,
                        paginateWith: 'page',
                    },
                    title: 'Page',
                    description: 'The starting page',
                },
            ],
        };

        const newPaginationType = ref<ApiPaginationOption | undefined>();
        const showChangePaginationModal = ref<boolean>(false);

        const initializePagination = (newPagination: {
            configuration: ApiHarvesterPagination;
            parameters: ApiHarvesterParameter[];
        }): { configuration: ApiHarvesterPagination; parameters: ApiHarvesterParameter[] } => {
            return {
                configuration: {
                    type: newPagination.configuration.type || 'no',
                },
                parameters: newPagination.parameters,
            };
        };

        const pagination = ref<{ configuration: ApiHarvesterPagination; parameters: ApiHarvesterParameter[] }>(
            initializePagination(props.paginationConfiguration),
        );

        const currentPaginationParameters: Ref<
            (ApiHarvesterParameter & { ignore: boolean; title: string; description: string })[]
        > = computed(() => {
            switch (pagination.value.configuration.type) {
                case 'offset':
                    return clone(
                        paginationTypes.offset.map(
                            (t: { parameter: ApiHarvesterParameter; title: string; description: string }) => {
                                const p = pagination.value.parameters.find(
                                    (param: ApiHarvesterParameter) => param.key.name === t.parameter.key.name,
                                );
                                return {
                                    ...t.parameter,
                                    ...p,
                                    ignore: pagination.value.parameters.some(
                                        (param: ApiHarvesterParameter) =>
                                            param.key.name === t.parameter.key.name &&
                                            param.value.category === 'pagination',
                                    ),
                                    title: t.title,
                                    description: t.description,
                                };
                            },
                        ),
                    );
                case 'page':
                    return clone(
                        paginationTypes.page.map(
                            (t: { parameter: ApiHarvesterParameter; title: string; description: string }) => {
                                const p = pagination.value.parameters.find(
                                    (param: ApiHarvesterParameter) => param.key.name === t.parameter.key.name,
                                );
                                return {
                                    ...t.parameter,
                                    ...p,
                                    ignore: pagination.value.parameters.some(
                                        (param: ApiHarvesterParameter) =>
                                            param.key.name === t.parameter.key.name &&
                                            param.value.category === 'pagination',
                                    ),
                                    title: t.title,
                                    description: t.description,
                                };
                            },
                        ),
                    );
                default:
                    return [];
            }
        });

        const confirmChangePagination = (type: ApiPaginationOption) => {
            if (type === pagination.value.configuration.type) return;

            newPaginationType.value = type;
            showChangePaginationModal.value = true;
        };

        const changePaginationType = () => {
            switch (newPaginationType.value) {
                case 'offset':
                    pagination.value.parameters = clone(
                        paginationTypes.offset.map(
                            (t: { parameter: ApiHarvesterParameter; title: string; description: string }) =>
                                t.parameter,
                        ),
                    );
                    break;
                case 'page':
                    pagination.value.parameters = clone(
                        paginationTypes.page.map(
                            (t: { parameter: ApiHarvesterParameter; title: string; description: string }) =>
                                t.parameter,
                        ),
                    );
                    break;
                default:
                    pagination.value.configuration.type = 'no';
                    pagination.value.parameters = [];
            }

            showChangePaginationModal.value = false;
            pagination.value.configuration.type = newPaginationType.value || 'no';
            newPaginationType.value = undefined;

            emit('changed', pagination.value);
        };

        const changeParameterValueType = (key: string, type: 'query' | 'body') => {
            emit('changed', {
                ...pagination.value,
                parameters: pagination.value.parameters.map((param: ApiHarvesterParameter) => {
                    if (param.key.name === key) {
                        param.key.type = type;
                    }
                    return param;
                }),
            });
        };

        const changeIgnoreStatus = (
            event: any,
            param: ApiHarvesterParameter & { ignore: boolean; title: string; description: string },
        ) => {
            if (event.target.checked) {
                const idx = pagination.value.parameters.findIndex(
                    (p: ApiHarvesterParameter) => p.key.name === param.key.name,
                );
                pagination.value.parameters.splice(idx, 1);
            } else {
                pagination.value.parameters.push(omit(['ignore', 'title', 'description'], param));
            }
            emit('changed', pagination.value);
        };

        return {
            pagination,
            paginationTypes,
            newPaginationType,
            showChangePaginationModal,
            ApiPaginationOptions,
            currentPaginationParameters,
            capitalizeFirstLetter: S.capitalizeFirstLetter,
            confirmChangePagination,
            changePaginationType,
            changeParameterValueType,
            changeIgnoreStatus,
        };
    },
});
