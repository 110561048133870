
















































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { is_not } from 'vee-validate/dist/rules'; // eslint-disable-line
import dayjs from 'dayjs';
import { InputErrorIcon } from '@/app/components';
import { ChevronDownIcon } from '@vue-hero-icons/solid';
import { TrashIcon } from '@vue-hero-icons/outline';

extend('is_not', {
    ...is_not, // eslint-disable-line
    message: 'The {_field_} field is required',
});

const getDayText = (day: string) => {
    switch (day) {
        case '1':
        case '21':
        case '31':
            return `${day}st`;
        case '2':
        case '22':
            return `${day}nd`;
        case '3':
        case '23':
            return `${day}rd`;
        default:
            return `${day}th`;
    }
};

export function cronToString(cronExpression: string, startDate: Date, endDate: Date, retrieveOnce = false): string {
    const cron = cronExpression.split(' ');
    let minutes = cron[0];
    let hours = cron[1];

    // Add leading zero in minutes and hours if length is 1 and not equal to *
    if (minutes.length === 1 && minutes !== '*') minutes = `0${minutes}`;
    if (hours.length === 1 && hours !== '*') hours = `0${hours}`;

    let result = 'Runs ';

    if (hours === '*') {
        result += `every hour, at XX:${minutes}`;
    } else {
        result += `at ${hours}:${minutes} `;

        if (retrieveOnce) {
            if (startDate) {
                const date = dayjs.utc(startDate).format(`D MMMM YYYY`).split(' ');
                result += `on the ${getDayText(date[0])} of ${date[1]} ${date[2]}`;
            }
        } else {
            const dayOfMonth = cron[2];
            const dayOfWeek = cron[4];

            if (dayOfWeek === '*') {
                if (dayOfMonth === '*') result += 'every day';
                else {
                    result += `on the ${getDayText(dayOfMonth)} day of every month`;
                    if (['29', '30', '31'].includes(dayOfMonth))
                        result += ` (won't run if month has less than ${dayOfMonth} days)`;
                }
            } else if (dayOfMonth === '*') {
                const daysOfWeek: Record<string, string> = {
                    '0': 'Sundays',
                    '1': 'Mondays',
                    '2': 'Tuesdays',
                    '3': 'Wednesdays',
                    '4': 'Thursdays',
                    '5': 'Fridays',
                    '6': 'Saturdays',
                };

                if (!Object.keys(daysOfWeek).includes(dayOfWeek))
                    return `Unreadable cron format. Cron will be displayed in its raw form: ${cronExpression}`;

                result += `on ${daysOfWeek[dayOfWeek]}`;
            }
        }
    }

    if (!retrieveOnce) {
        if (startDate) result += `, starting from ${dayjs.utc(startDate).format('D MMMM YYYY')}`;
        if (endDate) result += ` to ${dayjs.utc(endDate).format('D MMMM YYYY')}`;
    }

    result += ` (UTC timezone applied)`;
    return result;
}

export interface CronSchedule {
    minutes: number | string;
    hours: number | string;
    dayOfMonth: number | string;
    month: number | string;
    dayOfWeek: number | string;
}

export default defineComponent({
    name: 'CronSchedule',
    model: {
        prop: 'schedule',
        event: 'changed',
    },
    components: {
        ValidationProvider,
        InputErrorIcon,
        ChevronDownIcon,
        TrashIcon,
    },
    props: {
        period: {
            type: String,
            required: true,
        },
        startDate: {
            type: Date,
            default: null,
        },
        endDate: {
            type: Date,
            default: null,
        },
        schedule: {
            type: String,
            required: true,
        },
        showDeleteIcon: {
            type: Boolean,
            default: true,
        },
        whiteBackground: {
            type: Boolean,
            default: false,
        },
        retrieveOnce: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const scheduleObject = ref<CronSchedule | null>(null);
        const fromCronSchedule = (cronSchedule: string): CronSchedule => {
            const cron = cronSchedule.split(' ');
            return {
                minutes: cron[0],
                hours: cron[1],
                dayOfMonth: cron[2],
                month: cron[3],
                dayOfWeek: cron[4],
            };
        };

        const toCronSchedule = (schedule: CronSchedule): string => {
            const { minutes, hours, dayOfMonth, month, dayOfWeek } = schedule;
            return `${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek}`;
        };

        watch(
            () => props.schedule,
            (value: string) => {
                scheduleObject.value = fromCronSchedule(value);
            },
        );
        scheduleObject.value = fromCronSchedule(props.schedule);

        const scheduleChanged = () => {
            if (scheduleObject.value) {
                emit('changed', toCronSchedule(scheduleObject.value));
            }
        };
        const isValidSchedule = computed(() => {
            switch (props.period) {
                case 'hourly':
                    return scheduleObject.value?.minutes !== '*';
                case 'daily':
                    return scheduleObject.value?.minutes !== '*' && scheduleObject.value?.hours !== '*';
                case 'weekly':
                    return (
                        scheduleObject.value?.minutes !== '*' &&
                        scheduleObject.value?.hours !== '*' &&
                        scheduleObject.value?.dayOfWeek !== '*'
                    );
                case 'monthly':
                    return (
                        scheduleObject.value?.minutes !== '*' &&
                        scheduleObject.value?.hours !== '*' &&
                        scheduleObject.value?.dayOfMonth !== '*'
                    );
                default:
                    return false;
            }
        });

        return { scheduleChanged, scheduleObject, cronToString, isValidSchedule };
    },
});
