





























































































































































import { ButtonGroup, Scrollbar, SearchBox } from '@/app/components';
import { useExecutionErrors, useJsonObject } from '@/app/composable';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { useSampleFields } from '../../composable';
import { MappingFilter, playgroundFilters } from '../../constants';
import { FieldConfiguration, Stats, StatsPerField, TaskStats } from '../../types';
import ConceptOverview from './ConceptOverview.vue';
import ConceptView from './ConceptView.vue';
import MappingSelectedFields from './MappingSelectedFields.vue';

export default defineComponent({
    name: 'MappingPlayground',
    props: {
        fields: {
            type: Array as PropType<FieldConfiguration[]>,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        selectedMappings: {
            type: Array,
            required: true,
        },
        activeFilter: {
            type: String as PropType<MappingFilter>,
            required: true,
        },
        stats: {
            type: Object as PropType<TaskStats<Stats<StatsPerField>>>,
            default: null,
        },
        hasFailed: {
            type: Boolean,
            default: false,
        },
        hasCompleted: {
            type: Boolean,
            default: false,
        },
        sample: {
            type: Array,
            required: true,
        },
        validationErrors: {
            type: Object as PropType<
                Record<number, { message: string | null; description?: string | null; type?: string; title?: string }>
            >,
            required: true,
        },
        noTransformations: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: Array as PropType<string[] | undefined>,
            default: () => [],
        },
    },
    components: { SearchBox, ButtonGroup, Scrollbar, ConceptView, MappingSelectedFields, ConceptOverview },
    setup(props) {
        const searchText = ref<string>('');

        const { extractFieldSample } = useSampleFields();
        const { errorMessage } = useExecutionErrors();
        const { getFixedJSON } = useJsonObject();

        const sample = computed(() => getFixedJSON(props.sample));

        const selectedFields = computed(() => {
            const diff = R.difference(props.fields, props.selectedMappings);
            return R.pluck('source' as any, R.difference(props.fields, diff) as any).reduce(
                (fields: any[], obj: any) => {
                    fields.push({ ...obj, sample: extractFieldSample(sample.value, obj.title, obj.path) });
                    return fields;
                },
                [],
            );
        });

        const selectedMappingsIds = computed(() => props.selectedMappings.map((mapping: any) => mapping.source?.id));

        const includesSearchText = (value: any) => {
            return (
                !searchText.value.trim() ||
                value.source.title.toUpperCase().includes(searchText.value.toUpperCase().trim())
            );
        };

        const filteredFields = computed(() => {
            if (props.isFinalized) {
                return props.fields.filter((obj: any) => {
                    return 'target' in obj && 'id' in obj.target && obj.target.id;
                });
            }

            return props.fields.filter((obj: any) => {
                if (!includesSearchText(obj)) return false;
                switch (props.activeFilter) {
                    case MappingFilter.Predicted:
                        return obj.target.id && obj.prediction && obj.prediction.score;
                    case MappingFilter.Corrected:
                        return obj.temp && obj.temp.userDefined;
                    case MappingFilter.Unidentified:
                        return obj.target.id === null;
                    case MappingFilter.Invalid:
                        return obj.temp && obj.temp.invalid;
                    case MappingFilter.Selected:
                        return selectedMappingsIds.value.includes(obj.source?.id);
                    default:
                        return true;
                }
            });
        });

        const statsInField = (field: FieldConfiguration) => {
            if (props.stats?.successfulStats?.statsPerField?.length)
                return props.stats.successfulStats.statsPerField.find((s) => s.id === field.source.id);
            else if (props.hasCompleted && props.stats?.latestExecutionStats)
                // step completed successfully, but failed at a later step, so we should consider latest execution stats as successful for this step
                return props.stats.latestExecutionStats.statsPerField.find((s) => s.id === field.source.id);
            return null;
        };

        const failedStatsInField = (field: FieldConfiguration) => {
            if (props.hasFailed && props.stats?.latestExecutionStats)
                return props.stats.latestExecutionStats.statsPerField.find((s) => s.id === field.source.id);
            return null;
        };

        const failedReasonsInField = (field: FieldConfiguration) => {
            if (props.hasFailed && props.stats?.latestExecutionStats) {
                const errorCode = props.stats.latestExecutionStats.statsPerField.find((s) => s.id === field.source.id)
                    ?.errorCode;
                return errorCode ? errorMessage(errorCode).error.title : null;
            }
            return null;
        };

        return {
            searchText,
            playgroundFilters,
            filteredFields,
            selectedMappingsIds,
            selectedFields,
            statsInField,
            failedStatsInField,
            failedReasonsInField,
        };
    },
});
