
































import { FormBlock } from '@/app/components';
import { useApolloTask } from '@/modules/apollo/composable';
import { ApolloTask, LargeFilesHarvesterConfiguration, TFile } from '@/modules/apollo/types';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { is, isEmpty, isNil, pick, pluck, zipObj } from 'ramda';
import { AdditionalResponseData, FilesUpload, SampleReview } from '../common';

export default defineComponent({
    name: 'ReviewLargeFilesHarvester',
    components: {
        SampleReview,
        FormBlock,
        AdditionalResponseData,
        FilesUpload,
    },
    model: {
        prop: 'task',
    },
    props: {
        task: {
            type: Object as PropType<ApolloTask<LargeFilesHarvesterConfiguration>>,
            required: true,
        },
        sample: {
            type: [Array, Object],
        },
        sampleFile: {
            type: File as PropType<TFile>,
        },
        fileProgress: {
            type: Object as PropType<{ id: number; current: number }>,
            default: () => ({ id: -1, current: 0 }),
        },
    },
    setup(props, { emit }) {
        const task = ref(props.task);
        const { isFinalized } = useApolloTask(task);

        const sampleArray = computed(() => {
            const sample = props.sample;
            if (isNil(sample) || isEmpty(sample)) return [];

            return !is(Array, sample) ? [sample] : sample;
        });

        const originalSample = computed(() =>
            sampleArray.value.map((sample) => pick(task.value?.configuration.params.fields ?? [], sample)),
        );

        const uploadedFiles = computed(() => (props.sampleFile ? [props.sampleFile] : []));

        const changeAdditionalParameters = () => {
            const sampleWithAdditionalParams = originalSample.value.map((sample) => ({
                ...sample,
                ...zipObj(
                    pluck('key', task.value!.configuration.response.additional),
                    pluck('displayValue', task.value!.configuration.response.additional),
                ),
            }));
            emit('sample-uploaded', sampleWithAdditionalParams);
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<LargeFilesHarvesterConfiguration>) => {
                task.value = newTask;
            },
        );

        return { isFinalized, originalSample, uploadedFiles, changeAdditionalParameters };
    },
});
