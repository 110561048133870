
















































import { Scrollbar } from '@/app/components';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { MappingConfiguration } from '../../types';
import ConceptOverview from './ConceptOverview.vue';

export default defineComponent({
    name: 'MappingReview',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object as PropType<MappingConfiguration>,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        validationErrors: {
            type: Object as PropType<
                Record<number, { message: string | null; description?: string | null; type?: string; title?: string }>
            >,
            required: true,
        },
    },
    components: { Scrollbar, ConceptOverview },
    setup(props) {
        const filteredFields = computed(() =>
            props.configuration.fields.filter((obj: any) => 'target' in obj && 'id' in obj.target && obj.target.id),
        );

        const unidentifiedConcepts = computed(() => {
            const concepts = props.configuration.fields.filter(
                (obj: any) => !('target' in obj && 'id' in obj.target && obj.target.id),
            );
            const splitConcepts: any[][] = [];
            for (let i = 0; i < concepts.length; i += 4) {
                splitConcepts.push(concepts.slice(i, i + 4));
            }
            return splitConcepts;
        });

        return { filteredFields, unidentifiedConcepts };
    },
});
