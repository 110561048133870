





























































































































































import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import {
    AnonymisationConfigurationType,
    AnonymisationField,
    AnonymisationFieldStatistics,
    AnonymisationStatistics,
    TaskStats,
} from '../../types';
import AnonymisationOverallConfiguration from './AnonymisationOverallConfiguration.vue';
import { ValidationObserver } from 'vee-validate';
import { Scrollbar, SvgImage, Toggle } from '@/app/components';
import { isNil } from 'ramda';
import { MappingFieldConfiguration } from '../../types/typings';
import AnonymisationFieldOverview from './AnonymisationFieldOverview.vue';
import AnonymisationSection from './AnonymisationSection.vue';
import { useQuasiIdentifierAnonymisation } from '../../composable';
import { TaskExecutionStatus } from '../../constants';

export default defineComponent({
    name: 'AnonymisationReview',
    model: {
        prop: 'configuration',
        event: 'changed',
    },
    props: {
        pipelineId: { type: String },
        statistics: { type: Object as PropType<TaskStats<AnonymisationStatistics>> },
        configuration: { type: Object as PropType<AnonymisationConfigurationType>, required: true },
        fieldMappingConfigurations: {
            type: Object as PropType<Record<string, MappingFieldConfiguration>>,
            required: true,
        },
        isFinalized: { type: Boolean, default: false },
        isUnderRevise: { type: Boolean, default: false },
        sample: { type: Array, required: true },
        errors: {
            type: Array as PropType<
                { category: string; error: { title: string; description: string }; linkToHistory: boolean }[]
            >,
            default: () => [],
        },
        stepStatus: { type: String, default: 'draft' },
    },
    components: {
        AnonymisationOverallConfiguration,
        ValidationObserver,
        Scrollbar,
        AnonymisationFieldOverview,
        AnonymisationSection,
        Toggle,
        SvgImage,
    },
    setup(props, { emit }) {
        const isConfigurationValid = ref(false);
        const anonymsationRef = ref();
        const showAlternateNaming = ref<boolean>(false);
        const selectedFieldName = ref<string | undefined>();

        const sample = computed(() => props.sample);

        const selectedField = computed(() =>
            !isNil(selectedFieldName.value) ? fieldsMap.value[selectedFieldName.value] : undefined,
        );

        const mappingField = computed(() => getMappingField(selectedField.value));

        const rules = computed(() => {
            if (!isNil(selectedFieldName.value)) {
                const quasiIdentifiers = useQuasiIdentifierAnonymisation(selectedField, mappingField, sample);
                return quasiIdentifiers.rules.value;
            }
            return [];
        });

        const taskConfiguration = computed({
            get: () => props.configuration,
            set: (updatedConfiguration: AnonymisationConfigurationType) => {
                isConfigurationValid.value = anonymsationRef.value.validate();
                emit('changed', updatedConfiguration);
            },
        });

        const hasAlternateNames = computed(() =>
            Object.values(props.fieldMappingConfigurations).some((f: MappingFieldConfiguration) => !isNil(f.alias)),
        );

        const getMappingField = (field: AnonymisationField): MappingFieldConfiguration & { originalTitle: string } => {
            const fieldConfiguration = props.fieldMappingConfigurations[field.anonymisationIdentifier];
            const title =
                showAlternateNaming.value && fieldConfiguration.alias
                    ? `${fieldConfiguration.alias}${fieldConfiguration.multiple ? '[1]' : ''}`
                    : fieldConfiguration.title;
            return { ...fieldConfiguration, title, originalTitle: fieldConfiguration.title as string };
        };

        const getFullField = (field: AnonymisationField) => {
            return { ...field, ...getMappingField(field) };
        };

        const quasiIdentifiers = computed(() =>
            taskConfiguration.value.fields
                .filter((f: AnonymisationField) => f.anonymisationType === 'quasi-identifier')
                .map((f: AnonymisationField) => getFullField(f)),
        );

        const identifiers = computed(() =>
            taskConfiguration.value.fields
                .filter((f: AnonymisationField) => f.anonymisationType === 'identifier')
                .map((f: AnonymisationField) => getFullField(f)),
        );

        const sensitives = computed(() =>
            taskConfiguration.value.fields
                .filter((f: AnonymisationField) => f.anonymisationType === 'sensitive')
                .map((f: AnonymisationField) => getFullField(f)),
        );

        const insensitives = computed(() =>
            taskConfiguration.value.fields
                .filter((f: AnonymisationField) => f.anonymisationType === 'insensitive')
                .map((f: AnonymisationField) => getFullField(f)),
        );

        const insensitivesInGroupsOfFour = computed(() =>
            insensitives.value.reduce(
                (
                    result: Array<Array<AnonymisationField & MappingFieldConfiguration>>,
                    value: AnonymisationField & MappingFieldConfiguration,
                    index: number,
                    array: Array<AnonymisationField & MappingFieldConfiguration>,
                ) => {
                    if (index % 4 === 0) result.push(array.slice(index, index + 4));
                    return result;
                },
                [],
            ),
        );

        const statsPerField = computed(() =>
            props.statistics?.latestExecutionStats?.statsPerField
                ? props.statistics.latestExecutionStats.statsPerField.reduce(
                      (acc: Record<string, AnonymisationFieldStatistics>, stats: AnonymisationFieldStatistics) => {
                          acc[stats.anonymisationIdentifier] = stats;
                          return acc;
                      },
                      {},
                  )
                : {},
        );

        const fieldsMap = computed(() =>
            props.configuration.fields.reduce((acc: Record<number, AnonymisationField>, field: AnonymisationField) => {
                acc[field.anonymisationIdentifier] = field;
                return acc;
            }, {}),
        );

        watch(
            () => anonymsationRef.value,
            (newAnonymisationRef: any) => {
                if (newAnonymisationRef) isConfigurationValid.value = newAnonymisationRef.validate();
            },
            { immediate: true },
        );

        return {
            TaskExecutionStatus,
            selectedFieldName,
            hasAlternateNames,
            showAlternateNaming,
            anonymsationRef,
            taskConfiguration,
            sensitives,
            insensitivesInGroupsOfFour,
            identifiers,
            quasiIdentifiers,
            statsPerField,
            rules,
            isNil,
        };
    },
});
