



























































import { ApolloTask, LargeFilesHarvesterConfiguration, TFile } from '@/modules/apollo/types';
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { hasIn } from 'ramda';
import { ValidationObserver } from 'vee-validate';
import { SampleUpload } from '../common';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { useAxios } from '@vue-composable/axios';
import { ApolloAPI } from '@/modules/apollo/api';
import { useApolloTask } from '@/modules/apollo/composable';
import { AlertBanner, TwButton } from '@/app/components';
import { FileType, RetrievalType } from '@/modules/apollo/constants';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { useSchedule } from '@/app/composable';

import S3StorageConnectionDetails from './S3StorageConnectionDetails.vue';
import S3StorageInstructions from './S3StorageInstructions.vue';
import LargeFilesRetrievalSettings from './LargeFilesRetrievalSettings.vue';
import ProcessedLargeFiles from './ProcessedLargeFiles.vue';

export default defineComponent({
    name: 'SetupLargeFilesHarvester',
    components: {
        S3StorageInstructions,
        S3StorageConnectionDetails,
        LargeFilesRetrievalSettings,
        ProcessedLargeFiles,
        ValidationObserver,
        SampleUpload,
        OrbitSpinner,
        TwButton,
        AlertBanner,
        ChevronRightIcon,
    },
    model: {
        prop: 'task',
    },
    props: {
        task: {
            type: Object as PropType<ApolloTask<LargeFilesHarvesterConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
        sample: {
            type: [Array, Object],
        },
        sampleFile: {
            type: File as PropType<TFile>,
        },
        scheduleDetailsDefined: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit, root }: { emit: any; root: any }) {
        const { exec } = useAxios(true);
        const task = ref(props.task);
        const { inDraftStatus, pipelineFinalized } = useApolloTask(task);
        const { isValidSchedule } = useSchedule();

        const taskConfig = ref<LargeFilesHarvesterConfiguration>(props.task.configuration);
        const initializingS3Storage = ref<boolean>(false);
        const resettingConnDetails = ref<boolean>(false);

        const errorAlert = ref<string>('');
        const validationRef = ref<any>(null);

        const connDetails = reactive({
            url: taskConfig.value.connectionDetails?.url,
            accessKey: taskConfig.value.connectionDetails?.accessKey,
            secretKey: taskConfig.value.connectionDetails?.secretKey,
        });

        const resetS3SecretKey = async () => {
            if (!task.value) return;

            resettingConnDetails.value = true;
            try {
                const res = await exec(ApolloAPI.resetPassword(task.value.pipeline.id));

                connDetails.secretKey = res?.data;
                root.$toastr.s('Secret key has been reset!', 'Success');
            } catch (error) {
                root.$toastr.e('Failed to reset secret key', 'Failed');
            } finally {
                resettingConnDetails.value = false;
            }
        };

        const retrieveOnce = computed(() => props.task.configuration.retrieval.type === RetrievalType.Once);

        const validateNext = async () => {
            if (!validationRef.value) return;

            const invalidSchedules = props.schedules.filter(
                (schedule: ScheduleType) => !isValidSchedule(schedule, retrieveOnce.value),
            );

            if (!pipelineFinalized.value && invalidSchedules.length) {
                (root as any).$toastr.e(
                    'One or more schedules are in the past or have no valid executions between start and end date. Please update them accordingly.',
                    'Invalid Schedules!',
                );
                return;
            }

            const valid = await validationRef.value.validate();
            if (valid) {
                if (!task.value?.processedSample?.length && taskConfig.value.fileType === FileType.Parquet) {
                    emit('run-on-sample');
                } else {
                    emit('next-tab');
                }
            }
        };

        watch(
            () => props.task.configuration.connectionDetails,
            (value) => {
                if (value) {
                    // update connection details for rendering
                    connDetails.url = value.url;
                    connDetails.accessKey = value.accessKey;
                    connDetails.secretKey = value.secretKey;

                    if (initializingS3Storage) {
                        if (hasIn('secretKey', value))
                            // emit event to destroy secret key from task
                            emit('destroy-secret-key');
                        // disable initialisation loader
                        initializingS3Storage.value = false;
                    }
                } else {
                    //in case no connection details, enable loading and emit event to initialise storage and create credentials
                    initializingS3Storage.value = true;
                    emit('initialise-s3-storage');
                }
            },
            { immediate: true },
        );

        watch(
            () => props.task,
            (newTask: ApolloTask<LargeFilesHarvesterConfiguration>) => {
                task.value = newTask;
            },
        );

        return {
            initializingS3Storage,
            connDetails,
            resettingConnDetails,
            inDraftStatus,
            validationRef,
            errorAlert,
            resetS3SecretKey,
            validateNext,
        };
    },
});
