





















































































































































































































































































import { AlertBanner, FormModal, InputErrorIcon } from '@/app/components';
import { dateFormats, datetimeFormats, dynamicValues } from '@/app/constants';
import { regexValidator, requiredValidator } from '@/app/validators';
import { ApiHarvesterMethod, ApiHarvesterParameter } from '@/modules/apollo/types';
import { ChevronDownIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { is } from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';

extend('required', requiredValidator);
extend('regex', regexValidator);

export default defineComponent({
    name: 'ParameterModal',
    components: {
        ValidationObserver,
        ValidationProvider,
        InputErrorIcon,
        FormModal,
        AlertBanner,
        ChevronDownIcon,
    },
    props: {
        parameter: {
            type: Object as PropType<ApiHarvesterParameter>,
            required: true,
        },
        method: {
            type: String as PropType<ApiHarvesterMethod>,
            required: false,
        },
        loginResponseKeys: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        editing: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const paramValidationRef = ref<any>(null);
        const param = ref<ApiHarvesterParameter>(props.parameter as ApiHarvesterParameter);
        const paramCategoryType: Ref<string> = computed({
            get: () => (param.value.value.category || param.value.value.type) as any,
            set: (newCategoryType: string) => {
                if (newCategoryType && ['integer', 'float', 'string'].includes(newCategoryType)) {
                    param.value.value.type = newCategoryType as any;
                    param.value.value.category = undefined;
                } else {
                    param.value.value.category = newCategoryType as any;
                }
            },
        });

        const title = computed(() => {
            if (props.editing) return 'Edit Parameter';
            return 'Add New Parameter';
        });

        const changeType = () => {
            if (param.value.value.category !== 'dynamic') {
                param.value.value.value = undefined;
                param.value.value.format = undefined;
                param.value.value.value = undefined;
                param.value.sensitive = false;
            } else {
                param.value.value.value = { option: undefined, value: undefined };
            }
        };

        const queryTypes = [
            { value: 'static', label: 'Static' },
            { value: 'dynamic', label: 'Dynamic' },
            { value: 'authentication', label: 'Authentication' },
        ];

        const bodyTypes = [
            { value: 'integer', label: 'Integer' },
            { value: 'float', label: 'Float' },
            { value: 'string', label: 'String' },
            { value: 'dynamic', label: 'Dynamic' },
            { value: 'authentication', label: 'Authentication' },
        ];

        const parameterTypes = computed(() => {
            if (param.value.key.type === 'query' || param.value.key.type === 'url') return queryTypes;
            return bodyTypes;
        });

        const formats = computed(() => {
            // slice(1) is used to remove first option 'Infer from data' from formats
            const dateFormatsWithoutInfer = dateFormats.slice(1);
            const datetimeFormatsWithoutInfer = datetimeFormats.slice(1);

            if (param.value.value.value === 'current_date') return dateFormatsWithoutInfer;
            if (param.value.value.value === 'current_datetime') return datetimeFormatsWithoutInfer;
            if (
                param.value.value.value &&
                (`${param.value.value.value}`.includes('minute') || `${param.value.value.value}`.includes('hour'))
            )
                return datetimeFormatsWithoutInfer;
            return dateFormatsWithoutInfer.concat(datetimeFormatsWithoutInfer);
        });

        const needsValue = computed(() => {
            if (!is(Object, param.value.value.value)) return false;
            return !!(
                `${(param.value.value.value as any).option}`.endsWith('_ago') ||
                `${(param.value.value.value as any).option}`.endsWith('_later')
            );
        });

        const showAdvancedSettings = computed(() => {
            return (
                paramCategoryType.value &&
                !['dynamic', 'pagination', 'authentication'].includes(paramCategoryType.value)
            );
        });

        const cancel = () => {
            emit('cancel');
        };

        const validateAndProceed = async () => {
            emit('ok', param.value);
        };

        return {
            paramValidationRef,
            title,
            param,
            dynamicValues,
            formats,
            needsValue,
            changeType,
            cancel,
            validateAndProceed,
            queryTypes,
            bodyTypes,
            parameterTypes,
            showAdvancedSettings,
            paramCategoryType,
        };
    },
});
