





























































import { FormBlock } from '@/app/components';
import { useFilters } from '@/app/composable';
import { DocumentIcon, LockClosedIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { useHarvester } from '../../../composable';
import { BigQueryAuthentication, TFile } from '../../../types';

export default defineComponent({
    name: 'BigQueryAuthenticationDetails',
    model: {
        prop: 'auth',
        event: 'change',
    },
    props: {
        auth: {
            type: Object as PropType<BigQueryAuthentication>,
            required: true,
        },
        file: {
            type: File as PropType<TFile | null>,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { FormBlock, ValidationProvider, ExclamationIcon, DocumentIcon, LockClosedIcon },
    setup(props, { root, emit }) {
        const authDetails: Ref<BigQueryAuthentication> = computed({
            get: () => props.auth,
            set: (newAuthDetails: BigQueryAuthentication) => {
                emit('change', newAuthDetails);
            },
        });

        const fileTypeRef = computed(() => authDetails.value.serviceAccountKey.type);

        const { formatBytes } = useFilters();
        const { parseJSON } = useHarvester(root, emit, fileTypeRef);

        const fileUploaded = async (event: any) => {
            const uploadedFile = event.target.files[0];
            emit('files-changed', { sampleFile: uploadedFile });
            await parseJSON(uploadedFile);
        };

        return { authDetails, fileUploaded, formatBytes };
    },
});
