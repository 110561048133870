
































import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ApolloTaskShell } from '../../../components';
import { useApolloTask, usePolicyFileUpload } from '../../../composable';
import { ApolloTask, HarvesterStep, MqttHarvesterConfiguration, TFile, TaskStep, WizardAction } from '../../../types';

export default defineComponent({
    name: 'MqttHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<MqttHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { ApolloTaskShell },
    setup(props, { root }) {
        const currentStep = ref<HarvesterStep>(HarvesterStep.Setup);
        const sampleFile = ref<TFile | null>(null);
        const sample = ref<any>(props.task.processedSample);
        const finalizing = ref<boolean>(false);
        const showFinalizeModal = ref<boolean>(false);
        const initialEndDate = ref<Date>(R.clone(props.task.configuration.retrieval.endDate));

        const { uploadSampleFileFromData } = usePolicyFileUpload(props.task.pipeline.id);
        const taskRef = ref(props.task);
        const { loading: loadingTask, isFinalized, inDraftStatus, pipelineFinalized, finalize, save } = useApolloTask(
            taskRef,
        );

        const finalLoading = computed(() => props.loading || loadingTask.value);

        const endDateChanged = computed(() => initialEndDate.value !== taskRef.value?.configuration.retrieval.endDate);

        const loadingFinalization = computed(() => showFinalizeModal.value || finalizing.value);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'save-after-finalize',
                show: isFinalized.value,
                enabled: endDateChanged.value,
                showCancel: isFinalized.value && endDateChanged.value,
            },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: currentStep.value === HarvesterStep.Review,
            },
        ]);

        const setUploadedSample = (parsedSample: any) => {
            sample.value = parsedSample;
        };

        const setSampleFile = (file: TFile | null) => {
            sampleFile.value = file;
            if (taskRef.value) taskRef.value.configuration.topicNameField = null;
            if (!file) sample.value = null;
        };

        const saveTask = async () => {
            try {
                const res = await save();
                initialEndDate.value = res.configuration.retrieval.endDate;
                (root as any).$toastr.s('Harvester configuration saved successfully', 'Success');
            } catch (e) {
                (root as any).$toastr.e('Failed to save task', 'An error occurred!');
            }
        };

        const finalizeTask = async () => {
            if (!taskRef.value) return;

            try {
                finalizing.value = true;

                // save configuration and sample
                taskRef.value.configuration.sample = R.is(Array, sample.value) ? sample.value : [sample.value];
                await save();

                // upload sample to minio
                await uploadSampleFileFromData({
                    data: JSON.stringify(sample.value),
                    name: 'sample.json',
                    type: 'application/json',
                    policy: { folder: 'upload', subfolder: `sample/${new Date().valueOf().toString()}` },
                });

                // finalize
                await finalize();

                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            } finally {
                finalizing.value = false;
            }
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<MqttHarvesterConfiguration>) => {
                taskRef.value = newTask;
            },
        );

        return {
            taskRef,
            currentStep,
            sample,
            sampleFile,
            wizardActions,
            loadingTask,
            isFinalized,
            pipelineFinalized,
            finalLoading,
            showFinalizeModal,
            loadingFinalization,
            endDateChanged,
            inDraftStatus,
            setUploadedSample,
            setSampleFile,
            saveTask,
            finalizeTask,
        };
    },
});
