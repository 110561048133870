





















import {
    AnnotationIcon,
    CheckCircleIcon,
    CogIcon,
    InformationCircleIcon,
    PencilAltIcon,
    SwitchVerticalIcon,
} from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import { TransformationType } from '../../constants';

export default defineComponent({
    name: 'TransformationDetails',
    props: {
        transformation: {
            type: String,
            required: true,
        },
        isFail: {
            type: Boolean,
            default: null,
        },
        transformationType: {
            type: String as PropType<TransformationType>,
            required: true,
        },
    },
    setup(props) {
        const icon = computed(() => {
            switch (props.transformationType) {
                case TransformationType.None:
                    return CheckCircleIcon;
                case TransformationType.TypeCast:
                case TransformationType.DatetimeFormat:
                case TransformationType.DecimalPoint:
                case TransformationType.ThousandsSeparator:
                    return InformationCircleIcon;
                case TransformationType.Timezone:
                case TransformationType.Unit:
                    return CogIcon;
                case TransformationType.NestingLevel:
                    return SwitchVerticalIcon;
                case TransformationType.Annotation:
                    return AnnotationIcon;
                case TransformationType.Alias:
                    return PencilAltIcon;
                default:
                    return null;
            }
        });

        return { icon };
    },
});
