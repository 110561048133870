










































import { AlertBanner, FormBlock, JsonParser, SvgImage } from '@/app/components';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import * as R from 'ramda';
import { MqttHarvesterConfiguration } from '../../../types';
import { MqttTopicParameter } from '../common';

export default defineComponent({
    name: 'ReviewMqttHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<MqttHarvesterConfiguration>,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        loadingFinalization: {
            type: Boolean,
            default: false,
        },
        sample: {
            type: [Array, Object],
            default: null,
        },
    },
    components: { AlertBanner, FormBlock, MqttTopicParameter, SvgImage, JsonParser },
    setup(props, { emit }) {
        const mqttConfiguration: Ref<MqttHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: MqttHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const isSampleArray = computed(() => R.is(Array, props.sample));

        const displaySampleIsArrayBanner = computed(
            () => isSampleArray.value && !props.isFinalized && !props.loadingFinalization,
        );

        const setParameterName = (parameterName: string | null) => {
            mqttConfiguration.value.topicNameField = parameterName;
        };

        return { mqttConfiguration, displaySampleIsArrayBanner, setParameterName };
    },
});
