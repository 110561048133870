


































































import { ConfirmModal, CopyToClipboardButton, FormBlock } from '@/app/components';
import { useAxios } from '@/app/composable';
import { RefreshIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { ApolloAPI } from '../../../api';
import { MqttConnectionDetails } from '../../../types';

export default defineComponent({
    name: 'MqttConnectionDetails',
    model: {
        prop: 'connectionDetails',
        event: 'change',
    },
    props: {
        connectionDetails: {
            type: Object as PropType<MqttConnectionDetails>,
            required: true,
        },
        pipelineId: {
            type: String,
            required: true,
        },
        initialized: {
            type: Boolean,
            default: false,
        },
    },
    components: { FormBlock, CopyToClipboardButton, ConfirmModal, RefreshIcon },
    setup(props, { root, emit }) {
        const passwordReset = ref<boolean>(false);
        const showResetPasswordModal = ref<boolean>(false);

        const { exec, loading } = useAxios(true);

        const mqttConnectionDetails: Ref<MqttConnectionDetails> = computed({
            get: () => props.connectionDetails,
            set: (newConnectionDetails: MqttConnectionDetails) => {
                emit('change', newConnectionDetails);
            },
        });

        const resetButton = computed(() => (loading.value ? 'Resetting Password...' : 'Reset Password'));

        const resetPassword = async () => {
            showResetPasswordModal.value = false;
            exec(ApolloAPI.resetPassword(props.pipelineId))
                .then((res: any) => {
                    if (res?.data) mqttConnectionDetails.value.password = res.data;
                    passwordReset.value = true;
                    (root as any).$toastr.s('MQTT password has been reset!', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to reset MQTT password', 'Failed');
                });
        };

        return { showResetPasswordModal, loading, resetButton, mqttConnectionDetails, passwordReset, resetPassword };
    },
});
