






























import { FileWithIcon, FormBlock } from '@/app/components';
import { ApolloTask, LargeFilesHarvesterConfiguration } from '@/modules/apollo/types';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { InformationCircleIcon } from '@vue-hero-icons/solid';

export default defineComponent({
    name: 'ProcessedLargeFiles',
    components: {
        FormBlock,
        InformationCircleIcon,
        FileWithIcon,
    },
    props: {
        task: {
            type: Object as PropType<ApolloTask<LargeFilesHarvesterConfiguration>>,
            required: true,
        },
    },
    setup(props) {
        const processedFiles = computed(() => [
            {
                title: 'Processed Successfully',
                files: props.task.files.filter((file: any) => file.status === 'PROCESSED'),
                tooltip: 'Files that have been processed successfully by the last execution (latest 6).',
            },
            {
                title: 'Failed to be processed',
                files: props.task.files.filter((file: any) => file.status === 'FAILED'),
                tooltip: 'Files that failed to be processed by the latest execution.',
            },
            {
                title: 'Skipped',
                files: props.task.files.filter((file: any) => file.status === 'SKIPPED'),
                tooltip:
                    'Files that haved been skipped from the latest execution because they have the wrong format or are below the size threshold.',
            },
        ]);

        return { processedFiles };
    },
});
