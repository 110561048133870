





























































































import { AlertBanner, IncidentSummary, MonitoringFilters, TailwindTableTabs } from '@/app/components';
import { TButton } from '@/app/components/common';
import { useBlocks, useFeatureFlags, useQueryParams } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { WorkflowType } from '@/app/constants';
import { ExecutionQuery, MonitoringExecutionQuery } from '@/app/types';
import { ExecutionStatus } from '@/modules/workflow-designer/constants';
import { ChevronLeftIcon, ChevronRightIcon } from '@vue-hero-icons/outline';
import { BellIcon, FilterIcon } from '@vue-hero-icons/solid';
import { Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import {
    DcjCompleteness,
    DcjTimeliness,
    IncidentPerDcj,
    IncidentsTimeline,
    MonitoringQuerySummary,
    TopFailures,
} from '../components/monitoring';

const tables: { key: string; label: string; component: any; sortBy: { field: string; asc: boolean } }[] = [
    {
        key: 'IncidentsPerWorkflow',
        label: 'Incidents per Data Check-in Pipeline',
        component: IncidentPerDcj,
        sortBy: {
            field: 'failedExecutions',
            asc: false,
        },
    },
    {
        key: 'IncidentsTimeline',
        label: 'Incidents Timeline',
        component: IncidentsTimeline,
        sortBy: {
            field: 'created_at',
            asc: false,
        },
    },
    {
        key: 'TopFailures',
        label: 'Top Failures',
        component: TopFailures,
        sortBy: {
            field: 'created_at',
            asc: false,
        },
    },
    {
        key: 'Completeness',
        label: 'Completeness',
        component: DcjCompleteness,
        sortBy: {
            field: 'completeness',
            asc: true,
        },
    },
    {
        key: 'Timeliness',
        label: 'Timeliness Expectations',
        component: DcjTimeliness,
        sortBy: {
            field: 'timeliness',
            asc: true,
        },
    },
];

const QUERY_PARAM_SORT_BY_FIELD = 'sortBy';
const QUERY_PARAM_SORT_BY_ASC = 'asc';
const QUERY_PARAM_PAGE = 'page';
export default defineComponent({
    name: 'DataCheckinMonitoring',
    metaInfo: {
        title: 'Data Check-in Pipelines Incidents',
    },
    components: {
        MonitoringFilters,
        IncidentSummary,
        IncidentPerDcj,
        TailwindTableTabs,
        ChevronLeftIcon,
        ChevronRightIcon,
        OrbitSpinner,
        TButton,
        FilterIcon,
        MonitoringQuerySummary,
        AlertBanner,
        BellIcon,
    },
    setup(props, { root }) {
        const { flag } = useFeatureFlags();
        const isAlertsEnabled = flag('alerts');
        const PAGE_SIZE = 30;
        const openFilters: Ref<boolean> = ref<boolean>(false);
        const summaryLoading: Ref<boolean> = ref<boolean>(true);
        const viewLoading: Ref<boolean> = ref<boolean>(true);
        const showResultsWarning: Ref<boolean> = ref<boolean>(false);
        const focusUpdate: Ref<Date> = ref<Date>(new Date());
        const sortOptions = computed(() => {
            return [{ key: 'created_at', label: 'Created at' }];
        });

        const facets: Ref<Record<string, { value: string; count: number; selected: boolean }>> = ref<
            Record<string, { value: string; count: number; selected: boolean }>
        >({});
        const { blocksMap: blocks } = useBlocks();

        const availableFacets: Record<
            string,
            { label: string; visible: boolean; default: string[]; valueLabel?: any }
        > = {
            failedBlockId: {
                label: 'Failure on Step',
                visible: true,
                default: [],
                valueLabel: (id: string) => {
                    const block = blocks.value[id];
                    return block ? block.name : id;
                },
            },
            status: {
                label: 'Status',
                visible: false,
                default: [ExecutionStatus.Failed],
            },
            workflowType: {
                label: 'Pipeline Type',
                visible: false,
                default: [WorkflowType.DataCheckin],
            },
        };

        const queryParams: Ref<
            Record<
                string,
                {
                    param: string;
                    default?: string;
                    isArray: boolean;
                    value?: any;
                    path?: string[];
                    set?: any;
                    permitted?: string[];
                }
            >
        > = computed(() => {
            return {
                searchText: {
                    param: 'search',
                    default: '',
                    isArray: false,
                    path: ['query', 'text'],
                },
                status: {
                    param: 'status',
                    default: availableFacets['status'].default,
                    isArray: true,
                },
                timePeriod: {
                    param: 'period',
                    default: 'today',
                    isArray: false,
                },
                timePeriodStart: {
                    param: 'start',
                    isArray: false,
                    value: (value: any, defaultValue: any) =>
                        value === 'custom' && defaultValue ? defaultValue : null,
                    path: ['query', 'dateRange', 'start'],
                },
                timePeriodEnd: {
                    param: 'end',
                    isArray: false,
                    value: (value: any, defaultValue: any) =>
                        value === 'custom' && defaultValue ? defaultValue : null,
                    path: ['query', 'dateRange', 'end'],
                },
                ownership: {
                    param: 'ownership',
                    isArray: false,
                    default: 'me',
                },
                ...Object.keys(availableFacets).reduce((acc: any, param: string) => {
                    acc[param] = {
                        param,
                        isArray: true,
                        default: availableFacets[param].default,
                        path: ['facets', param],
                    };
                    return acc;
                }, {}),
            };
        });

        const router = useRouter();
        const { set, get, onChange } = useQueryParams(root, router, 'data-checkin-jobs:monitoring');

        const currentTable: Ref<string> = computed({
            get: () => {
                return (root.$route.query?.view as string) || tables[0].key;
            },
            set: (newView: string) => {
                set('view', newView, tables[0].key);
            },
        });
        const currentComponent = computed(
            () =>
                tables.find((t: { key: string }) => t.key === currentTable.value) as {
                    key: string;
                    label: string;
                    component: any;
                    sortBy: { field: string; asc: boolean };
                },
        );

        const filters = ref<{
            query: ExecutionQuery;
            facets: Record<string, any>;
        }>();

        const sortBy = ref<{
            field: string;
            asc: boolean;
        }>({
            field: get(QUERY_PARAM_SORT_BY_FIELD, false, currentComponent.value?.sortBy.field),
            asc: get(QUERY_PARAM_SORT_BY_ASC, false, `${currentComponent.value?.sortBy.asc}`) === 'true',
        });

        const pagination = ref<{
            index: number;
            pageSize: number;
        }>({
            index: 0,
            pageSize: PAGE_SIZE,
        });

        const filterFacets = computed(() =>
            Object.keys(queryParams.value).reduce((acc: Record<string, any>, facetKey: string) => {
                if (filters.value) acc[facetKey] = R.clone(filters.value.facets[facetKey]);
                return acc;
            }, {}),
        );

        const query: Ref<MonitoringExecutionQuery | undefined> = computed(() => {
            if (!filters.value?.query || !filters.value?.facets || !sortBy.value || !pagination.value) return undefined;
            return {
                query: R.clone(filters.value.query),
                facets: R.clone(filterFacets.value),
                sortBy: R.clone(sortBy.value),
                pagination: R.clone(pagination.value),
            };
        });

        const loading = computed(() => summaryLoading.value && viewLoading.value);

        const currentView = computed(() => (currentComponent.value ? currentComponent.value.component : null));

        onChange(() => {
            sortBy.value = {
                field: get(QUERY_PARAM_SORT_BY_FIELD, false, currentComponent.value?.sortBy.field),
                asc: get(QUERY_PARAM_SORT_BY_ASC, false, `${currentComponent.value?.sortBy.asc}`) === 'true',
            };
        });

        const setSummaryLoading = (l: boolean) => {
            summaryLoading.value = l;
        };

        const setViewLoading = (l: boolean) => {
            viewLoading.value = l;
        };

        const changePage = (newPage: number) => {
            set(QUERY_PARAM_PAGE, `${newPage}`, '1');
            pagination.value.index = (newPage - 1) * PAGE_SIZE;
        };

        const setFacets = (newFacets: Record<string, { value: string; count: number; selected: boolean }>) => {
            facets.value = newFacets;
        };

        const toggleFilters = () => {
            openFilters.value = !openFilters.value;
            if (openFilters.value) focusUpdate.value = new Date();
        };

        const filtersChange = (newFilters: any) => {
            filters.value = R.clone(newFilters);
        };

        const changeSort = (newSort: { field: string; asc: boolean } | null) => {
            const defaultSortField = currentComponent.value?.sortBy.field || '';
            const defaultSortAsc = currentComponent.value?.sortBy.asc ? 'true' : 'false';

            if (newSort) {
                sortBy.value = newSort;
                set(QUERY_PARAM_SORT_BY_FIELD, newSort.field, defaultSortField);
                set(QUERY_PARAM_SORT_BY_ASC, newSort.asc ? 'true' : 'false', defaultSortAsc);
            } else {
                sortBy.value = currentComponent.value.sortBy;
                set(QUERY_PARAM_SORT_BY_FIELD, defaultSortField, defaultSortField);
                set(QUERY_PARAM_SORT_BY_ASC, defaultSortAsc, defaultSortAsc);
            }
        };

        const changeView = () => {
            changeSort(null);
            changePage(1);
        };

        const updateShowResultsWarning = (value: boolean) => {
            showResultsWarning.value = value;
        };

        watch(
            () => query.value,
            (newQuery: MonitoringExecutionQuery | undefined, oldQuery: MonitoringExecutionQuery | undefined) => {
                // reset page if filters changed
                if (
                    !R.isNil(oldQuery) &&
                    !R.isNil(newQuery) &&
                    !R.equals({ ...newQuery, pagination: undefined }, { ...oldQuery, pagination: undefined })
                )
                    changePage(1);
            },
        );

        changePage(isNaN(get(QUERY_PARAM_PAGE, false, '1')) ? 1 : parseInt(get(QUERY_PARAM_PAGE, false, '1'), 10));

        return {
            blocks,
            filters,
            query,
            facets,
            openFilters,
            loading,
            currentTable,
            tables,
            currentView,
            focusUpdate,
            sortOptions,
            sortBy,
            showResultsWarning,
            availableFacets,
            queryParams,
            isAlertsEnabled,
            updateShowResultsWarning,
            setSummaryLoading,
            setViewLoading,
            changePage,
            setFacets,
            toggleFilters,
            filtersChange,
            changeSort,
            changeView,
            R,
        };
    },
});
