

































import { FormBlock, SQLEditor } from '@/app/components';
import { computed, defineComponent, PropType, Ref } from '@vue/composition-api';
import { SQLHarvesterConfiguration } from '../../../types';
import { AdditionalResponseData, SampleTable, IdentifierColumn } from '../common';

export default defineComponent({
    name: 'ReviewSQLHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<SQLHarvesterConfiguration>,
            required: true,
        },
        sample: {
            type: Array as PropType<Record<string, any>[]>,
            default: () => [],
        },
        sampleFields: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        structure: {
            type: Object as PropType<Record<string, string>>,
            default: () => {},
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        sqlQueryPreview: {
            type: String,
            default: '',
        },
    },
    components: {
        AdditionalResponseData,
        FormBlock,
        SQLEditor,
        SampleTable,
        IdentifierColumn,
    },
    setup(props, { emit }) {
        const sqlConfiguration: Ref<SQLHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: SQLHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        return { sqlConfiguration };
    },
});
