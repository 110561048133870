






import { DateRule } from '@/modules/apollo/types';
import { PropType, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'DateRule',
    props: {
        rule: {
            type: Object as PropType<DateRule>,
            required: true,
        },
    },
});
