





































import { SvgImage } from '@/app/components';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'LoadingStreamingSampleModal',
    components: { SvgImage },
});
