












































































































import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { AnonymisationConfigurationType, AnonymisationStatistics } from '../../types';
import { maxValueValidator, minValueValidator } from '@/app/validators';
import { extend, ValidationProvider } from 'vee-validate';
import { isNil } from 'ramda';
import { InputErrorIcon } from '@/app/components';

extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);

export default defineComponent({
    name: 'AnonymisationOverallConfiguration',
    model: {
        prop: 'configuration',
        event: 'changed',
    },
    props: {
        configuration: { type: Object as PropType<AnonymisationConfigurationType>, required: true },
        statistics: { type: Object as PropType<AnonymisationStatistics> },
        disabled: { type: Boolean, default: false },
        compact: { type: Boolean, default: false },
    },
    components: { ValidationProvider, InputErrorIcon },
    setup(props, { emit }) {
        const kAnonymity: Ref<number> = computed({
            get: () => props.configuration.kAnonymity,
            set: (newKAnonymity: number) => {
                emit('changed', {
                    ...props.configuration,
                    kAnonymity: newKAnonymity,
                });
            },
        });

        const informationLossThreshold: Ref<number> = computed({
            get: () => props.configuration.lossLimit,
            set: (newLossLimit: number) => {
                emit('changed', {
                    ...props.configuration,
                    lossLimit: newLossLimit,
                });
            },
        });

        const formatNumber = (num: number) => {
            if (num && !Number.isInteger(num)) return num.toFixed(2);
            return num;
        };

        return { kAnonymity, informationLossThreshold, isNil, formatNumber };
    },
});
