
















import { PropType, defineComponent } from '@vue/composition-api';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { NumericalGroupRule } from '@/modules/apollo/types';

export default defineComponent({
    name: 'NumericalGroupRule',
    props: {
        rule: {
            type: Object as PropType<NumericalGroupRule>,
            required: true,
        },
    },
    components: { ChevronRightIcon },
});
