



























































































import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import {
    AnonymisationField,
    NumericalFieldLeveling,
    NumericLevelAnonymisationField,
    NumericalGroupOptionsField,
} from '../../types';
import { MappingFieldConfiguration } from '../../types/typings';
import IntervalAnonymisationField from './IntervalAnonymisationField.vue';
import NumericalGroupAnonymisationField from './NumericalGroupAnonymisationField.vue';
import AnonymisationSection from './AnonymisationSection.vue';

export default defineComponent({
    name: 'NumericalAnonymisationField',
    model: {
        prop: 'field',
        event: 'changed',
    },
    props: {
        field: {
            type: Object as PropType<NumericLevelAnonymisationField>,
            required: true,
        },
        inEdit: { type: Boolean, default: false },
        mappingField: {
            type: Object as PropType<MappingFieldConfiguration & { originalTitle: string }>,
            required: true,
        },
        sample: { type: Array, required: true },
    },
    components: { IntervalAnonymisationField, NumericalGroupAnonymisationField, AnonymisationSection },
    setup(props, { emit }) {
        const selectedField: Ref<NumericLevelAnonymisationField> = computed({
            get: () => props.field,
            set: (updatedField: AnonymisationField) => {
                emit('changed', updatedField);
            },
        });

        const changeGeneralisationMethod = (method: 'interval' | 'numerical-group') => {
            selectedField.value = {
                ...selectedField.value,
                generalisation: method,
                options: {
                    ...selectedField.value.options,
                    leveling: 'auto',
                    levels: method === 'interval' ? [{ interval: null }] : [[{ from: null, to: null }]],
                } as NumericalGroupOptionsField,
            };
        };

        const changeLeveling = (leveling: NumericalFieldLeveling) => {
            if (leveling === selectedField.value.options.leveling) return;
            selectedField.value.options = {
                ...selectedField.value.options,
                leveling,
            };
        };

        return { selectedField, changeGeneralisationMethod, changeLeveling };
    },
});
