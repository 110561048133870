
















































































































import { FormBlock, JsonParser } from '@/app/components';
import { useAPIConnection, useApolloTask } from '@/modules/apollo/composable';
import { ApiHarvesterConfiguration, ApiHarvesterParameter, ApolloTask } from '@/modules/apollo/types';
import { PropType, Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { has, is, isEmpty, isNil, type } from 'ramda';
import { ExclamationCircleIcon, RefreshIcon } from '@vue-hero-icons/outline';
import { AdditionalResponseData, ResponseHandling } from '../common';

export default defineComponent({
    name: 'ReviewAPIHarvester',
    components: {
        FormBlock,
        ResponseHandling,
        AdditionalResponseData,
        JsonParser,
        ExclamationCircleIcon,
        RefreshIcon,
    },
    model: { prop: 'harvesterTask', event: 'changed' },
    props: {
        harvesterTask: {
            type: Object as PropType<ApolloTask<ApiHarvesterConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loginResponse: {
            type: [Object, Array] as PropType<Record<string, any> | Record<string, any>[]>,
        },
        sample: {
            type: [Object, Array] as PropType<Record<string, any> | Record<string, any>[]>,
            default: () => [],
        },
        finalSample: {
            type: [Object, Array] as PropType<Record<string, any> | Record<string, any>[]>,
            default: () => [],
        },
    },
    setup(props) {
        const basePath = ref<string>('res');
        const checkEmptyJSON = (json: any): boolean => {
            if (isNil(json) || isEmpty(json)) {
                return true;
            }
            if (type(json) === 'Array') {
                return checkEmptyJSON(json[0]);
            }
            return false;
        };

        const task = ref<ApolloTask<ApiHarvesterConfiguration>>(props.harvesterTask);
        const { isFinalized, inDraftStatus, inUpdateStatus } = useApolloTask<ApiHarvesterConfiguration>(task);

        const selectedFields = computed(() =>
            task.value.configuration.response.selectedItems.map((item: string) =>
                isSampleArray.value
                    ? item.replace(`${basePath.value}[0]${separator}`, '')
                    : item.replace(`${basePath.value}${separator}`, ''),
            ),
        );

        const sampleWithSelectedFields = computed(() => {
            if (isSampleArray.value)
                return props.sample.reduce((acc: any[], record: any) => {
                    const obj = Object.keys(record).reduce((recordAcc: any, key: string) => {
                        if (selectedFields.value.includes(key)) recordAcc[key] = record[key];
                        return recordAcc;
                    }, {});
                    if (Object.keys(obj).length) acc.push(obj);
                    return acc;
                }, []);
            return Object.keys(props.sample).reduce((acc: any, key: string) => {
                if (selectedFields.value.includes(key)) acc[key] = (props.sample as any)[key];
                return acc;
            }, {});
        });

        const loginResponse: Ref<Record<string, any>> = computed(() =>
            is(Array, props.loginResponse)
                ? (props.loginResponse[0] as Record<string, any>)
                : (props.loginResponse as Record<string, any>),
        );

        const { executeApi, separator, responsePrefix, loading: loadingAPI } = useAPIConnection(
            task,
            computed(() => loginResponse.value || {}),
        );

        const hasResponseData = computed(() => task.value && !checkEmptyJSON(props.sample));
        const isSampleArray = computed(() => task.value && is(Array, props.sample));

        const additionalPaths = computed(() => {
            if (!task.value.configuration.response.additional) return [];

            const paths = [];
            for (const data of task.value.configuration.response.additional) {
                if (isSampleArray.value) {
                    paths.push(`${basePath.value}[0]${separator}${data.key}`);
                } else {
                    paths.push(`${basePath.value}${separator}${data.key}`);
                }
            }

            return paths;
        });

        const additionalDataKeyValue = computed(() => {
            if (!task.value.configuration.response.additional) return {};

            const result = {};
            for (const data of task.value.configuration.response.additional) {
                if (
                    is(Object, data.displayValue) &&
                    has('option', data.displayValue) &&
                    has('value', data.displayValue)
                ) {
                    const { option, value } = data.displayValue as any;
                    result[data.key] = `${option}${isNil(value) ? '' : ':' + value}`;
                } else result[data.key] = data.displayValue;
            }
            return result;
        });

        const availableRequestParams = computed(() =>
            task.value.configuration.params.parameters.reduce((acc: any[], param: ApiHarvesterParameter) => {
                if (param.sensitive) return acc;
                if (param.value.category === 'authentication') {
                    // make sure parameter is in login response in order to calculate it
                    const sampleValue =
                        !isNil(param.value.value) &&
                        !isNil(loginResponse.value) &&
                        has(param.value.value as string, loginResponse.value)
                            ? loginResponse.value[param.value.value as string]
                            : ``;

                    acc.push({
                        ...param,
                        value: {
                            ...param.value,
                            sampleValue,
                        },
                    });
                } else acc.push(param);

                return acc;
            }, []),
        );

        return {
            task,
            isFinalized,
            hasResponseData,
            inUpdateStatus,
            inDraftStatus,
            separator,
            additionalDataKeyValue,
            additionalPaths,
            basePath,
            responsePrefix,
            availableRequestParams,
            loadingAPI,
            is,
            executeApi,
            sampleWithSelectedFields,
        };
    },
});
