
































import { defineComponent, PropType, computed, Ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { AlertBanner, FormBlock } from '@/app/components';

export default defineComponent({
    name: 'idenftifier-column',
    model: {
        prop: 'identifierColumn',
        event: 'change',
    },
    props: {
        identifierColumn: {
            type: [String, null] as PropType<string | null>,
            default: () => null,
        },
        structure: {
            type: Object as PropType<Record<string, string>>,
            default: () => {},
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationProvider,
        AlertBanner,
        FormBlock,
    },
    setup(props, { emit }) {
        const identifier: Ref<string | null> = computed({
            get: () => props.identifierColumn,
            set: (newIdentifierColumn) => {
                emit('change', newIdentifierColumn);
            },
        });

        const identifierColumns = computed(() =>
            props.structure
                ? Object.keys(props.structure).filter((key: string) =>
                      ['Number', 'Date'].includes(props.structure[key]),
                  )
                : [],
        );

        return {
            identifierColumns,
            identifier,
        };
    },
});
